const packageJson = require('../../package.json'); // work

export const defaultEnvironmentConfigs = {
  version: packageJson.version,
  production: true,
  sessionTokenName: 'LogbookApi',
  sessionRefreshTokenName: 'logbookApi_RT',
  apiUrl: 'https://local-logbook-api.scm3d.com:5002',
  ssoUrl: 'https://local-logbook-api.scm3d.com:5002',
  ssoAuthUrl: '/redirect_sso',
  ssoSilentAuthUrl: '/token/refresh',
  cookieSecureMode: true,  // http: false, https: true
  ssoLogoutUrl: '/logout',
  ssoProviderLogoutUrl: 'https://scm3d.b2clogin.com/scm3d.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1A_signup_signin_with_kmsi&post_logout_redirect_uri=',
  signalRHubUrl: '/signalr',
  notificationListUrl: '/getFeedData',
  notificationFeedTokenUrl: '/getFeedToken',
  ssoSilentAuthReturnMessage: 'Successfully Authenticated',
  idleWarningTimeOutInSeconds: 600,
  idleLogoutTimeOutInSeconds: 300,
  keepAliveTimeOutInSeconds: 890,
};
