import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {
  ICheckboxChange,
  INodeExpandEvent,
  ITableCheckboxValue,
  ITreeTableNode,
  ITreetableOutputParameter,
} from './scw-mat-treetable.model';
import { TranslateService } from '@ngx-translate/core';
import { IDatatableOutputParameter, ICustomSort } from '../../datatable/datatable.model';
import { TreeTable } from 'primeng/treetable';
import * as _ from 'lodash';

@Component({
  selector: 'scw-mat-treetable',
  templateUrl: './scw-mat-treetable.component.html',
  styleUrls: ['./scw-mat-treetable.component.scss'],
})
export class ScwMatTreetableComponent implements OnInit, AfterViewChecked, OnChanges {
  @ViewChild('treeTable') treeTableRef!: TreeTable;
  @Input() lazyLoadChildren: boolean = false;
  @Input() columns: any[] = [];
  @Input() customSorts: ICustomSort = {};
  @Input() shouldPaginate: boolean = true;
  @Input() currentPage: number = 1;
  @Input() itemsCount: number = 0;
  @Input() rowsPerPage: number = 10;
  @Input() searchPlaceholder: string = this.translate.instant('general.search');
  @Input() searchDelay: number = 600;
  @Input() clientSide: boolean = true;
  @Input() items: ITreeTableNode[] = [];
  @Input() readonly: boolean = false;
  @Input() noDataText: string = this.translate.instant('general.datatable.noData');
  @Input() showSearchBox: boolean = true;
  @Input() sortBy!: string;
  @Input() singleSelect: boolean = false;
  @Input() overflowYHidden: boolean = false;
  @Input() stickyEndColumn: number = 0;
  @Input() isFormScope: boolean = false;

  @Output() onDataRequest: EventEmitter<ITreetableOutputParameter> = new EventEmitter<ITreetableOutputParameter>();
  @Output() clientSideRequest: EventEmitter<ITreetableOutputParameter> = new EventEmitter<ITreetableOutputParameter>();
  @Output() onNodeExpandClicked: EventEmitter<INodeExpandEvent> = new EventEmitter<INodeExpandEvent>();
  /**
   * To be mainly used with clientSide mode
   */
  @Output() onClickPagination: EventEmitter<IDatatableOutputParameter> = new EventEmitter<IDatatableOutputParameter>();
  @Output() onClickSort: EventEmitter<void> = new EventEmitter<void>();
  @Output() onRowCheckboxChange: EventEmitter<ICheckboxChange[]> = new EventEmitter<ICheckboxChange[]>();
  @Output() onStickyEndColumnRightValuesUpdated = new EventEmitter<string[]>();

  public searchString: string | null = '';
  public stickyEndColumnRightValues: string[] = [];
  public isSelectAllCheckbox: boolean = true;
  public allInteractiveColumnInputsValid: boolean = true;
  private searchFindTree: number[] = [];
  private oldStickyEndColumnRightValues: string[] = [];
  private checkboxRowIdsToEmit: number[] = [];
  private isColumnInfoReceived: boolean = false;
  private tableRows!: NodeListOf<Element>;
  private oldTableRows!: NodeListOf<Element>;
  private stickyHeaders!: NodeListOf<Element>;
  private stickyHeaderLength!: number;
  private stickyColumnsArranged: boolean = false;
  private tableRowsChanged: boolean = true;
  private additionalColumnCheckRequired: boolean = false;
  private allItemCheckboxValue: ITableCheckboxValue[] = [];
  private onlyOneRunSelectAllCheckboxCheck: boolean = true;
  private interactiveColumnInputValidity: Record<number, boolean> = {};

  constructor(
    private primengConfig: PrimeNGConfig,
    private readonly translate: TranslateService,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.primengConfig.ripple = true;

    for (const column of this.columns) {
      if (column.checkbox) {
        column.checkboxInputModel = this.allCheckboxesAreChecked(column.field);
      }
    }

    this.filterSearchText('');
    this.searchFindTree.forEach((id: number) => this.allItemCheckboxValue.push({ id, checkboxValue: false }));
    this.filterSearchText('');
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.isColumnInfoReceived = false;
    this.tableRowsChanged = !_.isEqual(this.oldTableRows, this.tableRows);

    if (
      this.stickyEndColumn > 1 &&
      (this.isColumnInfoReceived || this.tableRowsChanged) &&
      !this.stickyColumnsArranged
    ) {
      this.arrangeStickyCells();
    }
  }

  private arrangeStickyCells(): void {
    this.stickyHeaders?.forEach((node: Element, index: number) => {
      const columnIndex: number = _.get(node, 'cellIndex', 0);
      const nextTargetExists: boolean = index + 1 !== this.stickyHeaderLength;

      if (nextTargetExists) {
        const nextTargetWidth: number = this.stickyHeaders[index + 1].getBoundingClientRect().width;
        this.stickyEndColumnRightValues[columnIndex] = `${nextTargetWidth}px`;
        this.isColumnInfoReceived = Boolean(nextTargetWidth);

        return;
      }

      this.stickyEndColumnRightValues[columnIndex] = '0';
    });

    if (this.isColumnInfoReceived) {
      this.stickyColumnsArranged = true;
      this.oldTableRows = this.tableRows;

      if (!_.isEqual(this.oldStickyEndColumnRightValues, this.stickyEndColumnRightValues)) {
        this.onStickyEndColumnRightValuesUpdated.emit(this.stickyEndColumnRightValues);
      }

      this.oldStickyEndColumnRightValues = _.cloneDeep(this.stickyEndColumnRightValues);
    }
  }

  public ngAfterViewChecked(): void {
    if (this.stickyEndColumn > 1 && (!this.isColumnInfoReceived || this.additionalColumnCheckRequired)) {
      this.stickyEndColumnRightValues = [];
      this.tableRows = document.querySelectorAll('.treetable-row');

      if (!this.tableRows.length && this.itemsCount) {
        return;
      }

      this.stickyHeaders = document.querySelectorAll('.tree-table .sticky-end-header');
      this.stickyHeaderLength = this.stickyHeaders.length;

      if (!this.stickyHeaderLength) {
        this.isColumnInfoReceived = false;

        return;
      }

      this.isColumnInfoReceived = true;
      this.stickyColumnsArranged = false;
      this.additionalColumnCheckRequired = Boolean(!this.itemsCount && this.tableRows.length);

      this.ngOnChanges({});
      this.changeDetector.detectChanges();
    }
  }

  public onRowCheckboxClick($event: boolean, rowId: number, columnKey: string) {
    this.onRowCheckboxChange.emit([{ rowId, columnKey, checked: $event }]);
    const header: any = this.columns.find((item: any) => item.field === columnKey);

    if (header?.checkbox) {
      if (!$event) {
        header.checkboxInputModel = false;
      } else if ($event) {
        header.checkboxInputModel = this.allCheckboxesAreChecked(columnKey);
      }
    }

    for (const item of this.items) {
      this.forEachItem(item, (node: ITreeTableNode) => {
        if (node.data.id === rowId && node.data.checkboxes) {
          node.data.checkboxes[columnKey] = $event;
        } else if (this.singleSelect && node.data.checkboxes) {
          node.data.checkboxes[columnKey] = false;
        }
      });
    }

    this.setAllItemCheckboxValue($event, rowId);
    this.selectAllOrUnselectCheckboxCheck();
  }

  private setAllItemCheckboxValue(value: boolean, rowId: number): void {
    this.allItemCheckboxValue.map((item: ITableCheckboxValue) => {
      if (item.id === rowId) {
        item.checkboxValue = value;
      }
    });
  }

  public onNodeExpand($event: INodeExpandEvent): void {
    this.onNodeExpandClicked.emit($event);
  }

  public headerCheckboxChanged(columnKey: string): void {
    this.checkboxRowIdsToEmit = [];

    for (const item of this.items) {
      this.forEachItem(item, (node: ITreeTableNode) => {
        if (node.data.checkboxes) {
          node.data.checkboxes[columnKey] = this.isSelectAllCheckbox;
          this.checkboxRowIdsToEmit.push(node.data.id);
        }
      });
    }

    this.onRowCheckboxChange.emit(
      this.searchFindTree.map((rowId: number) => {
        return { rowId, columnKey, checked: this.isSelectAllCheckbox };
      }),
    );
    this.checkboxRowIdsToEmit = [];
    this.tableCheckboxValueGenerator(columnKey);
    this.selectAllOrUnselectCheckboxCheck();
  }

  public searchTreeTable(searchKey: string): void {
    if (_.isNil(searchKey)) {
      return;
    }

    this.filterSearchText(searchKey.trim());
    this.treeTableRef.filterGlobal(searchKey.trim(), 'contains');
    this.selectAllOrUnselectCheckboxCheck();
  }

  public interactiveColumnInputIsValidChange(isValid: boolean, rowDataId: number): void {
    this.interactiveColumnInputValidity[rowDataId] = isValid;
    this.allInteractiveColumnInputsValid = Object.values(this.interactiveColumnInputValidity).every(
      (isValid: boolean) => isValid,
    );
  }

  public interactiveColumnOnCancelButtonClick(col: any, $event: unknown): void {
    this.allInteractiveColumnInputsValid = true;
    this.interactiveColumnInputValidity = {};

    col['buttonConfig']['cancelButtonOnClick'].call(this, $event);
  }

  private selectAllOrUnselectCheckboxCheck(): void {
    const isAllSelect: boolean = this.isTableHasItemAllSelect();
    const selectAllCheckbox: HTMLElement | null = document.getElementById('selectOrUnSelectCheckbox');

    if ((selectAllCheckbox && !isAllSelect) || !this.searchFindTree.length) {
      this.isSelectAllCheckbox = true;
      selectAllCheckbox?.classList.remove('mat-checkbox-checked');
    } else if (selectAllCheckbox) {
      this.isSelectAllCheckbox = false;
      selectAllCheckbox.classList.add('mat-checkbox-checked');
    }
  }

  private tableCheckboxValueGenerator(columnKey: string): void {
    this.allItemCheckboxValue.forEach((item: ITableCheckboxValue) => {
      const isItemHasTable: boolean = !!this.searchFindTree.find((id: number) => id === item.id);

      if (isItemHasTable) {
        item.checkboxValue = this.isSelectAllCheckbox;
      }
    });
    this.allItemCheckboxValue.map((item: ITableCheckboxValue) => {
      this.onRowCheckboxClick(item.checkboxValue, item.id, columnKey);
    });
  }

  private isTableHasItemAllSelect(): boolean {
    for (const id of this.searchFindTree) {
      const selectedItem: ITableCheckboxValue | undefined = this.allItemCheckboxValue.find(
        (data: ITableCheckboxValue) => data.id === id,
      );

      if (selectedItem?.checkboxValue === false) {
        return false;
      }
    }

    return true;
  }

  private filterSearchText(search: string): void {
    this.searchFindTree = [];
    this.items.forEach((data: ITreeTableNode) => {
      this.getFilteredItemIds(data, search, search === '' || search === null);
    });
  }

  private getFilteredItemIds(
    searchParentItems: ITreeTableNode,
    search: string,
    getAllItemIds: boolean,
    isParentContain: boolean = false,
  ): void {
    const isContainSearch: boolean =
      isParentContain || searchParentItems.data.name.toLowerCase().includes(search.toLowerCase());
    const isFormScope: boolean = this.isFormScope && !searchParentItems.data?.logbookId;
    let notHasItem: boolean = !this.searchFindTree.find((id: number) => id === searchParentItems.data.id);

    if (notHasItem && (isParentContain || isContainSearch || getAllItemIds) && (isFormScope || !this.isFormScope)) {
      this.searchFindTree.push(searchParentItems.data.id);

      if (searchParentItems.data?.checkboxes?.includeInScope) {
        this.onRowCheckboxClick(
          searchParentItems.data?.checkboxes['includeInScope'],
          searchParentItems.data.id,
          'includeInScope',
        );
      }
    }

    if (searchParentItems?.children) {
      for (const childItem of searchParentItems?.children) {
        this.getFilteredItemIds(childItem, search, getAllItemIds, isContainSearch);
        notHasItem = !this.searchFindTree.find((id: number) => id === searchParentItems.data.id);

        if (!isParentContain && (isFormScope || !this.isFormScope) && notHasItem && !getAllItemIds) {
          this.searchFindTree.forEach((id: number) => {
            if (_.map(searchParentItems.children, 'data.id')?.indexOf(id) !== -1 && searchParentItems.data.id !== -1) {
              this.searchFindTree.push(searchParentItems.data.id);
            }
          });
        }
      }
    }
  }

  private allCheckboxesAreChecked(columnKey: string): boolean {
    let allChecked: boolean = true;

    for (const item of this.items) {
      this.forEachItem(item, (node: ITreeTableNode) => {
        if (node.data.checkboxes && node.data.checkboxes[columnKey] === false) {
          allChecked = false;
        }
      });

      if (!allChecked) {
        return false;
      }
    }

    return allChecked;
  }

  private forEachItem(item: ITreeTableNode, operation: (item: ITreeTableNode) => void): void {
    if (item.children?.length) {
      for (const child of item.children) {
        this.forEachItem(child, operation);
      }
    }

    operation(item);
  }

  trackByFunction = (_index: number, item: any) => {
    return item.node.data.id;
  };
}
