import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { IFormIoFile, IFormIoOptions, IFormSubmissionUserAction, ITableHeader } from '../../../../../constants.model';
import * as _ from 'lodash';
import { forkJoin, Observable, Subject } from 'rxjs';
import {
  IBaseOneResponse,
  IGetManyResponse,
  IGetOneResponse,
} from '../../../model/interface/crud-response-interface.model';
import { IFormEntriesData } from '../../../../store/reports/form-entries/form-entries.model';
import { ILogsFormEntriesColumn } from '../../../../view/my-tasks/logs-form-entries/logs-form-entries.component.model';
import { IFormSubmissionData } from '../../../../store/my-tasks/logs-form-entries/logs-form-entries.model';
import { ISelect } from '../../../component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import * as moment from 'moment-timezone';
import { CellTypes, ICreateExcel, IExcelColumnDefinition } from '../../excel/excel-helper.service';
import * as FormEntriesActions from '../../../../store/reports/form-entries/form-entries.actions';
import { HelperService } from '../../helper.service';
import { localeDateObject } from '../../../../../constants';
import { takeUntil } from 'rxjs/operators';
import { excelDateFormat, excelTimeFormat, IExcelDateFormat } from '../../../model/enum/excel-date-format';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../../../../store/logbook.reducer';
import { ValueType } from 'exceljs';
import { IAddMyReport, IMyReportMeta } from '../../../../store/reports/my-reports/my-reports.model';
import {
  IFieldSetData,
  IFieldSetMeta,
  IFieldSetMetaOption,
} from '../../../../store/my-tasks/form-tasks/form-tasks.model';
import { IShowModelFields } from '../../../../view/my-tasks/form-tasks/form-tasks.component.model';
import { IFormRecord, IReturnUserDefinedFieldsToForm } from './form-entries.model';
import { EApprovalStatuses } from '../../../model/enum/constants';
import { IGenericObject } from '../../../model/interface/generic.model';
import { ExcelExporterService } from '../../excel/excel-exporter.service';
import { IFormIoComponentKeyId } from '../../../../view/settings/form/form.model';

@Injectable({
  providedIn: 'root',
})
export class FormEntriesService {
  private timezone: string = 'utc';
  private dateFormat$!: string;
  private timeFormat$!: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  public dynamicColumnPrefix: string = 'dyn_';
  public fileDynamicColumnPrefix: string = 'dyn_file_';
  private approvalStatuses!: IGenericObject<string>;

  private readonly commonExcelAttributes: Partial<IExcelColumnDefinition> = {
    type: ValueType.String,
    isRequired: false,
    dataValidation: {
      type: CellTypes.CUSTOM,
      allowBlank: false,
      showErrorMessage: true,
      formulae: [],
      errorStyle: 'Error',
      showInputMessage: true,
    },
  };

  private readonly FORM_ENTRIES = {
    BASE_URL: '/form-submissions/',
    FORM_ENTRIES_REPORT: 'form-entries-report',
    USER_ACTIONS_FORM_SUBMISSIONS: '/user-actions/form-submissions/',
    REPORT_SAVE: '/my-reports/save-from-page-config/',
  };

  private readonly LOGS_VERSIONS = {
    BASE_URL: '/form-versions/',
    FORM_VERSION_COLUMNS: 'form-version-fields',
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    public readonly helperService: HelperService,
    private readonly translate: TranslateService,
    public readonly store: Store<logbookAppReducer.LogbookAppState>,
    private readonly excelExporterService: ExcelExporterService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone ?? 'utc';

          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale as keyof IExcelDateFormat];
            this.timeFormat$ = excelTimeFormat[state.locale as keyof typeof excelTimeFormat];
          }

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getReportData(params: Record<string, string | number>): Observable<IGetManyResponse<IFormEntriesData>> {
    return this.http.post<IGetManyResponse<IFormEntriesData>>(
      `${this.baseUrl}${this.FORM_ENTRIES.BASE_URL}${this.FORM_ENTRIES.FORM_ENTRIES_REPORT}`,
      { ...params },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getFormEntriesColumns(
    formIds: number[],
    parentLogbookId?: number,
  ): Observable<IGetManyResponse<ILogsFormEntriesColumn>> {
    return this.http.post<IGetManyResponse<ILogsFormEntriesColumn>>(
      `${this.baseUrl}${this.LOGS_VERSIONS.BASE_URL}${this.LOGS_VERSIONS.FORM_VERSION_COLUMNS}`,
      {
        payload: { formIds, parentLogbookId },
      },
    );
  }

  public saveReportTableSettings(reportId: number, meta: IMyReportMeta): Observable<IBaseOneResponse<IAddMyReport>> {
    return this.http.put<IBaseOneResponse<IAddMyReport>>(`${this.baseUrl}${this.FORM_ENTRIES.REPORT_SAVE}`, {
      payload: {
        reportId,
        meta,
      },
    });
  }

  public getFormEntriesHistory(id: number): Observable<IGetManyResponse<IFormSubmissionUserAction>> {
    return this.http.get<IGetManyResponse<IFormSubmissionUserAction>>(
      `${this.baseUrl}${this.FORM_ENTRIES.USER_ACTIONS_FORM_SUBMISSIONS}${id}`,
    );
  }

  public getFormEntriesRecord(logbookId: number, id: number): Observable<IGetOneResponse<IFormSubmissionData>> {
    return this.http.get<IGetOneResponse<IFormSubmissionData>>(
      `${this.baseUrl}${this.FORM_ENTRIES.BASE_URL}${logbookId}/${id}`,
    );
  }

  private getReportObservables(params: Record<string, string | number>) {
    const observables: Observable<
      any | IGetManyResponse<ILogsFormEntriesColumn> | IGetManyResponse<IFormEntriesData>
    >[] = [this.getReportData(params)];

    return observables;
  }

  public async downloadReportExcel(
    params: Record<string, string | number>,
    selectedColumns: ITableHeader[],
    formEntriesDynamicColumns: ILogsFormEntriesColumn[],
  ): Promise<void> {
    forkJoin(this.getReportObservables(params)).subscribe((responseList) => {
      const sheetTitle: string = this.translate.instant('pageTitles.reportsFormEntries');
      const excelName: string = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;
      let excelData: any[] = [];

      excelData = _.get(responseList, '0.data', []) as any[];
      excelData = this.expandColumns(excelData, formEntriesDynamicColumns, true, true);

      const statusHeaderIndex: number = _.findIndex(selectedColumns, { value: 'approvalStepPosition' });
      let selectedColumnsWithFormattedStatus: ITableHeader[] = [];

      if (statusHeaderIndex !== -1) {
        selectedColumnsWithFormattedStatus = _.cloneDeep(selectedColumns);

        selectedColumnsWithFormattedStatus[statusHeaderIndex] = {
          ...selectedColumnsWithFormattedStatus[statusHeaderIndex],
          value: 'formattedApprovalStepPosition',
        };
      }

      const excelOptions: ICreateExcel = this.getReportExcelColumns(
        statusHeaderIndex === -1 ? selectedColumns : selectedColumnsWithFormattedStatus,
      );

      excelOptions.data = excelData;

      this.excelExporterService.createAndDownload(
        sheetTitle,
        excelName,
        excelOptions,
        {
          withData: true,
          timezone: this.timezone,
          dateFormat: this.dateFormat$,
          timeFormat: this.timeFormat$,
          createAsCsvFile: false,
          isDisabledColumnsFirstLine: false,
          addDateTimeFormula: true,
          excelRowFormatLimit: 1001,
          locked: true,
          fixColumnKeyIssues: false,
        },
        () => this.store.dispatch(new FormEntriesActions.DownloadReportExcelCompleted()),
        (error) => {
          this.store.dispatch(new FormEntriesActions.DownloadReportExcelCompleted());
          throw error;
        },
      );
    });
  }

  public getLocaleFormatList(): ISelect<string, string>[] {
    const locales: string[] = Object.keys(localeDateObject);
    const timeFormat: string = 'LTS';
    let output: ISelect<string, string>[] = [];

    for (const locale of locales) {
      const options: ISelect<string, string>[] = [
        moment().locale(locale).localeData().longDateFormat('ll'),
        moment().locale(locale).localeData().longDateFormat('L'),
      ].map((format: string) => {
        const formatAndTimeFormat: string = `${format} ${timeFormat}`;

        return {
          id: `${locale}_${format}`,
          name: `${localeDateObject[locale as keyof typeof localeDateObject]} - ${moment()
            .locale(locale)
            .format(formatAndTimeFormat)}`,
        };
      });

      output = output.concat(options);
    }

    return output;
  }

  private getReportExcelColumns(selectedColumns: ITableHeader[]): ICreateExcel {
    const excelColumns: ICreateExcel = {
      columns: [],
    };

    for (const column of selectedColumns) {
      if (['actions'].indexOf(column.value) === -1) {
        const newColumn = {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: column.name,
          key: column.value,
          width: 20,
        };

        excelColumns.columns.push(newColumn);
      }
    }

    return excelColumns;
  }

  public expandColumns(
    records: any[],
    formEntriesColumns: ILogsFormEntriesColumn[],
    simplifyUserColumns: boolean = false,
    fromExcel: boolean = false,
    fromHome: boolean = false,
  ): any[] {
    const expendedData: any[] = [];
    this.approvalStatuses = this.helperService.translateObjectValues(
      HelperService.numberEnumToObject(EApprovalStatuses, 'general.approvalStatuses'),
    );

    for (const row of records) {
      let newRow = { ...row };
      let jsonParsed = row['meta'];

      newRow = {
        ...newRow,
        logbookName: this.helperService.getNameWithArchiveLabel(row.logbookIsArchived, row.logbookName),
        formName: this.helperService.getNameWithArchiveLabel(row.formIsArchived, row.formName),
      };

      if (typeof row['meta'] === 'string') {
        jsonParsed = JSON.parse(row['meta']);
      }

      if (typeof jsonParsed === 'object' && jsonParsed !== null) {
        const formVersionDbId: any = row['formVersionDbId'] ?? row['formVersionId'];
        newRow = {
          ...newRow,
          ...this.breakIntoColumns(jsonParsed, formEntriesColumns, formVersionDbId),
        };
      }

      newRow = {
        ...newRow,
        formattedApprovalStepPosition: this.approvalStatuses[newRow.approvalStepPosition],
      };

      expendedData.push(newRow);
    }

    return this.makeHumanReadable(expendedData, formEntriesColumns, simplifyUserColumns, fromExcel, fromHome);
  }

  private makeHumanReadable(
    records: any[],
    formEntriesColumns: ILogsFormEntriesColumn[],
    simplifyUserColumns: boolean,
    returnFileFieldsFilenameOnly: boolean = false,
    fromHome: boolean = false,
  ): any[] {
    const yesTranslation: string = this.translate.instant('general.yes');
    const noTranslation: string = this.translate.instant('general.no');

    for (const row of records) {
      formEntriesColumns?.forEach((column: ILogsFormEntriesColumn) => {
        this.makeColumnReadable(row, column, returnFileFieldsFilenameOnly);
      });

      if (simplifyUserColumns) {
        row.createdBy = row.createdBy?.email ?? '';
        row.submittedBy = row.submittedBy?.email ?? '';
      }

      row.createdAt = fromHome ? row.createdAt : this.checkDateValidity(row, 'createdAt', simplifyUserColumns);
      row.submittedAt = fromHome ? row.submittedAt : this.checkDateValidity(row, 'submittedAt', simplifyUserColumns);

      row.formattedIsMobileLogbook = row.isMobileLogbook ? yesTranslation : noTranslation;
      row.formattedFormActivityType = row.formActivityType
        ? this.translate.instant(`general.formActivityTypes.${row.formActivityType}`)
        : '';
      row.useCheckIn = row.useCheckIn ? this.translate.instant('general.yes') : this.translate.instant('general.no');
    }
    return records;
  }

  private makeColumnReadable(row: any, column: ILogsFormEntriesColumn, returnFileFieldsFilenameOnly: boolean): any {
    const modifiedKey: string = this.dynamicColumnPrefix + column.selectedKey;

    if (column.selectedType === 'select' || column.selectedType === 'radio') {
      row[modifiedKey] = this.getFormIoLabels(
        row[modifiedKey],
        column.selectedInnerLabels,
        column.selectedType === 'radio',
      );
    }

    if (column.selectedType === 'datetime') {
      row[modifiedKey] = this.formatDate(row[modifiedKey], column?.formId);
    }

    if (column.selectedType === 'checkbox') {
      if (row[modifiedKey] === true) {
        row[modifiedKey] = this.translate.instant('reports.formEntries.headers.checked');
      } else if (row[modifiedKey] === false) {
        row[modifiedKey] = this.translate.instant('reports.formEntries.headers.unChecked');
      } else if (row[modifiedKey] && row[modifiedKey].includes('true')) {
        row[modifiedKey] = row[modifiedKey].replace(
          'true',
          this.translate.instant('reports.formEntries.headers.checked'),
        );
      } else if (row[modifiedKey] && row[modifiedKey].includes('false')) {
        row[modifiedKey] = row[modifiedKey].replace(
          'false',
          this.translate.instant('reports.formEntries.headers.unChecked'),
        );
      } else {
        row[modifiedKey] = '';
      }
    }

    if (column.selectedType === 'file' && returnFileFieldsFilenameOnly) {
      row[modifiedKey] = row[modifiedKey]?.originalName ?? '';
    }

    return row;
  }

  private breakIntoColumns(
    recordMeta: any,
    formEntriesColumns: ILogsFormEntriesColumn[],
    formVersionDbId: string = '',
  ): object {
    let resultObject = {};

    Object.keys(recordMeta).forEach((key: string) => {
      const innerDataValue = recordMeta[key];
      const map: { [key: string]: string | IFormIoFile } = {};
      const fieldOptionLabel: string | IFormIoFile = this.getFieldOptionLabels(
        key + formVersionDbId,
        innerDataValue,
        formEntriesColumns,
      );
      const chosenDynamicColumnPrefix: string =
        typeof fieldOptionLabel === 'object' ? this.fileDynamicColumnPrefix : this.dynamicColumnPrefix;

      map[chosenDynamicColumnPrefix + key + formVersionDbId] = fieldOptionLabel;

      resultObject = { ...resultObject, ...map };
    });

    return resultObject;
  }

  public getFieldObject(key: string, formEntriesColumns: ILogsFormEntriesColumn[]): ILogsFormEntriesColumn {
    let output: ILogsFormEntriesColumn = {
      parsedCommonName: '',
      isInputField: false,
      selectedKey: '',
      selectedType: '',
      selectedLabel: '',
      selectedInnerLabels: [],
    };

    formEntriesColumns?.forEach((item) => {
      if (item.selectedKey === key) {
        output = item;
      }
    });

    return output;
  }

  private getFieldOptionLabels(
    key: string,
    input: string,
    formEntriesColumns: ILogsFormEntriesColumn[],
  ): string | IFormIoFile {
    const fieldObject = this.getFieldObject(key, formEntriesColumns);
    switch (fieldObject['selectedType']) {
      case 'selectboxes':
      case 'radio':
        return `${this.convertSelectedBoxesValue(input, fieldObject)}`;
      case 'time':
        return Array.isArray(input)
          ? this.helperService.getFormIoMultipleValues(input)
          : input.substring(0, input.length - 3);
      case 'file':
        const file: IFormIoFile & { original_name: string } = input[0] as unknown as IFormIoFile & {
          original_name: string;
        };

        return file
          ? {
              data: file?.data,
              hash: file?.hash,
              name: file?.name,
              /*
Formerly, each 'meta' property was parsed to be of snake_case which warranted the usage of 'original_name',
after the related development that prevented such parsing the property is now saved as 'originalName'.
However, older clients' data will still linger as 'original_name' in the db,
hence the below workaround was implemented instead of an unnecessarily complex data change.
*/
              originalName: file.originalName ?? file.original_name,
              size: file?.size,
              storage: file?.storage,
              type: file?.type,
              url: file?.url,
            }
          : '';
      default:
        return input;
    }
  }

  public convertSelectedBoxesValue(input: any, formEntriesColumn: ILogsFormEntriesColumn): string {
    let output = '';

    if (typeof input === 'object') {
      Object.keys(input).forEach((innerKey) => {
        if (input[innerKey]) {
          output += this.retrieveInnerLabel(innerKey, formEntriesColumn);
          output += ',';
        }
      });
      output = output.substring(0, output.length - 1);
    } else {
      output = this.retrieveInnerLabel(input, formEntriesColumn);
    }

    return output;
  }

  public retrieveInnerLabel(innerKey: string, formEntriesColumn: ILogsFormEntriesColumn): string {
    let selectedLabel = innerKey;

    if (typeof formEntriesColumn.selectedInnerLabels === 'object') {
      for (const label of formEntriesColumn.selectedInnerLabels) {
        if (label['value'] == innerKey) {
          selectedLabel = label['label'];
          break;
        }
      }
    }

    return selectedLabel;
  }

  public formatDate(inputDate: string = '', formId?: string): string {
    return HelperService.formatDateTimeWithLineBreak(inputDate);
  }

  public formatFileData(input: any[]): string {
    if (input.length === 0) {
      return '';
    }
    return input[0]['originalName'];
  }

  private getFormIoLabels(
    value: any,
    selectedInnerLabels: IFormIoOptions[] | undefined,
    isRadio: boolean = false,
  ): string {
    const output: string[] = [];

    if (selectedInnerLabels && value) {
      if (Array.isArray(value)) {
        value.forEach((item: string) => {
          const selected: string | undefined = _.find(selectedInnerLabels, { value: String(item) })?.label;
          if (selected) {
            output.push(selected);
          }
        });
      } else {
        const selected: string | undefined = _.find(selectedInnerLabels, { value: String(value) })?.label;
        if (selected) {
          output.push(selected);
        }
      }
    }

    if (isRadio && !output.includes(value)) {
      output.push(value);
    }

    return output.join(',');
  }

  public static getFormEntriesTableHeaderDefaults(translateService: TranslateService): ITableHeader[] {
    return _.cloneDeep([
      {
        value: 'formSubmissionsId',
        name: translateService.instant('reports.headers.logId'),
        sortable: true,
        width: '40px',
        selected: true,
        draggable: true,
        disabled: true,
      },
      {
        value: 'logbookId',
        name: translateService.instant('reports.headers.logbookId'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'logbookName',
        name: translateService.instant('reports.headers.logbookName'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'logbookLegacyId',
        name: translateService.instant('reports.headers.logbookLegacyId'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'logbookVersionNumber',
        name: translateService.instant('general.datatable.headers.logbookVersion'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'formattedIsMobileLogbook',
        name: translateService.instant('general.datatable.headers.mobileLogbook'),
        selected: true,
        sortable: false,
        draggable: true,
      },
      {
        value: 'parentLogbookName',
        name: translateService.instant('general.datatable.headers.parentLogbookName'),
        selected: true,
        sortable: false,
        draggable: true,
      },
      {
        value: 'createdBy',
        name: translateService.instant('reports.headers.createdBy'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'createdAt',
        name: translateService.instant('reports.headers.createdAt'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'submittedBy',
        name: translateService.instant('reports.headers.submittedBy'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'submittedAt',
        name: translateService.instant('reports.headers.submittedAt'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'formId',
        name: translateService.instant('reports.headers.formId'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'formName',
        name: translateService.instant('reports.headers.formName'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'formLegacyId',
        name: translateService.instant('reports.headers.formLegacyId'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'formVersionNumber',
        name: translateService.instant('general.datatable.headers.formVersion'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'formattedFormActivityType',
        name: translateService.instant('general.datatable.headers.formActivityType'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'approvalStepPosition',
        name: translateService.instant('reports.headers.status'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'actions',
        name: translateService.instant('general.datatable.headers.content'),
        sortable: false,
        disabled: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'useCheckIn',
        name: translateService.instant('general.datatable.headers.checkInMechanism'),
        sortable: false,
        selected: true,
        draggable: true,
      },
    ]);
  }

  public convertUserDefinedFieldsToForm(
    formRecord: IFormRecord,
    showModelAfterForm: IShowModelFields,
    fieldRecords: IFieldSetData[],
  ): IReturnUserDefinedFieldsToForm {
    showModelAfterForm.userDefinedFields = {};

    const gatheredFields: IFieldSetMeta[] = this.gatherFields(fieldRecords);
    formRecord.currentVersionUserFields = this.applyInputModels(formRecord, gatheredFields);

    return { showModelAfterForm, formRecord };
  }

  private applyInputModels(formRecord: IFormRecord, fields: IFieldSetMeta[]): IFieldSetMeta[] {
    let fieldValues: any;

    if (formRecord.after) {
      fieldValues = formRecord.after.fieldMeta;
    }

    fields = this.injectInputDummyModels(fields);

    if (fieldValues) {
      fields = this.injectInputModels(fields, fieldValues);
    }

    return fields;
  }

  public injectInputModels(fields: IFieldSetMeta[], fieldValues: any): IFieldSetMeta[] {
    fields.forEach((item) => {
      for (const fieldValue of fieldValues) {
        if (fieldValue['fieldId'] != item.fieldId) {
          continue;
        }

        if (fieldValue['inputModel']) {
          item.inputModel = fieldValue['inputModel'];
        } else {
          switch (item.type) {
            case 'Checkbox':
              item.inputModel = false;
              break;
            case 'CheckboxMulti':
              item.inputModel = this.generateOptionsForCheckboxMulti(item.options);
              break;
            case 'Dropdown':
            case 'DropdownMulti':
              item.inputModel = [];
              item.options = [
                {
                  id: 1,
                  label: '',
                  status: true,
                },
              ];
              break;
            default:
              item.inputModel = null;
          }
        }
      }
    });

    return fields;
  }

  private generateOptionsForCheckboxMulti(itemOptions: IFieldSetMetaOption[]): IFieldSetMetaOption[] {
    const options: IFieldSetMetaOption[] = [];
    for (const option of itemOptions) {
      const newOption = { ...option, value: false };
      options.push(newOption);
    }
    return options;
  }

  public injectInputDummyModels(fields: IFieldSetMeta[]): IFieldSetMeta[] {
    fields.forEach((item) => {
      switch (item.type) {
        case 'Text':
        case 'TextArea':
          item.inputModel = '';
          break;
        case 'Date':
        case 'Datetime':
          item.inputModel = {
            end_date: moment(),
            start_date: moment(),
          };
          break;
        case 'Checkbox':
          item.inputModel = false;
          break;
        case 'CheckboxMulti':
          item.inputModel = this.generateOptionsForCheckboxMulti(item.options);
          break;
        case 'Dropdown':
        case 'DropdownMulti':
          item.inputModel = [];
          item.options = [
            {
              id: 1,
              label: '',
              status: true,
            },
          ];
          break;
        default:
          item.inputModel = null;
      }
    });

    return fields;
  }

  public gatherFields(fieldRecords: IFieldSetData[]): IFieldSetMeta[] {
    let gatheredFields: IFieldSetMeta[] = [];

    fieldRecords.forEach((item: IFieldSetData) => {
      let inputModal;
      if (['Dropdown', 'DropdownMulti', 'CheckboxMulti'].includes(item.meta.type)) {
        inputModal = { isEnabled: true, value: [], rules: [] };
      } else {
        inputModal = { isEnabled: true, value: null, rules: [] };
      }
      gatheredFields.push({
        order: item.order,
        id: item.id,
        name: item.name,
        type: item.meta.type,
        fieldId: item.meta.fieldId,
        options: item.meta.options,
        mandatory: item.meta.mandatory,
        maxLength: item.meta.maxLength,
        inputModel: inputModal,
      });
      gatheredFields = gatheredFields.sort((a, b) => (a.order < b.order ? -1 : 1));
    });

    return gatheredFields;
  }

  public checkDateValidity(row: any, key: string, simplifyUserColumns: boolean): string | null {
    if (!moment(row.submittedAt).isValid()) {
      return '';
    }

    if (simplifyUserColumns) {
      return this.helperService.formatDateTimeTz(row[key]);
    }

    return row[key];
  }

  public joinObjectValues(data: any): string {
    if (typeof data === 'object') {
      return Object.values(data).join(', ');
    }

    return '';
  }

  public prepareDynColumnsValue(records: any[], formEntriesColumns: ILogsFormEntriesColumn[]): any[] {
    return records.map((record: any) => {
      formEntriesColumns.forEach((formField: ILogsFormEntriesColumn) => {
        const oldKeyPrefix: string =
          formField.selectedType === 'file' ? this.fileDynamicColumnPrefix : this.dynamicColumnPrefix;
        const oldKey: string = oldKeyPrefix + formField.selectedKey;
        const newKey: string = oldKeyPrefix + String(formField.selectedLabel).toLowerCase().replace(/\s/g, '');

        if (record.hasOwnProperty(oldKey)) {
          const value = record[oldKey];
          delete record[oldKey];
          if (formField.selectedType === 'file') {
            record[newKey] = record[newKey] ? record[newKey].concat([value]) : [value];
          } else {
            record[newKey] = record.hasOwnProperty(newKey)
              ? value !== '' && value !== ' \n '
                ? record[newKey] !== ''
                  ? `${record[newKey] as string}, ${value}`
                  : value
                : record[newKey]
              : value === ' \n '
              ? value.replace(' \n ', '')
              : value;
          }
        }
      });

      return record;
    });
  }
}
