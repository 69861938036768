import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../../logbook.reducer';
import { forkJoin, map, Observable, Subject } from 'rxjs';
import { IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { HelperService } from '../../../shared/service/helper.service';
import { IFieldVersion, IFormTemplateData } from '../../../view/reports/form-templates/form-templates.model';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import {
  CellTypes,
  ExcelHelperService,
  ICreateExcel,
  IExcelColumnDefinition,
} from '../../../shared/service/excel/excel-helper.service';
import * as LogbookTemplatesActions from '../../reports/logbook-templates/logbook-templates.actions';
import { TranslateService } from '@ngx-translate/core';
import { ITableHeader } from 'src/constants.model';
import { takeUntil } from 'rxjs/operators';
import { excelDateFormat, excelTimeFormat, IExcelDateFormat } from '../../../shared/model/enum/excel-date-format';
import { ValueType } from 'exceljs';
import * as AppActions from '../../../../app/store/app/actions';
import { EApprovalStatuses } from '../../../shared/model/enum/constants';
import { IGenericObject } from '../../../shared/model/interface/generic.model';
import { IFormVersionHistory } from '../../forms/forms.model';
import { WorkflowsService } from '../../settings/workflows/workflows.service';
import {
  ILogbookTemplateData,
  ILogbookTemplateDataExcel,
  ILogbookTemplateTableQuery,
} from '../../../view/reports/logbook-templates/logbook-templates.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IGetLogbookTemplates } from './logbook-templates.model';
import { LogbooksService } from '../../logbooks/logbooks.service';
import { FormsService } from '../../forms/forms.service';
import {
  FieldTypes,
  IFilterOutput,
  SqlOperators,
} from '../../../shared/component/filter/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../../../shared/component/filter/advanced-filter/advanced-filter.service';
import { DEFAULT_EXCEL_COLUMN_TRUNCATE_OPTIONS } from '../../../../constants';
import { TemplatesHelperService } from '../../../shared/service/reports/templates/templates-helper.service';

@Injectable({
  providedIn: 'root',
})
export class LogbookTemplatesService {
  private readonly URLS = {
    BASE_URL: '/form-submissions/',
    MASTER_DATA_URL: '/field-version-items/',
    LOGBOOK_TEMPLATES: '/logbook-versions/',
    LOGBOOK_TEMPLATES_HISTORY: '/user-actions/logbook-versions',
  };
  private timezone$: string = 'utc';
  private dateFormat$!: string;
  private timeFormat$!: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private readonly commonExcelAttributes: Partial<IExcelColumnDefinition> = {
    type: ValueType.String,
    isRequired: false,
    dataValidation: {
      type: CellTypes.CUSTOM,
      allowBlank: false,
      showErrorMessage: true,
      formulae: [],
      errorStyle: 'Error',
      showInputMessage: false,
    },
  };
  public approvalStatuses: IGenericObject<string> = this.helperService.translateObjectValues(
    HelperService.numberEnumToObject(EApprovalStatuses, 'general.approvalStatuses'),
  );

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    public readonly helperService: HelperService,
    public readonly store: Store<logbookAppReducer.LogbookAppState>,
    public readonly translate: TranslateService,
    public readonly excelHelperService: ExcelHelperService,
    public readonly workflowsService: WorkflowsService,
    public readonly logbookService: LogbooksService,
    public readonly formService: FormsService,
    private readonly advancedFilterService: AdvancedFilterService,
    private readonly templatesHelperService: TemplatesHelperService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone$ = state.timezone ?? 'utc';

          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale as keyof IExcelDateFormat];
            this.timeFormat$ = excelTimeFormat[state.locale as keyof typeof excelTimeFormat];
          }

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getAllMasterData(params: Record<string, string | number>): Observable<IGetManyResponse<IFieldVersion>> {
    const paramsWithType: Record<string, string | number> = { ...params, field_version_type: 'logbook' };

    return this.http.post<IGetManyResponse<IFieldVersion>>(
      `${this.baseUrl}${this.URLS.MASTER_DATA_URL}`,
      {
        ...paramsWithType,
      },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getLogbookTemplates(
    params: Record<string, string | number>,
  ): Observable<IGetManyResponse<ILogbookTemplateData>> {
    return this.http.post<IGetManyResponse<ILogbookTemplateData>>(
      `${this.baseUrl}${this.URLS.LOGBOOK_TEMPLATES}`,
      { ...params },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getLogbookTemplateData(id: number, issuer?: IIssuer): Observable<IGetLogbookTemplates> {
    const params: HttpParams = new HttpParams().append('limit', 1000);
    const workflowParams: HttpParams = new HttpParams().append('limit', 1000).append('include_disabled', true);
    const observables: any = [
      this.workflowsService.getWorkflows(workflowParams, issuer),
      this.logbookService.getLogbooks(params, issuer),
      this.formService.getForms(params, issuer),
      this.getLogbookTemplate(id),
    ];

    return forkJoin(observables).pipe(
      map((responseList) => {
        return {
          workflows: _.get(responseList, '0.data', null),
          logbooks: _.get(responseList, '1.data', null),
          forms: _.get(responseList, '2.data', null),
          logbookVersion: _.get(responseList, '3.data', []),
        };
      }),
    );
  }

  public getLogbookTemplate(id: number): Observable<IGetManyResponse<IFormTemplateData>> {
    const params: HttpParams = new HttpParams().append('logbook_version_id', id);
    return this.http.get<IGetManyResponse<IFormTemplateData>>(`${this.baseUrl}${this.URLS.LOGBOOK_TEMPLATES}`, {
      params,
    });
  }

  public async downloadExcel(
    filters: ILogbookTemplateTableQuery,
    selectedColumns: ITableHeader[],
    approvalStatuses: IGenericObject<string>,
    rawMasterDataColumns: ITableHeader[],
  ): Promise<void> {
    const yesTranslation: string = this.translate.instant('general.yes');
    const noTranslation: string = this.translate.instant('general.no');

    forkJoin(this.getObservables(filters)).subscribe((responseList) => {
      const sheetTitle: string = this.translate.instant('pageTitles.logbookTemplates');
      const excelName: string = `${sheetTitle} ${moment().tz(this.timezone$).format(this.dateFormat$)}`;
      const formattedSelectedColumns: ITableHeader[] = _.cloneDeep(selectedColumns);
      let excelData: ILogbookTemplateDataExcel[] = [];

      excelData = _.get(responseList, '0.data', []) as ILogbookTemplateDataExcel[];
      excelData = excelData.map((item: ILogbookTemplateDataExcel) => {
        return {
          ...item,
          name: this.helperService.getNameWithArchiveLabel(item.logbook.isArchived, item.name),
          createdAt: this.helperService.formatDateTimeTz(item.createdAt),
          submittedAt: this.helperService.formatDateTimeTz(item.submittedAt),
          issuedDate: this.helperService.formatDateTimeTz(item.issuedDate),
          approvalStepPosition:
            approvalStatuses[item.approvalStepPosition] || approvalStatuses[EApprovalStatuses.SUBMITTED],
          logbookIdFormatted: item.logbook.logbookId,
          workflowFormatted: this.helperService.getNoApprovalRequiredWorkflowName(item.workflowDetail),
          mobileLogbookFormatted: item.isMobileLogbook ? yesTranslation : noTranslation,
          createdByEmail: item.createdBy?.email ?? '',
          submittedByEmail: item.submittedBy?.email ?? '',
        };
      });

      selectedColumns.forEach((item: ITableHeader, index: number) => {
        if (item.value === 'createdBy' || item.value === 'submittedBy') {
          formattedSelectedColumns[index] = { ...item, value: `${item.value}Email` };
        }
      });

      excelData = this.templatesHelperService.formatTemplateMasterDataValues(excelData, rawMasterDataColumns);

      const excelOptions: ICreateExcel = this.getExcelColumns(formattedSelectedColumns);

      excelOptions.data = excelData;

      this.excelHelperService
        .createExcel(
          sheetTitle,
          excelName,
          excelOptions,
          true,
          this.timezone$,
          this.dateFormat$,
          this.timeFormat$,
          false,
          true,
          true,
          1001,
          true,
          undefined,
          true,
        )
        .then(
          () => {
            this.store.dispatch(new LogbookTemplatesActions.DownloadLogbookTemplatesExcelCompleted());
            this.store.dispatch(new AppActions.HideLoader());
          },
          () => {
            this.store.dispatch(new LogbookTemplatesActions.FetchError({}));
            this.store.dispatch(new AppActions.HideLoader());
          },
        );
    });
  }

  private getObservables(filters: ILogbookTemplateTableQuery) {
    let body: Record<string, string | number> = { limit: 5000 };
    const observables: Observable<IGetManyResponse<ILogbookTemplateData>>[] = [];

    body = { ...body, ordering: '-id' };

    if (Array.isArray(filters.selectedStatuses)) {
      body = { ...body, statuses: JSON.stringify(filters.selectedStatuses) };
    }

    if (Array.isArray(filters.selectedForms)) {
      body = { ...body, forms: JSON.stringify(filters.selectedForms) };
    }

    if (Array.isArray(filters.selectedLogbooks)) {
      body = { ...body, logbooks: JSON.stringify(filters.selectedLogbooks) };
    }

    if (filters.selectedDateRange) {
      body = { ...body, date: JSON.stringify(filters.selectedDateRange) };
    }

    if (filters.page) {
      body = { ...body, offset: 0 };
    }

    if (!_.isEmpty(filters.advancedFilter)) {
      body = { ...body, advanced_filter: JSON.stringify(this.getSearchString(filters)) };
    }

    observables.push(this.getLogbookTemplates(body));

    return observables;
  }

  private getExcelColumns(selectedColumns: ITableHeader[]): ICreateExcel {
    const columns: IExcelColumnDefinition[] = selectedColumns.map((column: ITableHeader) => {
      const columnName: string =
        column.value === 'workflowFormatted'
          ? this.translate.instant('general.datatable.headers.templateApprovalWorkflow')
          : column.name;

      return {
        ...(this.commonExcelAttributes as IExcelColumnDefinition),
        header: _.truncate(columnName, DEFAULT_EXCEL_COLUMN_TRUNCATE_OPTIONS),
        key: _.truncate(column.value, DEFAULT_EXCEL_COLUMN_TRUNCATE_OPTIONS),
        width: 30,
      };
    });

    const excelColumns: ICreateExcel = {
      columns: columns.filter(
        (column: IExcelColumnDefinition) => !['submittedBy', 'createdBy', 'actions'].includes(column.key),
      ),
    };

    this.excelHelperService.prepareExcelColumns(excelColumns.columns);

    return excelColumns;
  }

  public getFormTemplatesHistory(id: number): Observable<IGetManyResponse<IFormVersionHistory>> {
    const params: HttpParams = new HttpParams().append('versionIds', id);

    return this.http.get<IGetManyResponse<IFormVersionHistory>>(
      `${this.baseUrl}${this.URLS.LOGBOOK_TEMPLATES_HISTORY}`,
      { params },
    );
  }

  public getSearchString(tableQuery: ILogbookTemplateTableQuery): object[] {
    const httpParamString: object[] = [];

    if (tableQuery.advancedFilter) {
      const advancedFilter = tableQuery.advancedFilter.filters;

      for (const filter of advancedFilter) {
        switch (filter.type) {
          case FieldTypes.predefined:
            httpParamString.push(this.generateQueryForPredefined(filter, tableQuery));
            break;
          default:
            httpParamString.push(this.generateQueryForDefault(filter, tableQuery));
            break;
        }
      }
    }

    return httpParamString;
  }

  private generateQueryForPredefined(filter: IFilterOutput, tableQuery: ILogbookTemplateTableQuery): object {
    if (!tableQuery?.advancedFilter) {
      return {};
    }

    const filterValue: string = _.get(filter.value, `[0][${filter.searchBy}]`, '');
    const param: string | void = this.advancedFilterService.generateQuery(
      filter.path,
      filter.type,
      filter.operator.name,
      filter.operator.type,
      tableQuery.advancedFilter.target,
      filterValue,
    );

    if (param) {
      if (filter.operator.name === SqlOperators.isNull) {
        return { param, value: true };
      }

      if (filter.operator.name === SqlOperators.isNotNull) {
        return { param: `${filter.path}__isnull`, value: false };
      }

      return { param, value: filter.value };
    }
    return {};
  }

  private generateQueryForDefault(filter: IFilterOutput, tableQuery: ILogbookTemplateTableQuery): object {
    if (!tableQuery?.advancedFilter) {
      return {};
    }

    const param: string | void = this.advancedFilterService.generateQuery(
      filter.path,
      filter.type,
      filter.operator.name,
      filter.operator.type,
      tableQuery.advancedFilter.target,
      _.get(filter.value, `[0][${filter.searchBy}]`, ''),
    );

    if (param) {
      if (filter.operator.name === SqlOperators.isNull) {
        return { param, value: true };
      }

      if (filter.operator.name === SqlOperators.isNotNull) {
        return { param: `${filter.path}__isnull`, value: false };
      }

      return { param, value: filter.value };
    }

    return {};
  }
}
