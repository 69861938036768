import { Inject, Injectable } from '@angular/core';
import {
  CellTypes,
  ExcelHelperService,
  ICreateExcel,
  IExcelColumnDefinition,
} from '../../../shared/service/excel/excel-helper.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../../logbook.reducer';
import { TranslateService } from '@ngx-translate/core';
import { ValueType } from 'exceljs';
import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';
import { IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import * as _ from 'lodash';
import { HelperService } from '../../../shared/service/helper.service';
import { MainService } from '../../main/main.service';
import { IFormSubmission } from '../../home/home.model';
import { AdvancedFilterService } from '../../../shared/component/filter/advanced-filter/advanced-filter.service';
import * as LogsReportsActions from './logs-reports.actions';
import moment from 'moment';
import * as AppActions from '../../../store/app/actions';
import { excelDateFormat, excelTimeFormat, IExcelDateFormat } from '../../../shared/model/enum/excel-date-format';
import { HomeService } from '../../home/home.service';
import { ITableHeader, PageConfigurationTypes } from '../../../../constants.model';
import { EApprovalStatuses } from '../../../shared/model/enum/constants';

@Injectable({
  providedIn: 'root',
})
export class LogsReportsService {
  private readonly URLS = {
    LOGS: `${this.baseUrl}/form-submissions/`,
  };
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private readonly commonExcelAttributes: Partial<IExcelColumnDefinition> = {
    type: ValueType.String,
    dataValidation: {
      type: CellTypes.CUSTOM,
      allowBlank: false,
      showErrorMessage: true,
      formulae: [],
      errorStyle: 'Error',
      showInputMessage: false,
    },
    style: {
      alignment: { horizontal: 'left' },
    },
  };
  private timezone: string = 'utc';
  private dateFormat$!: string;
  private timeFormat$!: string;

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly mainService: MainService,
    private readonly translate: TranslateService,
    public readonly advancedFilterService: AdvancedFilterService,
    public readonly helperService: HelperService,
    public readonly excelHelperService: ExcelHelperService,
    public readonly homeService: HomeService,
    public readonly store: Store<logbookAppReducer.LogbookAppState>,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          if (state.locale !== '') {
            this.timeFormat$ = excelTimeFormat[state.locale as keyof typeof excelTimeFormat];
            this.dateFormat$ = excelDateFormat[state.locale as keyof IExcelDateFormat];
          }

          this.timezone = state.timezone ?? 'utc';

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getLogs(params: Record<string, string | number>): Observable<IGetManyResponse<IFormSubmission>> {
    return this.http.post<IGetManyResponse<IFormSubmission>>(
      this.URLS.LOGS,
      { ...params },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public async downloadLogsExcel(params: Record<string, string | number>, selectedColumns: string[]): Promise<void> {
    forkJoin(this.getLogs(params)).subscribe((responseList) => {
      const sheetTitle: string = this.translate.instant('pageTitles.logsReports');
      const excelName: string = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;
      const excelData: IFormSubmission[] = LogsReportsService.formatLogsForExcel(
        this.homeService.formatFormSubmissions(_.get(responseList, '0.data', []), true),
      );
      const excelOptions: ICreateExcel = this.getLogsExcelColumns(selectedColumns);
      excelOptions.data = excelData;

      this.excelHelperService
        .createExcel(
          sheetTitle,
          excelName,
          excelOptions,
          true,
          this.timezone,
          this.dateFormat$,
          this.timeFormat$,
          false,
          true,
          false,
          5001,
          false,
        )
        .then(
          () => {
            this.store.dispatch(new LogsReportsActions.DownloadExcelCompleted());
            this.store.dispatch(new AppActions.HideLoader());
          },
          () => {
            this.store.dispatch(new LogsReportsActions.FetchError(new HttpErrorResponse({})));
          },
        );
    });
  }

  private getLogsExcelColumns(selectedColumns: string[]): ICreateExcel {
    const excelColumns: ICreateExcel = {
      columns: [
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.logId'),
          key: 'id',
          width: 20,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.logbookName'),
          key: 'logbookName',
          width: 30,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.logbookId'),
          key: 'logbookLogbookId',
          width: 20,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.logbookLegacyId'),
          key: 'logbookLegacyId',
          width: 20,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.logbookVersion'),
          key: 'logbookVersionNumber',
          width: 20,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.parentLogbookName'),
          key: 'currentParentLogbookName',
          width: 30,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.createdBy'),
          key: 'createdBy',
          width: 35,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('home.logbook.createdAt'),
          key: 'createdAt',
          width: 30,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.submittedBy'),
          key: 'submittedBy',
          width: 35,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.submittedAt'),
          key: 'submittedAt',
          width: 30,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.formName'),
          key: 'formName',
          width: 30,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.formId'),
          key: 'formFormId',
          width: 20,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.formLegacyId'),
          key: 'formLegacyId',
          width: 20,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.formActivityType'),
          key: 'formActivityType',
          width: 20,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.formVersion'),
          key: 'formVersionNumber',
          width: 20,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.status'),
          key: 'approvalStepPosition',
          width: 20,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.mobileLogbook'),
          key: 'formattedIsMobileLogbook',
          width: 20,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.checkInMechanism'),
          key: 'useCheckIn',
          width: 20,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.sendBackCount'),
          key: 'sendBackCount',
          width: 20,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.combinedSendBackReasons'),
          key: 'combinedSendBackReasons',
          width: 40,
        },
        {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: this.translate.instant('general.datatable.headers.approvalDate'),
          key: 'approvalDate',
          width: 30,
        },
      ],
    };

    excelColumns.columns = excelColumns.columns
      .filter((column: IExcelColumnDefinition) => selectedColumns.includes(column.key))
      .sort(
        (a: IExcelColumnDefinition, b: IExcelColumnDefinition) =>
          selectedColumns.indexOf(a.key) - selectedColumns.indexOf(b.key),
      );

    this.excelHelperService.prepareExcelColumns(excelColumns.columns);

    return excelColumns;
  }

  private static formatLogsForExcel(logs: IFormSubmission[]): IFormSubmission[] {
    return logs.map((log: IFormSubmission) => {
      return Object.assign(log, {
        approvalDate:
          log.approvalStepPosition === EApprovalStatuses.APPROVED
          || log.approvalStepPosition === EApprovalStatuses.COMPLETED
            ? log.approvalDate : '',
        createdBy: log.createdBy?.email ?? '',
        submittedBy: log.submittedBy?.email ?? '',
        approvalStepPosition: _.get(log, 'statusText'),
      });
    });
  }

  public static getLogsReportsTableHeaderDefaults(translate: TranslateService): ITableHeader[] {
    return _.cloneDeep([
      {
        value: 'id',
        name: translate.instant('general.datatable.headers.logId'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
        disabled: true,
      },
      {
        value: 'logbookLogbookId',
        name: translate.instant('general.datatable.headers.logbookId'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'logbookName',
        name: translate.instant('general.datatable.headers.logbookName'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'logbookLegacyId',
        name: translate.instant('general.datatable.headers.logbookLegacyId'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'logbookVersionNumber',
        name: translate.instant('general.datatable.headers.logbookVersion'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'formFormId',
        name: translate.instant('general.datatable.headers.formId'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'formName',
        name: translate.instant('general.datatable.headers.formName'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'formLegacyId',
        name: translate.instant('general.datatable.headers.formLegacyId'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'formActivityType',
        name: translate.instant('general.datatable.headers.formActivityType'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'formVersionNumber',
        name: translate.instant('general.datatable.headers.formVersion'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'currentParentLogbookName',
        name: translate.instant('general.datatable.headers.parentLogbookName'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'formattedIsMobileLogbook',
        name: translate.instant('general.datatable.headers.mobileLogbook'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'createdBy',
        name: translate.instant('general.datatable.headers.createdBy'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'createdAt',
        name: translate.instant('home.logbook.createdAt'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'submittedBy',
        name: translate.instant('general.datatable.headers.submittedBy'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'submittedAt',
        name: translate.instant('general.datatable.headers.submittedAt'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'approvalStepPosition',
        name: translate.instant('general.datatable.headers.status'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'content',
        name: translate.instant('general.datatable.headers.history'),
        selected: true,
        sortable: false,
        draggable: true,
        disabled: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'useCheckIn',
        name: translate.instant('general.datatable.headers.checkInMechanism'),
        selected: true,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'sendBackCount',
        name: translate.instant('general.datatable.headers.sendBackCount'),
        selected: false,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'combinedSendBackReasons',
        name: translate.instant('general.datatable.headers.combinedSendBackReasons'),
        selected: false,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'approvalDate',
        name: translate.instant('general.datatable.headers.approvalDate'),
        selected: false,
        sortable: false,
        draggable: true,
        type: PageConfigurationTypes.TABLE,
      },
    ]);
  }
}
