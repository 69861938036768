import { Component, Injector, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  ApiServer,
  DependencyType,
  DropdownFilterConfiguration,
  IDropdownSettings,
  ILazyLoading,
} from './dropdown.model';
import { TranslateService } from '@ngx-translate/core';
import { FilterableObjectTypes, TypeFilterableObjects } from '../filterable-objects.class';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { IDropdownItemFilter, IFilterComponent, FilterDataObjectTypes, IOutputOptions } from '../filter.class';
import * as _ from 'lodash';
import { generateSearchObject } from '../../../helper/app-helper';
import { IFilterCondition } from '../../../../store/filter/filter.model';
import { IGenericObject, TEmpty } from '../../../model/interface/generic.model';
import { ScwMatSelectComponent } from '../../scw-mat-ui/scw-mat-select/scw-mat-select.component';

@Component({
  selector: 'filter-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownComponent implements OnInit, OnDestroy, IFilterComponent {
  @ViewChild('scwMatSelect') scwMatSelect?: ScwMatSelectComponent;

  outputOptions!: IOutputOptions;
  dropdownList: FilterDataObjectTypes[] = [];
  selectedItems: FilterDataObjectTypes[] = [];
  public dropdownObject!: FilterableObjectTypes;
  @Input() dropdownSettings!: IDropdownSettings;
  @Input() dropdownObjectClass!: TypeFilterableObjects;
  @Input() depends!: DependencyType;
  @Input() filter!: IDropdownItemFilter;
  @Input() strictControlForSelected: boolean = false;
  public elementID!: string;
  public searchModel: string | null = null;
  public defaultSettings: IDropdownSettings = {
    singleSelection: false,
    text: this.translate.instant('filterCard.dropdown.text'),
    enableCheckAll: true,
    selectAllText: this.translate.instant('filterCard.dropdown.selectAllText'),
    unSelectAllText: this.translate.instant('filterCard.dropdown.unSelectAllText'),
    filterSelectAllText: this.translate.instant('filterCard.dropdown.filterSelectAllText'),
    filterUnSelectAllText: this.translate.instant('filterCard.dropdown.filterUnSelectAllText'),
    enableSearchFilter: false,
    searchBy: [],
    maxHeight: 300,
    badgeShowLimit: 1,
    classes: '',
    disabled: false,
    searchPlaceholderText: this.translate.instant('filterCard.dropdown.searchPlaceholderText'),
    showCheckbox: true,
    noDataLabel: this.translate.instant('filterCard.dropdown.noDataLabel'),
    searchAutofocus: true,
    lazyLoading: false,
    labelKey: 'itemName',
    primaryKey: 'id',
    position: 'bottom',
    autoPosition: true,
    enableFilterSelectAll: true,
    selectGroup: false,
    addNewItemOnFilter: false,
    addNewButtonText: this.translate.instant('filterCard.dropdown.addNewButtonText'),
    escapeToClose: true,
    clearAll: true,
    isRequired: false,
    searchMinimumCharacter: 3,
  };
  filterListenerConfiguration: DropdownFilterConfiguration[] = [];
  outputSubject!: Subject<any>;
  submit: boolean = false;
  isLoading$: boolean = true;
  private filterConfigSorted: boolean = false;
  private searchedData: boolean = false;
  private filterSelectedItems: FilterDataObjectTypes[] = [];
  private lazyLoadingLastPage: number = 0;
  private dropdownSubscribe!: Subscription;
  public disabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private disabledSubjectSubscription!: Subscription;
  public isDisabled: boolean = false;
  public dependedOptionConfiguration = [];

  constructor(private translate: TranslateService, private injector: Injector) {}

  ngOnInit(): void {
    this.dropdownObject = this.injector.get<FilterableObjectTypes>(this.dropdownObjectClass);
    this.dropdownObject.setApiServer(_.get(this.dropdownSettings, 'server', ApiServer.Django));
    this.dropdownSubscribe = this.dropdownObject.getDataObservable().subscribe((data: FilterDataObjectTypes[]) => {
      this.compareItemsBySelectedItems(data);

      this.isLoading$ = false;
      const dropdownData = this.getDataWithInitialData.call(this, data);
      this.dropdownList = dropdownData.slice();

      if (this.depends !== undefined) {
        this.filterData.call(this, this.dropdownList);
      }

      this.applyFilterCondition();
      this.generateSingleSelectDropdownList();

      if (!this.searchedData && this.dropdownSettings.selectAll) {
        this.selectedItems = [...dropdownData];
        this.publishSelectedItems();
      } else if (!this.searchedData && this.dropdownSettings.defaultSelection) {
        const values: (string | number)[] = this.dropdownSettings.defaultSelection.values;
        const key = this.dropdownSettings.defaultSelection.key as keyof FilterDataObjectTypes;
        this.selectedItems = _.clone(dropdownData).filter((item) => {
          if (item.hasOwnProperty(key)) {
            // Don't use type check (===) here.
            // tslint:disable-next-line:triple-equals
            return values.some((x: string | number) => x == item[key]);
          }
          return false;
        });

        this.overRideDropdownData();
        this.publishSelectedItems();
      }
    });

    this.dropdownObject.init();

    this.defaultSettings = {
      ...this.defaultSettings,
      ...this.dropdownSettings,
      labelKey: this.dropdownObject.getObjectNameProp(),
    };

    this.defaultSettings.text =
      !this.dropdownSettings.isRequired && !this.dropdownSettings.singleSelection
        ? `${this.defaultSettings.text}: ${this.translate.instant('filterCard.dropdown.any')}`
        : this.defaultSettings.text;

    this.disabledSubjectSubscription = this.disabledSubject.asObservable().subscribe((disabledStatus: boolean) => {
      this.isDisabled = disabledStatus;
    });
  }

  public onItemSelect(): void {
    this.publishSelectedItems();
    this.publishValue(this.selectedItems);
  }

  public onItemDeselect(): void {
    this.publishSelectedItems();
    this.publishValue(this.selectedItems);
  }

  public onSelectAll(): void {
    this.publishSelectedItems();
    this.publishValue(this.selectedItems);
  }

  public onGroupSelect(): void {
    this.publishSelectedItems();
    this.publishValue(this.selectedItems);
  }

  public onGroupDeSelect(): void {
    this.publishSelectedItems();
    this.publishValue(this.selectedItems);
  }

  public onDeSelectAll(): void {
    this.selectedItems = [];
    this.publishSelectedItems();
    this.publishValue(this.selectedItems);
  }

  /**
   * To use the lazy loading feature,
   * The dropdownList should be empty when the dropdown generated.
   * the dropdown data should be appended to the store after the response has been received.
   * @param event
   */
  public fetchMore(event: ILazyLoading): void {
    if (this.isLoading$ || event.endIndex !== this.dropdownList.length - 1) {
      return;
    }

    const limit: number = this.dropdownSettings.lazyLoadingLimit ?? 10;
    const page: number = Math.ceil((event.endIndex !== -1 ? event.endIndex : 0) / limit);

    if (this.lazyLoadingLastPage === page) {
      return;
    }

    this.lazyLoadingLastPage = page;
    this.isLoading$ = true;
    this.dropdownObject.getFilterData({
      page,
      limit,
    });
  }

  public subscribeFilterListener(configuration: DropdownFilterConfiguration): void {
    configuration.filterListener.asObservable().subscribe((filterData) => {
      const result = this.filterListenerConfiguration.find(
        (config: DropdownFilterConfiguration) => config.elementId === configuration.elementId,
      );

      if (result === undefined) {
        return;
      }

      result.filterData = filterData;

      if (!this.dropdownSettings.enableServerSideSearch) {
        this.filterData();
        return;
      }

      const parentIds = this.getParentIds();
      const hasDepends = this.depends !== undefined;
      if (hasDepends && parentIds.length > 0) {
        this.isLoading$ = true;
        const filterObject = this.generateConditionObject(this.searchModel);
        this.dropdownObject.getFilterData({ search: filterObject });
      } else if (hasDepends && this.selectedItems.length !== 0) {
        this.selectedItems = this.dropdownList = [];
        this.publishSelectedItems();
      }
    });
  }

  private applyFilterCondition(): void {
    if (!this.filter) return;
    if (this.filter.preCondition && !this.filter.preCondition(this.dropdownList)) return;

    this.dropdownList = this.dropdownList.filter(this.filter.condition);
  }

  private prepareSearchResults(result: any, getDataFromServiceRequired: boolean): void {
    if (!getDataFromServiceRequired) return;

    const filterObject = this.generateConditionObject(result);
    this.isLoading$ = true;
    this.selectedItems = [];
    this.dropdownObject.getFilterData({ search: filterObject });
  }

  private prepareFilterListenerConfigurations(config: any, getDataFromServiceRequired: boolean): void {
    if (
      config.filterData &&
      config.filterData.length === 0 &&
      this.depends !== undefined &&
      this.selectedItems.length !== 0
    ) {
      this.dropdownList = [];
      this.selectedItems = [];
      this.publishSelectedItems();
      return;
    }

    if (!config.submit && config.filterData !== undefined) {
      const result = config.isDataLocatedAtParent
        ? _.uniq(
          _.flatten(
            config.filterData.map((element: any) =>
              element[config.dataPathAtParent] ? element[config.dataPathAtParent].split(',').map(Number) : null,
            ),
          ),
        )
        : config.filterData.map((element: any) => element[this.depends.elementIdKey]);

      this.prepareSearchOrApply(result, getDataFromServiceRequired);
    } else {
      config.filterListener.next(this.dropdownList);
    }

    this.selectedItemPublisher();
  }

  public filterData(dropdownList: FilterDataObjectTypes[] = []): void {
    let getDataFromServiceRequired = false;
    if (this.dropdownObject === undefined) {
      return;
    }

    this.dropdownList = dropdownList;

    if (dropdownList === null) {
      this.dropdownList = this.dropdownObject.getInitialData();
      this.applyFilterCondition();
    }

    if (dropdownList === null && this.depends !== undefined) {
      getDataFromServiceRequired = true;
    }

    if (!this.filterConfigSorted) {
      this.filterListenerConfiguration.sort((a, b) => {
        const submitValue: number = !a.submit ? -1 : 1;
        return a.submit === b.submit ? 0 : submitValue;
      });
      this.filterConfigSorted = true;
    }

    this.filterListenerConfiguration.forEach((config) => {
      this.prepareFilterListenerConfigurations(config, getDataFromServiceRequired);
    });
  }

  private filterElement(array: FilterDataObjectTypes[], search: FilterDataObjectTypes[]): FilterDataObjectTypes[] {
    return array.filter((element) => {
      let filterData: any = element[this.depends.searchBy as keyof FilterDataObjectTypes];
      if (filterData === '*') {
        return true;
      }

      if (this.depends.splitBy) {
        filterData = filterData.split(this.depends.splitBy);
        return search.some((data) => {
          return filterData.includes(data !== undefined ? data.toString() : undefined);
        });
      }

      return search.includes(filterData);
    });
  }

  public publishSelectedItems(selectedItems: FilterDataObjectTypes[] = this.selectedItems): void {
    this.outputSubject.next(this.getFiltersOutputs());
    this.filterListenerConfiguration.forEach((config) => {
      if (config.submit) {
        config.filterListener.next(selectedItems);
      }
    });
  }

  public getFiltersOutputs(): any {
    const output: IGenericObject<any> = {};
    const isSearchedData = this.searchModel !== null && this.searchModel.length > 0;

    if (this.dropdownSettings.isRequired && this.selectedItems.length === 0) {
      output[this.outputOptions.filterObjectId] = null;
      return output;
    }

    if (
      !isSearchedData &&
      (this.selectedItems.length === 0 ||
        (this.selectedItems.length === this.dropdownList.length && !this.strictControlForSelected))
    ) {
      output[this.outputOptions.filterObjectId] = -1;
      return output;
    }

    if (this.outputOptions.returnFilterObjectAllProp) {
      output[this.outputOptions.filterObjectId] = this.selectedItems;
      return output;
    }

    output[this.outputOptions.filterObjectId] = this.selectedItems.map(
      (item) => item[this.outputOptions.filterObjectProp as keyof FilterDataObjectTypes],
    );
    return output;
  }

  onSearch(searchString: any): void {
    const search = searchString;
    const searchInputLength = search.length;

    if (searchInputLength === 0 || searchInputLength >= (this.defaultSettings.searchMinimumCharacter ?? 0)) {
      this.searchedData = this.isLoading$ = true;
      this.filterSelectedItems = [];
      const filterObject = this.generateConditionObject(search);
      this.dropdownObject.getFilterData({ search: filterObject });
    }
  }

  onSearchComplete(searchResult: FilterDataObjectTypes[] | undefined): void {
    if (searchResult) {
      this.compareItemsBySelectedItems(searchResult);
    }
  }

  private compareItemsBySelectedItems(results: FilterDataObjectTypes[]): void {
    if (!results || results.length === 0) {
      return;
    }

    const primaryKey: string | undefined = this.defaultSettings.primaryKey;
    const key: string = !primaryKey ? 'id' : primaryKey;

    const filterResult: FilterDataObjectTypes[] = results.filter((resultItem: any) => {
      return this.selectedItems.some((selectedItem: any) => {
        return selectedItem[key] === resultItem[key];
      });
    });

    this.scwMatSelect?.configureSelectAllCheckbox(filterResult.length === results.length);
  }

  private getParentIds(): number[] {
    const dependIds: number[] = [];
    const result = this.filterListenerConfiguration.find(
      (config: DropdownFilterConfiguration) => config.elementId === this.elementID,
    );

    if (result?.filterData !== undefined) {
      result.filterData.forEach((value: any) => {
        dependIds.push(value[this.depends.elementIdKey]);
      });
    }

    return dependIds;
  }

  private generateNestJSSearchCondition(search: string): IFilterCondition[] {
    if (this.dropdownSettings.searchProps === undefined) {
      this.dropdownSettings.searchProps = [
        {
          prop: 'name',
          condition: '$cont',
        },
      ];
    }

    const filterObject = generateSearchObject(search, this.dropdownSettings.searchProps);

    if (this.depends === undefined) {
      return filterObject;
    }

    const parentIds = this.getParentIds();
    const dependFilter: IFilterCondition = {
      prop: this.depends.searchBy,
      condition: '$in',
      query: parentIds.toString(),
      isNotOrOperand: true,
    };
    filterObject.push(dependFilter);

    return filterObject;
  }

  private generatePhreezeSearchCondition(): IFilterCondition[] {
    const parentIds = this.getParentIds();
    const dependFilter: IFilterCondition = {
      prop: this.depends.searchBy,
      condition: '$in',
      query: parentIds.toString(),
    };
    return [dependFilter];
  }

  private generateConditionObject(search: string | TEmpty): string {
    return search ?? '';
  }

  private removeDuplicates(array: any[], prop: any): any[] {
    return array.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  private getDataWithInitialData(data: FilterDataObjectTypes[]): FilterDataObjectTypes[] {
    if (_.get(this.dropdownSettings, 'defaultSelection.initialData', false)) {
      return this.dropdownObject.getDataWithInitialData(this.dropdownSettings.defaultSelection?.initialData ?? []);
    }

    return data;
  }

  public subscribeDependedOptionListener(configuration: any): void {
    configuration.dependedOptionListener.asObservable().subscribe((config: any) => {
      if (this.elementID === config?.dependedElementId && this.hasOwnProperty(config?.dependedOption)) {
        _.set(this, config?.dependedOption, config?.getDependentValue(config?.value));
      }
    });
  }

  public publishValue(value: any): void {
    this.dependedOptionConfiguration.forEach((config: any) => {
      if (config?.submit) {
        config.dependedOptionListener.next({
          ...config,
          value,
        });
      }
    });
  }

  private generateSingleSelectDropdownList(): void {
    if (this.dropdownSettings.singleSelection || !this.filterSelectedItems.length) {
      return;
    }

    const objectIdProp =
      this.dropdownSettings.primaryKey !== undefined
        ? this.dropdownSettings.primaryKey
        : this.defaultSettings.primaryKey;
    this.dropdownList = [...this.dropdownList, ...this.filterSelectedItems];
    this.dropdownList = this.removeDuplicates(this.dropdownList, objectIdProp);

  }

  public overRideDropdownData(): void {
    if (_.get(this.dropdownSettings, 'defaultSelection.overrideData', false)) {
      this.dropdownList = _.clone(this.selectedItems);
    }
  }

  public selectedItemPublisher(): void {
    if (this.selectedItems.length !== 0) {
      this.publishSelectedItems();
    }
  }

  public prepareSearchOrApply(result: any[], getDataFromServiceRequired: boolean): void {
    if (!result.length) {
      return;
    }

    if (this.depends.hasOwnProperty('serverSideFilter') && this.depends.serverSideFilter) {
      this.prepareSearchResults(result, getDataFromServiceRequired);
    } else {
      this.dropdownList = this.filterElement(this.dropdownList, result);
      this.applyFilterCondition();
      this.selectedItems = this.filterElement(this.selectedItems, result);
    }

  }

  ngOnDestroy(): void {
    if (this.dropdownObject.subscribe !== null) {
      this.dropdownObject.subscribe.unsubscribe();
    }

    this.dropdownSubscribe.unsubscribe();
    this.disabledSubjectSubscription.unsubscribe();
    this.disabledSubject.complete();
  }
}
