import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../../logbook.reducer';
import { forkJoin, map, Observable, Subject } from 'rxjs';
import { IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { HelperService } from '../../../shared/service/helper.service';
import {
  IFieldVersion,
  IFormTemplateData,
  IFormTemplateDataExcel,
  IFormTemplateTableQuery,
} from '../../../view/reports/form-templates/form-templates.model';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import {
  CellTypes,
  ExcelHelperService,
  ICreateExcel,
  IExcelColumnDefinition,
} from '../../../shared/service/excel/excel-helper.service';
import * as FormTemplatesActions from '../../reports/form-templates/form-templates.actions';
import { TranslateService } from '@ngx-translate/core';
import { ITableHeader } from 'src/constants.model';
import { takeUntil } from 'rxjs/operators';
import { excelDateFormat, excelTimeFormat, IExcelDateFormat } from '../../../shared/model/enum/excel-date-format';
import { ValueType } from 'exceljs';
import * as AppActions from '../../../../app/store/app/actions';
import { IGenericObject } from '../../../shared/model/interface/generic.model';
import { EFormActivityType, IFormVersionHistory } from '../../forms/forms.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { WorkflowsService } from '../../settings/workflows/workflows.service';
import { IGetFormTemplates, IGetFormTemplatesAndMasterData } from './form-templates.model';
import { FormMasterDataService } from '../../settings/form-master-data/form-master-data.service';
import {
  FieldTypes,
  IFilterOutput,
  SqlOperators,
} from '../../../shared/component/filter/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../../../shared/component/filter/advanced-filter/advanced-filter.service';
import { ISelect } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ComponentUtilities } from '../../../shared/helper/component-utilities';
import { DEFAULT_EXCEL_COLUMN_TRUNCATE_OPTIONS } from '../../../../constants';
import { TemplatesHelperService } from '../../../shared/service/reports/templates/templates-helper.service';
import { EApprovalStatuses } from '../../../shared/model/enum/constants';

@Injectable({
  providedIn: 'root',
})
export class FormTemplatesService {
  private readonly URLS = {
    BASE_URL: '/form-submissions/',
    MASTER_DATA_URL: '/field-version-items/',
    FORM_TEMPLATES: '/form-versions/',
    FORM_TEMPLATES_HISTORY: '/user-actions/form-versions',
  };
  private timezone$: string = 'utc';
  private dateFormat$!: string;
  private timeFormat$!: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private readonly commonExcelAttributes: Partial<IExcelColumnDefinition> = {
    type: ValueType.String,
    isRequired: false,
    dataValidation: {
      type: CellTypes.CUSTOM,
      allowBlank: false,
      showErrorMessage: true,
      formulae: [],
      errorStyle: 'Error',
      showInputMessage: false,
    },
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    public readonly helperService: HelperService,
    public readonly store: Store<logbookAppReducer.LogbookAppState>,
    public readonly translate: TranslateService,
    public readonly excelHelperService: ExcelHelperService,
    public readonly workflowsService: WorkflowsService,
    public readonly formMasterDataService: FormMasterDataService,
    private readonly advancedFilterService: AdvancedFilterService,
    private readonly templatesHelperService: TemplatesHelperService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone$ = state.timezone ?? 'utc';

          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale as keyof IExcelDateFormat];
            this.timeFormat$ = excelTimeFormat[state.locale as keyof typeof excelTimeFormat];
          }

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getAllMasterData(params: Record<string, string | number>): Observable<IGetManyResponse<IFieldVersion>> {
    const paramsWithType: Record<string, string | number> = { ...params, field_version_type: 'form' };

    return this.http.post<IGetManyResponse<IFieldVersion>>(
      `${this.baseUrl}${this.URLS.MASTER_DATA_URL}`,
      {
        ...paramsWithType,
      },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getFormTemplates(params: Record<string, string | number>): Observable<IGetManyResponse<IFormTemplateData>> {
    return this.http.post<IGetManyResponse<IFormTemplateData>>(
      `${this.baseUrl}${this.URLS.FORM_TEMPLATES}`,
      { ...params },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getFormTemplate(id: number): Observable<IGetManyResponse<IFormTemplateData>> {
    const params: HttpParams = new HttpParams().append('form_version_id', id);
    return this.http.get<IGetManyResponse<IFormTemplateData>>(`${this.baseUrl}${this.URLS.FORM_TEMPLATES}`, { params });
  }

  public async downloadExcel(
    filters: IFormTemplateTableQuery,
    selectedColumns: ITableHeader[],
    approvalStatuses: IGenericObject<string>,
    activityTypeDropdownItems: ISelect<EFormActivityType, string>[],
    rawMasterDataColumns: ITableHeader[],
  ): Promise<void> {
    forkJoin(this.getObservables(filters)).subscribe((responseList) => {
      const sheetTitle: string = this.translate.instant('pageTitles.formTemplates');
      const excelName: string = `${sheetTitle} ${moment().tz(this.timezone$).format(this.dateFormat$)}`;
      const formattedSelectedColumns: ITableHeader[] = _.cloneDeep(selectedColumns);
      let excelData: IFormTemplateDataExcel[] = [];

      excelData = _.get(responseList, '0.data', []) as IFormTemplateDataExcel[];
      excelData = excelData.map((item: IFormTemplateDataExcel) => {
        const activityType: ISelect<EFormActivityType, string> | undefined = _.head(
          ComponentUtilities.findOneOptionForSelect(activityTypeDropdownItems, item.activityType, 'id'),
        );
        return {
          ...item,
          name: this.helperService.getNameWithArchiveLabel(item.form.isArchived, item.name),
          createdAt: this.helperService.formatDateTimeTz(item.createdAt),
          submittedAt: this.helperService.formatDateTimeTz(item.submittedAt),
          issuedDate: this.helperService.formatDateTimeTz(item.issuedDate),
          approvalStepPosition:
            approvalStatuses[item.approvalStepPosition] || approvalStatuses[EApprovalStatuses.SUBMITTED],
          formIdFormatted: item.form.formId,
          workflowDetailFormatted: this.helperService.getNoApprovalRequiredWorkflowName(item.workflowDetail),
          workflowEntriesDetailFormatted: this.helperService.getNoApprovalRequiredWorkflowName(
            item.workflowEntriesDetail,
          ),
          checkInMechanism: item.useCheckIn
            ? this.translate.instant('general.yes')
            : this.translate.instant('general.no'),
          activityTypeFormatted: activityType ? activityType.name : null,
          createdByEmail: item.createdBy?.email ?? '',
          submittedByEmail: item.submittedBy?.email ?? '',
        };
      });

      selectedColumns.forEach((item: ITableHeader, index: number) => {
        if (item.value === 'createdBy' || item.value === 'submittedBy') {
          formattedSelectedColumns[index] = { ...item, value: `${item.value}Email` };
        }
      });

      excelData = this.templatesHelperService.formatTemplateMasterDataValues(excelData, rawMasterDataColumns);

      const excelOptions: ICreateExcel = this.getExcelColumns(formattedSelectedColumns);

      excelOptions.data = excelData;

      this.excelHelperService
        .createExcel(
          sheetTitle,
          excelName,
          excelOptions,
          true,
          this.timezone$,
          this.dateFormat$,
          this.timeFormat$,
          false,
          true,
          true,
          1001,
          true,
          undefined,
          true,
        )
        .then(
          () => {
            this.store.dispatch(new FormTemplatesActions.DownloadFormTemplatesExcelCompleted());
            this.store.dispatch(new AppActions.HideLoader());
          },
          () => {
            this.store.dispatch(new FormTemplatesActions.FetchError({}));
            this.store.dispatch(new AppActions.HideLoader());
          },
        );
    });
  }

  private getObservables(filters: IFormTemplateTableQuery) {
    let body: Record<string, string | number> = { limit: 5000 };
    const observables: Observable<IGetManyResponse<IFormTemplateData>>[] = [];

    body = { ...body, ordering: '-id' };

    if (Array.isArray(filters.selectedStatuses)) {
      body = { ...body, statuses: JSON.stringify(filters.selectedStatuses) };
    }

    if (Array.isArray(filters.selectedForms)) {
      body = { ...body, forms: JSON.stringify(filters.selectedForms) };
    }

    if (Array.isArray(filters.selectedLogbooks)) {
      body = { ...body, logbooks: JSON.stringify(filters.selectedLogbooks) };
    }

    if (filters.selectedDateRange) {
      body = { ...body, date: JSON.stringify(filters.selectedDateRange) };
    }

    if (filters.page) {
      body = { ...body, offset: 0 };
    }

    if (!_.isEmpty(filters.advancedFilter)) {
      body = { ...body, advanced_filter: JSON.stringify(this.getSearchString(filters)) };
    }

    observables.push(this.getFormTemplates(body));

    return observables;
  }

  private getExcelColumns(selectedColumns: ITableHeader[]): ICreateExcel {
    const columns: IExcelColumnDefinition[] = selectedColumns.map((column: ITableHeader) => {
      const columnName: string =
        column.value === 'workflowDetailFormatted'
          ? this.translate.instant('general.datatable.headers.templateApprovalWorkflow')
          : column.name;

      return {
        ...(this.commonExcelAttributes as IExcelColumnDefinition),
        header: _.truncate(columnName, DEFAULT_EXCEL_COLUMN_TRUNCATE_OPTIONS),
        key: _.truncate(column.value, DEFAULT_EXCEL_COLUMN_TRUNCATE_OPTIONS),
        width: 30,
      };
    });

    const excelColumns: ICreateExcel = {
      columns: columns.filter(
        (column: IExcelColumnDefinition) => !['submittedBy', 'createdBy', 'actions'].includes(column.key),
      ),
    };

    this.excelHelperService.prepareExcelColumns(excelColumns.columns);

    return excelColumns;
  }

  public getFormTemplatesHistory(id: number): Observable<IGetManyResponse<IFormVersionHistory>> {
    const params: HttpParams = new HttpParams().append('versionIds', id);

    return this.http.get<IGetManyResponse<IFormVersionHistory>>(`${this.baseUrl}${this.URLS.FORM_TEMPLATES_HISTORY}`, {
      params,
    });
  }

  public getFormTemplateData(id: number, issuer?: IIssuer): Observable<IGetFormTemplates> {
    const params: HttpParams = new HttpParams()
      .append('limit', 1000)
      .append('just_workflow_data', true)
      .append('include_disabled', true);
    const observables: any = [this.workflowsService.getWorkflows(params, issuer), this.getFormTemplate(id)];

    return forkJoin(observables).pipe(
      map((responseList) => {
        return {
          workflows: _.get(responseList, '0.data', null),
          formVersion: _.get(responseList, '1.data', []),
        };
      }),
    );
  }

  public getFormTemplateDataAndMasterData(
    fieldVersion: number,
    id: number,
    issuer?: IIssuer,
  ): Observable<IGetFormTemplatesAndMasterData> {
    const params: HttpParams = new HttpParams()
      .append('limit', 1000)
      .append('just_workflow_data', true)
      .append('include_disabled', true);
    const observables: any = [
      this.workflowsService.getWorkflows(params, issuer),
      this.getFormTemplate(id),
      this.formMasterDataService.getFormMasterDetailDataMainPart(fieldVersion),
      this.formMasterDataService.getFormMasterDetailDataFieldsPart(fieldVersion),
    ];

    return forkJoin(observables).pipe(
      map((responseList) => {
        return {
          workflows: _.get(responseList, '0.data', null),
          formVersion: _.get(responseList, '1.data', []),
          formMasterDataDetail: { ..._.get(responseList, '2.data', null), fields: _.get(responseList, '4.data', []) },
        };
      }),
    );
  }

  public getSearchString(tableQuery: IFormTemplateTableQuery): object[] {
    const httpParamString: object[] = [];

    if (tableQuery.advancedFilter) {
      const advancedFilter = tableQuery.advancedFilter.filters;

      for (const filter of advancedFilter) {
        if (FieldTypes.predefined) {
          httpParamString.push(this.generateQueryForPredefined(filter, tableQuery));
        } else {
          httpParamString.push(this.generateQueryForDefault(filter, tableQuery));
        }
      }
    }

    return httpParamString;
  }

  private generateQueryForPredefined(filter: IFilterOutput, tableQuery: IFormTemplateTableQuery): object {
    if (!tableQuery?.advancedFilter) {
      return {};
    }

    const filterValue: string = _.get(filter.value, `[0][${filter.searchBy}]`, '');
    const param: string | void = this.advancedFilterService.generateQuery(
      filter.path,
      filter.type,
      filter.operator.name,
      filter.operator.type,
      tableQuery.advancedFilter.target,
      filterValue,
    );

    if (param) {
      if (filter.operator.name === SqlOperators.isNull) {
        return { param, value: true };
      }

      if (filter.operator.name === SqlOperators.isNotNull) {
        return { param: `${filter.path}__isnull`, value: false };
      }

      if (['use_check_in'].includes(filter.path)) {
        return {
          param: filter.path.includes('__iexact') ? `${filter.path}`.replace('__iexact', '') : param,
          value: filter.value[0].id,
        };
      }

      return { param, value: filter.value };
    }
    return {};
  }

  private generateQueryForDefault(filter: IFilterOutput, tableQuery: IFormTemplateTableQuery): object {
    if (!tableQuery?.advancedFilter) {
      return {};
    }

    const param: string | void = this.advancedFilterService.generateQuery(
      filter.path,
      filter.type,
      filter.operator.name,
      filter.operator.type,
      tableQuery.advancedFilter.target,
      _.get(filter.value, `[0][${filter.searchBy}]`, ''),
    );

    if (param) {
      if (filter.operator.name === SqlOperators.isNull) {
        return { param, value: true };
      }

      if (filter.operator.name === SqlOperators.isNotNull) {
        return { param: `${filter.path}__isnull`, value: false };
      }

      return { param, value: filter.value };
    }

    return {};
  }
}
