import { Action } from '@ngrx/store';
import { IBaseOneResponse, IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import {
  IFieldVersion,
  IFormTemplateData,
  IFormTemplateTableQuery,
} from '../../../view/reports/form-templates/form-templates.model';
import { ITableHeader } from '../../../../constants.model';
import { EFormActivityType, IFormVersionHistory } from '../../forms/forms.model';
import { IFormMasterDataDetail } from '../../settings/form-master-data/form-master-data.model';
import { IWorkflowStepsData } from '../../../view/settings/workflows/workflows.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IGetFormTemplates, IGetFormTemplatesAndMasterData } from './form-templates.model';
import { IGenericObject } from '../../../shared/model/interface/generic.model';
import { ISelect } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { IDateRangeReturn } from '../../../shared/component/date-range-picker/date-range-picker.model';

export const GET_ALL_MASTER_DATA = '[FORM TEMPLATES] GET ALL MASTER DATA';
export const GET_ALL_MASTER_DATA_COMPLETED = '[FORM TEMPLATES] GET ALL MASTER DATA COMPLETED';
export const GET_FORM_TEMPLATES = '[FORM TEMPLATES] GET ALL FORM TEMPLATES';
export const GET_FORM_TEMPLATES_COMPLETED = '[FORM TEMPLATES] GET ALL FORM TEMPLATES COMPLETED';
export const SET_TABLE_SETTINGS = '[FORM TEMPLATES] FORM TEMPLATES TABLE SETTINGS SET';
export const SET_SELECTED_FILTERS = '[FORM TEMPLATES] SET SELECTED FILTERS';
export const DOWNLOAD_FORM_TEMPLATES_EXCEL = '[FORM TEMPLATES] DOWNLOAD FORM TEMPLATES';
export const DOWNLOAD_FORM_TEMPLATES_EXCEL_COMPLETED = '[FORM TEMPLATES] DOWNLOAD FORM TEMPLATES COMPLETED';
export const GET_MASER_DATA = '[FORM TEMPLATES] GET MASTER DATA';
export const GET_MASER_DATA_LOADED = '[FORM TEMPLATES] GET MASTER DATA LOADED';
export const GET_FORM_TEMPLATE_DATA_LOADING = '[FORM TEMPLATES] GET FORM TEMPLATE DATA LOADING';
export const GET_FORM_TEMPLATE_DATA_LOADED = '[FORM TEMPLATES] GET FORM TEMPLATE DATA LOADED';
export const GET_FORM_TEMPLATE_HISTORY = '[FORM TEMPLATES] GET FORM TEMPLATE HISTORY LOADING';
export const GET_FORM_TEMPLATE_HISTORY_LOADED = '[FORM TEMPLATES] GET FORM TEMPLATE HISTORY LOADED';
export const WORKFLOW_STEP_DATA_LOADING = '[FORM TEMPLATES] WORKFLOW STEP DATA LOAD';
export const WORKFLOW_STEP_DATA_LOADED = '[FORM TEMPLATES] WORKFLOW STEP DATA LOADED';
export const FETCH_ERROR = '[FORM TEMPLATES] FETCH ERROR';
export const GET_MASER_DATA_FORM_TEMPLATE_DATA_LOADING = '[FORM TEMPLATES] GET MASER DATA FORM TEMPLATE DATA LOADING';
export const GET_MASER_DATA_FORM_TEMPLATE_DATA_LOADED = '[FORM TEMPLATES] GET MASER DATA FORM TEMPLATE DATA LOADED';
export const GET_ALL_MASTER_DATA_COMPLETED_TO_ZIP = '[FORM TEMPLATES] GET ALL MASTER DATA COMPLETED TO ZIP';
export const GET_FORM_TEMPLATES_COMPLETED_TO_ZIP = '[FORM TEMPLATES] GET FORM TEMPLATES COMPLETED TO ZIP';

export class GetAllMasterData implements Action {
  readonly type = GET_ALL_MASTER_DATA;

  constructor(
    public formIds: number[] | null,
    public logbookIds: number[] | null,
    public dispatchHideLoader: boolean = true,
    public sourceObjectDate: IDateRangeReturn | null,
  ) {}
}

export class GetAllMasterDataCompleted implements Action {
  readonly type = GET_ALL_MASTER_DATA_COMPLETED;

  constructor(public payload: IGetManyResponse<IFieldVersion>) {}
}

export class GetFormTemplates implements Action {
  readonly type = GET_FORM_TEMPLATES;
  constructor(public tableQuery: IFormTemplateTableQuery, public dispatchHideLoader: boolean = true) {}
}

export class GetFormTemplatesCompleted implements Action {
  readonly type = GET_FORM_TEMPLATES_COMPLETED;

  constructor(public payload: IGetManyResponse<IFormTemplateData>) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: ITableHeader[], public setAsDefault: boolean) {}
}

export class SetSelectedFilters implements Action {
  readonly type = SET_SELECTED_FILTERS;

  constructor(public filters: IFormTemplateTableQuery) {}
}

export class DownloadFormTemplatesExcel implements Action {
  readonly type = DOWNLOAD_FORM_TEMPLATES_EXCEL;

  constructor(
    public filters: IFormTemplateTableQuery,
    public columns: ITableHeader[],
    public approvalStatuses: IGenericObject<string>,
    public activityTypeDropdownItems: ISelect<EFormActivityType, string>[],
    public rawMasterDataColumns: ITableHeader[],
  ) {}
}

export class DownloadFormTemplatesExcelCompleted implements Action {
  readonly type = DOWNLOAD_FORM_TEMPLATES_EXCEL_COMPLETED;
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: object) {}
}

export class GetMasterDataLoading implements Action {
  readonly type = GET_MASER_DATA;

  constructor(public id: number) {}
}

export class GetMasterDataLoaded implements Action {
  readonly type = GET_MASER_DATA_LOADED;

  constructor(public payload: IBaseOneResponse<IFormMasterDataDetail>) {}
}

export class GetFormTemplatesData implements Action {
  readonly type = GET_FORM_TEMPLATE_DATA_LOADING;

  constructor(public id: number, public issuer?: IIssuer) {}
}

export class GetFormTemplatesDataLoaded implements Action {
  readonly type = GET_FORM_TEMPLATE_DATA_LOADED;

  constructor(public payload: IGetFormTemplates) {}
}

export class GetMasterDataFormTemplatesData implements Action {
  readonly type = GET_MASER_DATA_FORM_TEMPLATE_DATA_LOADING;

  constructor(public fieldVersion: number, public id: number, public issuer?: IIssuer) {}
}

export class GetMasterDataFormTemplatesDataLoaded implements Action {
  readonly type = GET_MASER_DATA_FORM_TEMPLATE_DATA_LOADED;

  constructor(public payload: IGetFormTemplatesAndMasterData) {}
}

export class GetFormTemplatesHistoryLoading implements Action {
  readonly type = GET_FORM_TEMPLATE_HISTORY;

  constructor(public id: number) {}
}

export class GetFormTemplatesHistoryLoaded implements Action {
  readonly type = GET_FORM_TEMPLATE_HISTORY_LOADED;

  constructor(public payload: IGetManyResponse<IFormVersionHistory>) {}
}

export class GetWorkflowStepData implements Action {
  readonly type = WORKFLOW_STEP_DATA_LOADING;

  constructor(public id: number) {}
}

export class GetWorkflowStepDataLoaded implements Action {
  readonly type = WORKFLOW_STEP_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IWorkflowStepsData>) {}
}

export class GetAllMasterDataCompletedToZip implements Action {
  readonly type = GET_ALL_MASTER_DATA_COMPLETED_TO_ZIP;
}

export class GetFormTemplatesCompletedToZip implements Action {
  readonly type = GET_FORM_TEMPLATES_COMPLETED_TO_ZIP;
}

export type FormTemplateActions =
  | GetAllMasterData
  | GetAllMasterDataCompleted
  | GetFormTemplates
  | GetFormTemplatesCompleted
  | SetTableSettings
  | DownloadFormTemplatesExcel
  | DownloadFormTemplatesExcelCompleted
  | FetchError
  | GetMasterDataLoading
  | GetMasterDataLoaded
  | GetFormTemplatesData
  | GetFormTemplatesDataLoaded
  | GetMasterDataFormTemplatesData
  | GetMasterDataFormTemplatesDataLoaded
  | GetFormTemplatesHistoryLoading
  | GetFormTemplatesHistoryLoaded
  | GetWorkflowStepData
  | GetWorkflowStepDataLoaded
  | GetFormTemplatesCompletedToZip
  | GetAllMasterDataCompletedToZip;
