import { Inject, Injectable } from '@angular/core';
import { IFilterService, ILogbooksCRUDResponse } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ILogbooksCRUD } from '../../component/filter/filter.class';
import * as _ from 'lodash';
import { ActiveStatuses } from '../../../../constants';
import { ILogbookFilterCard } from '../../../store/filter/filter.model';

@Injectable({
  providedIn: 'root',
})
export class LogbookService implements IFilterService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  private readonly routes: string = 'logbooks/';

  getLogbooks(filter: ILogbookFilterCard): Promise<ILogbooksCRUD[]> {
    let params: HttpParams = new HttpParams();
    if (filter.sortParams.sort !== null && filter.sortParams.direction !== null) {
      params = params.set('sort', filter.sortParams.sort);
      params = params.set('direction', filter.sortParams.direction);
    }

    if (filter.params.approvalStatuses && filter.params.approvalStatuses?.length > 0) {
      params = params.set('statuses', JSON.stringify(filter.params.approvalStatuses));
    }

    params = params.set('limit', filter.params.limit);
    params = params.set('page', filter.params.page);
    params = params.set('include_archived', filter.params.includeArchived);

    return this.getData(params);
  }

  public getData(params?: HttpParams): Promise<ILogbooksCRUD[]> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      this.http.get(`${this.baseUrl}/${this.routes}`, { params }).subscribe((response: ILogbooksCRUDResponse) => {
        if (response.hasOwnProperty('data')) {
          resolve(response.data);
        } else {
          reject();
        }
      });
    });
  }
}
