import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './home.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { of, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as LogbookAppReducer from '../logbook.reducer';
import * as AppActions from '../app/actions';
import * as UserActions from '../../store/user/actions';
import * as MainActions from '../../store/main/main.actions';
import * as _ from 'lodash';

import { IBaseOneResponse, IGetManyResponse } from '../../shared/model/interface/crud-response-interface.model';
import { ServiceUtilities } from '../../shared/helper/service-utilities';
import { LogbooksService } from '../logbooks/logbooks.service';
import { ILogbook } from '../logbooks/logbooks.model';
import { UserService } from '../../shared/service/user/user.service';
import { IConfig, User } from '../user/model';
import { emptyUserMeta } from '../../../constants';
import { IResponse } from '../../shared/model/interface/generic-api-response.model';
import { HomeService } from './home.service';
import {
  IFormSubmission,
  IFormSubmissionParams,
  IHomeFormSubmission,
  ILogbookHomeDetail,
  IHomeSearchResult,
} from './home.model';
import { IFormSubmissionUserAction, IUserAction } from '../../../constants.model';
import { FormsService } from '../forms/forms.service';
import { IFormVersion } from '../forms/forms.model';
import { EManualLogActionType, EManualLogObjectContentModel } from '../main/main.model';
import { IGetCurrentUserResponse } from '../app/model';

@Injectable()
export class HomeEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppReducer.LogbookAppState>,
    private readonly logbooksService: LogbooksService,
    private readonly formsService: FormsService,
    private readonly homeService: HomeService,
    private readonly userService: UserService,
  ) {}

  getLogbookData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FAVORITE_LOGBOOKS_DATA_LOADING),
      switchMap((payload: ObjectActions.FavoriteLogbooksDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
          page: payload.queryParams?.page ?? 1,
          rowsPerPage: payload.queryParams?.rowsPerPage ?? 10,
          sort: payload.queryParams?.sort ?? { type: '-', column: 'id' },
          ...(payload.queryParams?.search && { search: payload.queryParams.search }),
          ...(!_.isNil(payload.queryParams?.isActive) && { isActive: Boolean(payload.queryParams?.isActive) }),
          ...(payload.type === ObjectActions.FAVORITE_LOGBOOKS_DATA_LOADING && { favoritesOnly: true }),
        });

        return this.logbooksService.getLogbooks(httpParams).pipe(
          switchMap((response: IGetManyResponse<ILogbook>) => {
            return of(new ObjectActions.FavoriteLogbooksDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getOngoingLogbookData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ONGOING_LOGBOOKS_DATA_LOADING),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.logbooksService.getOngoingLogbooks().pipe(
          switchMap((response: IGetManyResponse<ILogbook>) => {
            return of(new ObjectActions.OngoingLogbooksDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getNetworkLogbookData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NETWORK_LOGBOOKS_DATA_LOADING),
      switchMap((payload: ObjectActions.NetworkLogbooksDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
          page: payload.queryParams?.page ?? 1,
          rowsPerPage: payload.queryParams?.rowsPerPage ?? 10,
          sort: payload.queryParams?.sort ?? { type: '-', column: 'id' },
          isActive: 1,
          ...(payload.queryParams?.search && { search: payload.queryParams.search }),
        });

        return this.logbooksService.getLogbookChildren(httpParams, 0).pipe(
          switchMap((response: IGetManyResponse<ILogbook>) => {
            return of(new ObjectActions.NetworkLogbooksDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getChildrenLogbookData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHILDREN_LOGBOOKS_DATA_LOADING),
      switchMap((payload: ObjectActions.ChildrenLogbooksDataLoading) => {
        if (payload.isGridView) {
          this.store.dispatch(new AppActions.ShowLoader());
        }

        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
          page: payload.queryParams?.page ?? 1,
          rowsPerPage: payload.queryParams?.rowsPerPage ?? 10,
          sort: payload.queryParams?.sort ?? { type: '-', column: 'id' },
          isActive: 1,
          ...(payload.queryParams?.search && { search: payload.queryParams.search }),
        });

        return this.logbooksService.getLogbookChildren(httpParams, payload.parentId).pipe(
          switchMap((response: IGetManyResponse<ILogbook>) => {
            return of(new ObjectActions.ChildrenLogbooksDataLoaded(response, payload.isGridView));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addLogbookToFavorites = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_LOGBOOK_TO_FAVORITES_LOADING),
      switchMap((payload: ObjectActions.AddLogbookToFavoritesLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        let userConfig!: IConfig | null;
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((state: User) => {
            userConfig = { ..._.cloneDeep(emptyUserMeta), ..._.cloneDeep(state.configuration) };
            if (payload.isAdd) {
              userConfig = {
                ...userConfig,
                favoriteLogbooks: _.uniq([...userConfig?.favoriteLogbooks, payload.payload]),
              };
            } else {
              userConfig = {
                ...userConfig,
                favoriteLogbooks: [...userConfig?.favoriteLogbooks].filter((item) => {
                  return item !== payload.payload;
                }),
              };
            }
          });

        return this.userService.editUserConfiguration(userConfig).pipe(
          switchMap((response: IResponse<{ meta: IConfig }>) => {
            return of(
              new UserActions.SetUserConfiguration(response.data.meta),
              new ObjectActions.FavoriteLogbooksDataLoading({ page: 1, rowsPerPage: 5000 }),
              new ObjectActions.AddLogbookToFavoritesLoaded(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookHomeDetailData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOK_HOME_DETAIL_DATA_LOADING),
      switchMap((payload: ObjectActions.LogbookHomeDetailDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.logbooksService
          .getFormSubmissionTableData(HomeEffects.getFormSubmissionsHttpParams(payload.params), payload.logbookId)
          .pipe(
            switchMap((response: ILogbookHomeDetail) => {
              return of(new ObjectActions.LogbookHomeDetailDataLoaded(response));
            }),
            catchError((errorRes) => {
              return of(
                new ObjectActions.FetchError(errorRes),
                new ObjectActions.RedirectToHome(),
                new AppActions.HideLoader(),
              );
            }),
          );
      }),
      catchError((errorRes) => {
        return of(
          new ObjectActions.FetchError(errorRes),
          new ObjectActions.RedirectToHome(),
          new AppActions.HideLoader(),
        );
      }),
    ),
  );

  cancelFormSubmission = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CANCEL_FORM_SUBMISSION),
      switchMap((payload: ObjectActions.CancelFormSubmission) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.logbooksService
          .cancelFormSubmission(payload.logbookId, payload.formSubmissionId, payload.issuerAndReason)
          .pipe(
            switchMap((response: IResponse<IFormSubmission>) => {
              return of(new ObjectActions.CancelFormSubmissionCompleted(response), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormSubmissionHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_FORM_SUBMISSION_HISTORY),
      switchMap((payload: ObjectActions.GetFormSubmissionHistory) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const params: HttpParams = new HttpParams().append('limit', 1000);

        return this.logbooksService.getFormSubmissionHistory(params, payload.formSubmissionId).pipe(
          switchMap((response: IGetManyResponse<IFormSubmissionUserAction>) => {
            return of(new ObjectActions.GetFormSubmissionHistoryCompleted(response.data));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormSubmission = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_FORM_SUBMISSION),
      switchMap((payload: ObjectActions.GetFormSubmission) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.logbooksService.getFormSubmission(payload.logbookId, payload.formSubmissionId, payload.body).pipe(
          switchMap((response: IResponse<IHomeFormSubmission>) => {
            return of(
              new ObjectActions.GetFormSubmissionCompleted(response.data),
              payload.insertUpdatingLog
                ? new MainActions.CreateManualLog(
                    EManualLogActionType.UPDATING,
                    EManualLogObjectContentModel.FORM_SUBMISSION,
                    payload.formSubmissionId,
                    undefined,
                    payload.body?.issuer,
                  )
                : new AppActions.EmptyAction(),
              payload.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LOGBOOK_USER_ACTION_DATA),
      switchMap((payload: ObjectActions.GetLogbookUserActionData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.logbooksService.getLogbooksVersionHistoryData(payload.logbookId).pipe(
          switchMap((response: IGetManyResponse<IUserAction>) => {
            return of(new ObjectActions.GetLogbookUserActionDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_FORM_USER_ACTION_DATA),
      switchMap((payload: ObjectActions.GetFormUserActionData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.logbooksService.getFormHistoryData(payload.formId).pipe(
          switchMap((response: IGetManyResponse<IUserAction>) => {
            return of(new ObjectActions.GetFormUserActionDataCompleted(response, payload.formId));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormSubmissionsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_FORM_SUBMISSIONS),
      switchMap((payload: ObjectActions.GetFormSubmissions) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.logbooksService
          .getFormSubmissions(HomeEffects.getFormSubmissionsHttpParams(payload.params), payload.logbookId)
          .pipe(
            switchMap((response: IGetManyResponse<IFormSubmission>) => {
              return of(new ObjectActions.GetFormSubmissionsCompleted(response));
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbook = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LOGBOOK),
      switchMap((payload: ObjectActions.GetLogbook) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const params: HttpParams = new HttpParams().append('logbook_ids', payload.logbookId);

        return this.logbooksService.getLogbook(params).pipe(
          switchMap((response: IGetManyResponse<ILogbook>) => {
            return of(new ObjectActions.GetLogbookCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  createFormSubmission = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CREATE_FORM_SUBMISSION),
      switchMap((payload: ObjectActions.CreateFormSubmission) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.logbooksService
          .createFormSubmission(
            payload.logbookId,
            payload.formId,
            payload.form,
            payload.criticalityStatus,
            payload.issuerAndReason,
          )
          .pipe(
            switchMap((response: IBaseOneResponse<IFormSubmission>) => {
              return of(new ObjectActions.CreateFormSubmissionCompleted(response));
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  submitFormSubmission = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SUBMIT_FORM_SUBMISSION),
      switchMap((payload: ObjectActions.SubmitFormSubmission) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.logbooksService
          .submitFormSubmission(
            payload.formSubmissionId,
            payload.form,
            payload.criticalityStatus,
            payload.issuerAndReason,
          )
          .pipe(
            switchMap((response: IBaseOneResponse<IFormSubmission>) => {
              return of(new ObjectActions.SubmitFormSubmissionCompleted(response), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  saveFormSubmission = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SAVE_FORM_SUBMISSION),
      switchMap((payload: ObjectActions.SaveFormSubmission) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.logbooksService
          .saveFormSubmission(
            payload.logbookId,
            payload.formSubmissionId,
            payload.form,
            payload.criticalityStatus,
            payload.issuerAndReason,
            payload.isCheckOut,
            payload.bulkCheckout,
          )
          .pipe(
            switchMap((response: IBaseOneResponse<IFormSubmission>) => {
              return of(new ObjectActions.SaveFormSubmissionCompleted(response), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormVersion = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_FORM_VERSION),
      switchMap((payload: ObjectActions.GetFormVersion) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.formsService.getFormVersion(payload.formId, payload.formVersionId).pipe(
          switchMap((response: IBaseOneResponse<IFormVersion>) => {
            if (payload.hideLoader) {
              return of(new ObjectActions.GetFormVersionCompleted(response), new AppActions.HideLoader());
            }

            return of(new ObjectActions.GetFormVersionCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  homeSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HOME_SEARCH),
      switchMap((payload: ObjectActions.HomeSearch) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.homeService.homeSearch(payload.searchKey).pipe(
          switchMap((response: IHomeSearchResult[]) => {
            return of(new ObjectActions.HomeSearchLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_CURRENT_USER),
      switchMap((objectData: ObjectActions.GetCurrentUser) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.homeService.getCurrentUser(objectData.action).pipe(
          switchMap((response: IGetCurrentUserResponse) => {
            return of(new ObjectActions.SetHomeUserInfo(response.data));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  private static getFormSubmissionsHttpParams(params: IFormSubmissionParams): HttpParams {
    return new HttpParams().appendAll({
      page: params.page ?? 1,
      limit: params.limit ?? 10,
      ...(params.ongoingsOnly && { ongoings_only: params.ongoingsOnly }),
      ...(params.statusFilter && { status_filter: params.statusFilter }),
      ...(params.logbookId && { logbook_ids: params.logbookId }),
    });
  }
}
