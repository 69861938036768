import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../logbook.reducer';
import { TranslateService } from '@ngx-translate/core';
import {
  IBaseOneResponse,
  IBulkResponseData,
  IBulkResponseRecord,
  IGetManyResponse,
  IGetOneResponse,
} from '../../shared/model/interface/crud-response-interface.model';
import { Observable, forkJoin, map, takeUntil, Subject } from 'rxjs';
import {
  EFormActivityType,
  IForm,
  IFormActionPayload,
  IFormExcel,
  IFormExcelContent,
  IFormExcelData,
  IFormVersion,
  IFormVersionHistory,
  IFormVersionSettings,
  IGetFormVersionHistoryParams,
  IGetFormVersionsParams,
  IPassedIssueDateForms,
  ISpecificFormData,
} from './forms.model';
import { WorkflowsService } from '../settings/workflows/workflows.service';
import * as _ from 'lodash';
import { IIssuerAndReason } from '../../shared/component/before-action-preparer/before-action-preparer.model';
import { IIssuer } from '../../shared/component/issuer/issuer.model';
import { ICloneFormPayload, IFormCreate, IFormForm, IPreviousFormVersion } from '../../view/settings/form/form.model';
import { ServiceUtilities } from '../../shared/helper/service-utilities';
import { IWorkflow } from '../settings/workflows/workflows.model';
import * as moment from 'moment-timezone';
import {
  CellTypes,
  ExcelHelperService,
  ICreateExcel,
  IExcelColumnDefinition,
  IExcelColumnKeys,
} from '../../shared/service/excel/excel-helper.service';
import * as FormsActions from '../forms/forms.actions';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import { ISelect } from '../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { HelperService } from '../../shared/service/helper.service';
import * as AppActions from '../app/actions';
import { IFormMasterDataDetail, IFormMasterDataFields } from '../settings/form-master-data/form-master-data.model';
import { excelDateFormat, excelTimeFormat, IExcelDateFormat } from '../../shared/model/enum/excel-date-format';
import { ComponentUtilities } from '../../shared/helper/component-utilities';
import { IMasterDataOption } from '../../view/settings/logbook/logbook.model';
import { EFieldType, ICompareField } from '../../shared/standalone/compare-changes/compare-changes.model';
import { IBulkActionSingleResponse } from '../../shared/service/bulk-action/bulk-action.model';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  private readonly FORMS = {
    FORMS_URL: `${this.baseUrl}/forms/`,
    FORMS_BULK_URL: `${this.baseUrl}/forms/bulk/`,
    WORKFLOWS_URL: `${this.baseUrl}/workflows/`,
    NUMBER_URL: `${this.baseUrl}/number-generator/`,
    FORM_VERSION_URL: `${this.baseUrl}/form-versions/`,
    PASSED_ISSUE_DATE_URL: `${this.baseUrl}/form-versions/check-passed-issue-date/`,
    FORM_VERSION_HISTORY: `${this.baseUrl}/user-actions/form-versions`,
    FORM_HISTORY: `${this.baseUrl}/user-actions/forms/`,
    FLAG_TO_BE_ARCHIVE_URL: `${this.baseUrl}/forms/to_be_archived`,
    ARCHIVE_URL: `${this.baseUrl}/forms/archive`,
    BULK: {
      SUBMIT: `${this.baseUrl}/form-versions/bulk/send-to-review`,
      ACTIVATE: `${this.baseUrl}/form-versions/bulk/activate`,
    },
  };

  private timezone: string = 'utc';
  private dateFormat$!: string;
  private timeFormat$!: string;
  private dateTimeFormat!: string;
  private readonly commonExcelAttributes: Partial<IExcelColumnDefinition> = {
    type: ValueType.String,
    isRequired: true,
    dataValidation: {
      type: CellTypes.CUSTOM,
      allowBlank: false,
      showErrorMessage: true,
      formulae: [],
      errorStyle: 'Error',
      showInputMessage: true,
    },
  };
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly store: Store<logbookAppReducer.LogbookAppState>,
    private readonly translate: TranslateService,
    private readonly workflowService: WorkflowsService,
    private excelHelper: ExcelHelperService,
    private helperService: HelperService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale as keyof IExcelDateFormat];
            this.timeFormat$ = excelTimeFormat[state.locale as keyof typeof excelTimeFormat];
            this.dateTimeFormat = `${this.dateFormat$} ${this.timeFormat$}`;
          }

          this.timezone = state.timezone ?? 'utc';

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getForms(params: HttpParams, issuer?: IIssuer): Observable<IGetManyResponse<IForm>> {
    return this.http.get<IGetManyResponse<IForm>>(this.FORMS.FORMS_URL, {
      params,
      ...(issuer && { headers: ServiceUtilities.makeBasicAuthHeaderFromIssuer(issuer) }),
    });
  }

  public getAllFormVersions(params: HttpParams, issuer?: IIssuer): Observable<IGetManyResponse<IFormVersionSettings>> {
    return this.http.get<IGetManyResponse<IFormVersionSettings>>(this.FORMS.FORM_VERSION_URL, {
      params,
      ...(issuer && { headers: ServiceUtilities.makeBasicAuthHeaderFromIssuer(issuer) }),
    });
  }

  public getSpecificFormData(
    formId: number | null = null,
    formVersionId: number | null = null,
    previousVersionId: number | null = null,
    issuer?: IIssuer,
    isIncludeDisabled?: boolean,
  ): Observable<ISpecificFormData> {
    let params: HttpParams = new HttpParams().append('limit', 5000).append('just_workflow_data', true);

    if (isIncludeDisabled) {
      params = params.append('include_disabled', true);
    }

    const observables: any = [
      this.workflowService.getWorkflows(params, issuer),
      ...(formId && formVersionId ? [this.getFormVersion(formId, formVersionId)] : []),
      ...(formId && previousVersionId ? [this.getFormVersion(formId, previousVersionId)] : []),
    ];

    return forkJoin(observables).pipe(
      map((responseList) => {
        return {
          workflows: _.get(responseList, '0.data', null),
          formVersion: _.get(responseList, '1.data', []),
          previousVersion: _.get(responseList, '2.data', null),
        };
      }),
    );
  }

  public getFormVersions(formId: number | null, params: HttpParams): Observable<IGetManyResponse<IFormVersion>> {
    return this.http.get<IGetManyResponse<IFormVersion>>(`${this.FORMS.FORM_VERSION_URL}${formId}`, {
      params,
    });
  }

  public checkPassedIssueDate(params: HttpParams): Observable<IGetManyResponse<IPassedIssueDateForms>> {
    return this.http.get<IGetManyResponse<IPassedIssueDateForms>>(`${this.FORMS.PASSED_ISSUE_DATE_URL}`, { params });
  }

  public getFormVersion(
    formId: number | null,
    formVersionId: number | null,
  ): Observable<IBaseOneResponse<IFormVersion>> {
    return this.http.get<IBaseOneResponse<IFormVersion>>(`${this.FORMS.FORM_VERSION_URL}${formId}/${formVersionId}`);
  }

  public getFormVersionHistory(
    params: HttpParams,
    formID?: number,
    getTemplateHistory: boolean = false
  ): Observable<IGetManyResponse<IFormVersionHistory>> {

    if (getTemplateHistory) {
      return this.http.get<IGetManyResponse<IFormVersionHistory>>(`${this.FORMS.FORM_HISTORY}${formID}`);
    }

    return this.http.get<IGetManyResponse<IFormVersionHistory>>(`${this.FORMS.FORM_VERSION_HISTORY}`, {
      params,
    });
  }

  public activateForm(
    formId: number | null,
    formVersionId: number | null,
    issuer: IIssuer | null,
  ): Observable<IBaseOneResponse<IForm>> {
    return this.http.post<IBaseOneResponse<IForm>>(
      `${this.FORMS.FORM_VERSION_URL}activate/${formId}/${formVersionId}`,
      {
        issuer,
        payload: {},
      },
    );
  }

  public deleteForm(
    formId: number | null,
    formVersionId: number | null,
    issuerAndReason: IIssuerAndReason,
  ): Observable<IBaseOneResponse<IFormVersion>> {
    return this.http.delete<IBaseOneResponse<IFormVersion>>(
      `${this.FORMS.FORM_VERSION_URL}${formId}/${formVersionId}`,
      {
        body: {
          issuer: issuerAndReason.issuer,
          payload: { reason: issuerAndReason.reason },
        },
      },
    );
  }

  public submitForm(
    formId: number | null,
    formVersionId: number | null,
    issuer: IIssuer | null,
  ): Observable<IBaseOneResponse<IFormVersion>> {
    return this.http.post<IBaseOneResponse<IFormVersion>>(
      `${this.FORMS.FORM_VERSION_URL}send-to-review/${formId}/${formVersionId}`,
      {
        issuer,
        payload: {},
      },
    );
  }

  public flagToBeArchiveForm(
    id: number | null,
    issuerAndReason: IIssuerAndReason,
  ): Observable<IBaseOneResponse<IForm>> {
    return this.http.put<IBaseOneResponse<IForm>>(`${this.FORMS.FLAG_TO_BE_ARCHIVE_URL}/${id}`, {
      issuer: issuerAndReason.issuer,
      payload: { reason: issuerAndReason.reason },
    });
  }

  public archiveForm(id: number | null, issuerAndReason: IIssuerAndReason): Observable<IBaseOneResponse<IForm>> {
    return this.http.put<IBaseOneResponse<IForm>>(`${this.FORMS.ARCHIVE_URL}/${id}`, {
      issuer: issuerAndReason.issuer,
      payload: { reason: issuerAndReason.reason },
    });
  }

  public addForm(form: IFormActionPayload, issuer: IIssuer | null): Observable<IBaseOneResponse<IForm>> {
    return this.http.post<IBaseOneResponse<IForm>>(this.FORMS.FORMS_URL, {
      issuer,
      payload: form,
    });
  }

  public addFormVersion(
    formId: number | null,
    formVersion: IFormVersion,
    issuer: IIssuer | null,
  ): Observable<IBaseOneResponse<IFormVersion>> {
    return this.http.post<IBaseOneResponse<IFormVersion>>(`${this.FORMS.FORM_VERSION_URL}${formId}`, {
      issuer,
      payload: formVersion,
    });
  }

  public editForm(
    form: IForm,
    formId: number | null,
    formVersionId: number | null,
    issuer: IIssuer | null,
  ): Observable<IGetManyResponse<IForm>> {
    return this.http.put<IGetManyResponse<IForm>>(`${this.FORMS.FORM_VERSION_URL}${formId}/${formVersionId}`, {
      issuer,
      payload: form,
    });
  }

  public cloneForm(
    form: ICloneFormPayload,
    formId: number,
    issuer: IIssuer | null,
  ): Observable<IGetManyResponse<IForm>> {
    return this.http.post<IGetManyResponse<IForm>>(`${this.FORMS.FORM_VERSION_URL}${formId}`, {
      issuer,
      payload: form,
    });
  }

  public async downloadExcel(
    withData: boolean,
    activeMasterDataData: IFormMasterDataDetail,
    workflows: IWorkflow[],
    withErrorColumn: boolean = false,
    data?: IFormExcelData[],
  ): Promise<void> {
    const sheetTitle: string = this.translate.instant('pageTitles.forms');
    const excelName: string = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;
    let excelData: IFormExcelData[] = [];

    if (withData && data) {
      excelData = _.cloneDeep(data);

      excelData.forEach((form: IFormExcelData) => {
        this.formatFormData(form, {
          activeMasterDataData,
          workflows,
          activityType: this.helperService.getFormActivityTypes(),
          yesNo: this.helperService.getYesNoDropdownExcel(),
          yesDropdown: this.helperService.getYesDropdownExcel(),
        });
      });
    }

    const excelOptions: ICreateExcel = this.getExcelColumns(
      {
        activeMasterDataData,
        workflows,
        activityType: this.helperService.getFormActivityTypes(),
        yesNo: this.helperService.getYesNoDropdownExcel(),
        yesDropdown: this.helperService.getYesDropdownExcel(),
      },
      withErrorColumn,
    );

    if (withData) {
      excelOptions.data = excelData;
    }

    this.excelHelper
      .createExcel(
        sheetTitle,
        excelName,
        excelOptions,
        withData,
        this.timezone,
        this.dateFormat$,
        this.timeFormat$,
        false,
        true,
        true,
        1001,
        true,
        activeMasterDataData.id,
      )
      .then(
        () => {
          if (withData) {
            this.store.dispatch(new FormsActions.DownloadErrorExcelCompleted());
          } else {
            this.store.dispatch(new FormsActions.DownloadExcelCompleted());
          }

          this.store.dispatch(new AppActions.HideLoader());
        },
        () => {
          this.store.dispatch(new FormsActions.FetchError({}));
        },
      );
  }

  private getExcelColumns(content: IFormExcelContent, withErrorColumn: boolean): ICreateExcel {
    const dateFormula: string = this.excelHelper.getExcelDateFormula(this.dateFormat$);
    const excelColumns: ICreateExcel = {
      columns: [
        {
          ...(_.cloneDeep(this.commonExcelAttributes) as IExcelColumnDefinition),
          header: this.translate.instant('excel.column.formName'),
          key: 'name',
          width: 35,
        },
        {
          ...(_.cloneDeep(this.commonExcelAttributes) as IExcelColumnDefinition),
          isRequired: false,
          header: this.translate.instant('general.datatable.headers.formLegacyId'),
          key: 'legacyId',
          width: 35,
        },
        {
          header: this.translate.instant('general.datatable.headers.workflowFormApproval'),
          key: 'formTemplateWorkflow',
          width: 35,
          type: ValueType.String,
          isRequired: true,
          dropdownOptions: {
            data: content.workflows,
            prop: 'formTemplateWorkflowDropdown.id',
            dataProperty: 'formTemplateWorkflowDropdown.name',
            dataId: 'formTemplateWorkflowDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('general.datatable.headers.workflowEntries'),
          key: 'formEntryWorkflow',
          width: 35,
          type: ValueType.String,
          isRequired: true,
          dropdownOptions: {
            data: content.workflows,
            prop: 'formEntryWorkflowDropdown.id',
            dataProperty: 'formEntryWorkflowDropdown.name',
            dataId: 'formEntryWorkflowDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('excel.column.issuedDate'),
          key: 'issuedDate',
          width: 35,
          type: ValueType.Date,
          style: { numFmt: '@' },
          isRequired: false,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [dateFormula],
            errorStyle: 'Error',
            showInputMessage: true,
            prompt: this.translate.instant('excel.form.issuedDate.prompt', {
              property: this.translate.instant('excel.column.issuedDate'),
              date: moment().format(this.dateFormat$),
            }),
            promptTitle: this.translate.instant('excel.form.issuedDate.promptTitle'),
          },
        },
        {
          header: this.translate.instant('general.datatable.headers.activityType'),
          key: 'activityType',
          width: 35,
          type: ValueType.String,
          isRequired: true,
          dropdownOptions: {
            data: content.activityType,
            prop: 'activityTypeDropdown.id',
            dataProperty: 'activityTypeDropdown.name',
            dataId: 'activityTypeDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('general.datatable.headers.checkInMechanism'),
          key: 'useCheckIn',
          width: 20,
          type: ValueType.String,
          isRequired: true,
          dropdownOptions: {
            data: content.yesNo,
            prop: 'useCheckInDropdown.id',
            dataProperty: 'useCheckInDropdown.name',
            dataId: 'useCheckInDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
      ],
    };

    content?.activeMasterDataData?.fields?.forEach((item: IFormMasterDataFields) => {
      switch (item.meta['type']) {
        case 'Checkbox':
          excelColumns.columns.push({
            header: item.name,
            key: item.meta['fieldId'],
            width: 35,
            type: ValueType.String,
            isRequired: item.meta['mandatory'],
            dropdownOptions: {
              data: content.yesDropdown,
              prop: `${item.name}Dropdown.id`,
              dataProperty: `${item.name}Dropdown.name`,
              dataId: `${item.name}Dropdown.id`,
            },
            dataValidation: {
              type: CellTypes.LIST,
            },
          });
          break;
        case 'CheckboxMulti':
          excelColumns.columns.push({
            header: item.name,
            key: item.meta['fieldId'],
            width: 35,
            type: ValueType.String,
            isRequired: item.meta['mandatory'],
            dataValidation: {
              type: CellTypes.CUSTOM,
              allowBlank: false,
              showErrorMessage: true,
              formulae: [],
              errorStyle: 'Error',
              showInputMessage: true,
            },
          });
          break;
        case 'Datetime':
          excelColumns.columns.push({
            header: item.name,
            key: item.meta['fieldId'],
            width: 35,
            type: ValueType.Date,
            style: { numFmt: '@' },
            isRequired: item.meta['mandatory'],
            isDateTimeFormat: true,
            dataValidation: {
              type: CellTypes.CUSTOM,
              allowBlank: false,
              showErrorMessage: true,
              formulae: [null],
              errorStyle: 'Error',
              showInputMessage: true,
              prompt: this.translate.instant('excel.date.prompt', {
                property: item.name,
                date: moment().format(this.dateTimeFormat),
              }),
              promptTitle: this.translate.instant('excel.date.promptTitle', { fieldName: item.name }),
            },
          });
          break;
        case 'Dropdown':
          excelColumns.columns.push({
            header: item.name,
            key: item.meta['fieldId'],
            width: 35,
            type: ValueType.String,
            isRequired: item.meta['mandatory'],
            dropdownOptions: {
              data: item.meta['options'].map((option: any) => {
                return {
                  id: option['id'],
                  name: option['label'],
                };
              }),
              prop: `${item.name}Dropdown.id`,
              dataProperty: `${item.name}Dropdown.name`,
              dataId: `${item.name}Dropdown.id`,
            },
            dataValidation: {
              type: CellTypes.CUSTOM,
              allowBlank: false,
              showErrorMessage: true,
              formulae: [],
              errorStyle: 'Error',
              showInputMessage: true,
            },
          });
          break;
        case 'Text':
          excelColumns.columns.push({
            header: item.name,
            key: item.meta['fieldId'],
            width: 35,
            type: ValueType.String,
            isRequired: item.meta['mandatory'],
            dataValidation: {
              type: CellTypes.CUSTOM,
              allowBlank: false,
              showErrorMessage: true,
              formulae: [],
              errorStyle: 'Error',
              showInputMessage: true,
            },
          });
          break;
        case 'TextArea':
          excelColumns.columns.push({
            header: item.name,
            key: item.meta['fieldId'],
            width: 35,
            type: ValueType.String,
            isRequired: item.meta['mandatory'],
            dataValidation: {
              type: CellTypes.CUSTOM,
              allowBlank: false,
              showErrorMessage: true,
              formulae: [],
              errorStyle: 'Error',
              showInputMessage: true,
            },
          });
          break;
        case 'DropdownMulti':
          excelColumns.columns.push({
            header: item.name,
            key: item.meta['fieldId'],
            width: 35,
            type: ValueType.String,
            isRequired: item.meta['mandatory'],
            dataValidation: {
              type: CellTypes.CUSTOM,
              allowBlank: false,
              showErrorMessage: true,
              formulae: [],
              errorStyle: 'Error',
              showInputMessage: true,
            },
          });
          break;
        default:
      }
    });

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public async getFormsFromExcel(file: File, activeMasterDataData: IFormMasterDataDetail): Promise<IFormExcel | null> {
    const workbook: Workbook = await this.excelHelper.getExcelWorkBookFromFile(file);
    const formSheet: Worksheet = workbook.getWorksheet(this.translate.instant('pageTitles.forms'));
    const formTemplateApprovalWorkflowWorkSheet: Worksheet = workbook.getWorksheet('formTemplateWorkflowDataSheet');
    const formEntryApprovalWorkflowWorkSheet: Worksheet = workbook.getWorksheet('formEntryWorkflowDataSheet');
    const activityTypeWorkSheet: Worksheet = workbook.getWorksheet('activityTypeDataSheet');

    if (!formSheet) {
      return null;
    }

    const formTemplateApprovalWorkflowData: ISelect<number, string>[] = this.getGenericSheetData<number, string>(
      formTemplateApprovalWorkflowWorkSheet,
    );
    const formEntryApprovalWorkflowData: ISelect<number, string>[] = this.getGenericSheetData<number, string>(
      formEntryApprovalWorkflowWorkSheet,
    );
    const activityTypeData: ISelect<number, string>[] = this.getGenericSheetData<number, string>(activityTypeWorkSheet);
    const { columns } = this.getExcelColumns(
      {
        activeMasterDataData,
        workflows: null,
        yesNo: null,
        activityType: null,
        yesDropdown: null,
      },
      false,
    );
    const columnKeys: IExcelColumnKeys = this.excelHelper.getSheetColumnKeys(columns);

    return {
      formTemplateApprovalWorkflowData,
      formEntryApprovalWorkflowData,
      activityTypeData,
      fieldVersion: !_.isNil(workbook.description) ? Number(workbook.description) : null,
      formData: this.excelHelper.getExcelRowsFromWorkSheet<IFormExcelData>(formSheet, columnKeys, {
        dateFormat: this.dateFormat$,
        timeFormat: this.timeFormat$,
        timezone: this.timezone,
      }),
    };
  }

  private getGenericSheetData<IdType, NameType>(sheet: Worksheet | null): ISelect<IdType, NameType>[] {
    if (!sheet) {
      return [];
    }

    const genericColumns = {
      id: { key: 'id', type: ValueType.String, dataValidationType: CellTypes.CUSTOM },
      name: { key: 'name', type: ValueType.String, dataValidationType: CellTypes.CUSTOM },
    };

    return this.excelHelper.getExcelRowsFromWorkSheet<ISelect<IdType, NameType>>(sheet, genericColumns);
  }

  public uploadExcel(
    formExcelData: IFormCreate[],
    issuerAndReason: IIssuerAndReason | null,
    scopeId?: number | null,
  ): Observable<IBulkResponseData> {
    return this.http.post<IBulkResponseData>(this.FORMS.FORMS_BULK_URL, {
      scopeId,
      issuer: issuerAndReason?.issuer,
      payload: formExcelData,
      reason: issuerAndReason?.reason,
    });
  }

  public formatFormData(form: IFormExcelData, content: Partial<IFormExcelContent>): void {
    form.issuedDate = form.issuedDate === null ? '' : moment(form.issuedDate).utc().format(this.dateFormat$);
    content.activeMasterDataData?.fields?.forEach((item: IFormMasterDataFields) => {
      switch (item.meta['type']) {
        case 'Checkbox':
          const value: number | undefined = _.find(form['fieldMeta'], {
            fieldId: item['meta']['fieldId'],
          })?.inputModel;
          const contentValue: number | string = value ? 1 : '0';
          form[`${item['name']}Dropdown`] = {
            id: _.isNil(value) ? null : _.find(content.yesNo, { id: contentValue })?.id,
            name: _.isNil(value)
              ? null
              : ComponentUtilities.findOneOptionForSelect(content.yesNo ?? [], contentValue)?.[0]?.name,
          };
          break;
        case 'Dropdown':
          const id: number | undefined = _.find(form.fieldMeta, { fieldId: item['meta']['fieldId'] })?.inputModel[0]
            ?.id;
          form[`${item['name']}Dropdown`] = {
            id,
            name: ComponentUtilities.findOneOptionForSelect(
              (item['meta']['options'] as any[]) ?? [],
              id ?? 0,
              'id',
            )?.[0]?.label,
          };
          break;
        case 'CheckboxMulti':
        case 'DropdownMulti':
          const multiInputModel: any = _.find(form.fieldMeta, {
            fieldId: item['meta']['fieldId'],
          })?.inputModel;

          if (!Array.isArray(multiInputModel)) {
            return;
          }
          form[item['meta']['fieldId']] = multiInputModel
            .map((innerItem: IMasterDataOption) => {
              return innerItem.label;
            })
            .join(', ');
          break;
        case 'Datetime':
          const inputModel: any | undefined = _.find(form.fieldMeta, {
            fieldId: item['meta']['fieldId'],
          })?.inputModel;
          form[item['meta']['fieldId']] = inputModel
            ? inputModel.startDate !== 'Invalid date'
              ? moment(inputModel['startDate']).tz(this.timezone).format(`${this.dateFormat$} ${this.timeFormat$}`)
              : 'Invalid date'
            : '';
          break;
        default:
          form[item['meta']['fieldId']] = _.find(form.fieldMeta, {
            fieldId: item['meta']['fieldId'],
          })?.inputModel;
      }
    });
    this.setForeignKeyDropdowns(form, content);
  }

  private setForeignKeyDropdowns(form: IFormExcelData, content: Partial<IFormExcelContent>) {
    form.formTemplateWorkflowDropdown = {
      id: Number(form.workflowId),
      name: ComponentUtilities.findOneOptionForSelect(content.workflows ?? [], form.workflowId ?? 0)?.[0]?.name,
    };
    form.formEntryWorkflowDropdown = {
      id: Number(form.workflowEntriesId),
      name: ComponentUtilities.findOneOptionForSelect(content.workflows ?? [], form.workflowEntriesId ?? 0)?.[0]?.name,
    };
    form.activityTypeDropdown = {
      id: form.activityType,
      name: ComponentUtilities.findOneOptionForSelect(content.activityType ?? [], form.activityType, 'id')?.[0]?.name,
    };

    let useCheckInValue: number | string | null = null;

    if (form.useCheckIn) {
      useCheckInValue = 1;
    } else if (!_.isNil(form.useCheckIn)) {
      useCheckInValue = '0';
    }

    form.useCheckInDropdown = {
      id: form.useCheckIn,
      name: ComponentUtilities.findOneOptionForSelect(content.yesNo ?? [], useCheckInValue, 'id')?.[0]?.name,
    };
  }

  public setCompareFields(
    formForm: IFormForm,
    previousVersionData: IPreviousFormVersion,
    workflowData: IWorkflow[],
  ): ICompareField[] {
    const yesNoDropdown: ISelect<number, string>[] = this.helperService.getYesNoDropdown();

    return [
      {
        type: EFieldType.INPUT,
        label: 'settings.forms.modal.formName.label',
        required: true,
        highlighted: previousVersionData.formName !== formForm.formName.value,
        values: {
          before: previousVersionData.formName,
          after: formForm.formName.value,
        },
      },
      {
        type: EFieldType.INPUT,
        label: 'settings.forms.modal.formId.label',
        required: true,
        highlighted: previousVersionData.formId !== formForm.formId.value,
        values: {
          before: previousVersionData.formId,
          after: formForm.formId.value,
        },
      },
      {
        type: EFieldType.SELECT,
        label: 'settings.forms.modal.formApprovalWorkflow.label',
        required: true,
        highlighted:
          previousVersionData.formApprovalWorkFlow[0]?.id !==
          (formForm.formApprovalWorkFlow.value as ISelect<number, string>[])[0]?.id,
        values: {
          before: previousVersionData.formApprovalWorkFlow,
          after: formForm.formApprovalWorkFlow.value,
        },
        button: {
          buttonIconCls: 'fas fa-eye',
          disabled: (item: ISelect<number, string>[] | null) => {
            return _.isNil(item) || item[0].id === 1;
          },
        },
        data: workflowData,
      },
      {
        type: EFieldType.SELECT,
        label: 'settings.forms.modal.entryReviewWorkflow.label',
        required: true,
        highlighted:
          previousVersionData.entryReviewWorkFlow[0]?.id !==
          (formForm.entryReviewWorkFlow.value as ISelect<number, string>[])[0]?.id,
        values: {
          before: previousVersionData.entryReviewWorkFlow,
          after: formForm.entryReviewWorkFlow.value,
        },
        button: {
          buttonIconCls: 'fas fa-eye',
          disabled: (item: ISelect<number, string>[] | null) => {
            return _.isNil(item) || item[0].id === 1;
          },
        },
        data: workflowData,
      },
      {
        type: EFieldType.INPUT,
        label: 'settings.forms.modal.versionNumber.label',
        required: true,
        highlighted: previousVersionData.versionNumber !== formForm.versionNumber.value,
        values: {
          before: previousVersionData.versionNumber,
          after: formForm.versionNumber.value,
        },
      },
      {
        type: EFieldType.INPUT,
        label: 'settings.forms.modal.legacyId.label',
        required: false,
        highlighted: previousVersionData.legacyId !== formForm.legacyId.value,
        values: {
          before: previousVersionData.legacyId,
          after: formForm.legacyId.value,
        },
      },
      {
        type: EFieldType.SELECT,
        label: 'settings.forms.modal.activityType.label',
        required: true,
        highlighted:
          previousVersionData.activityType[0]?.id !==
          (formForm.activityType.value as ISelect<EFormActivityType, string>[])[0].id,
        values: {
          before: previousVersionData.activityType,
          after: formForm.activityType.value,
        },
        data: workflowData,
      },
      {
        type: EFieldType.SELECT,
        label: 'settings.forms.modal.checkInMechanism.label',
        required: true,
        highlighted:
          previousVersionData.checkInMechanism[0]?.id !==
          (formForm.useCheckIn.value as ISelect<number, string>[])[0]?.id,
        values: {
          before: previousVersionData.checkInMechanism,
          after: formForm.useCheckIn.value,
        },
        data: yesNoDropdown,
      },
      {
        type: EFieldType.DATE,
        label: 'settings.forms.modal.issuedDate.label',
        required: false,
        highlighted: !_.isEqual(formForm.issuedDate.value, previousVersionData.issuedDate),
        values: {
          before: previousVersionData.issuedDate,
          after: formForm.issuedDate.value,
        },
        data: yesNoDropdown,
      },
    ];
  }

  public bulkSubmitForms(
    formVersionIds: number[],
    issuer: IIssuer | null,
  ): Observable<IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>> {
    return this.http.post<IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>>(
      `${this.FORMS.BULK.SUBMIT}`,
      {
        issuer,
        payload: { formVersionIds },
      },
    );
  }

  public bulkActivateForms(
    formVersionIds: number[],
    issuer: IIssuer | null,
  ): Observable<IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>> {
    return this.http.post<IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>>(
      `${this.FORMS.BULK.ACTIVATE}`,
      {
        issuer,
        payload: { formVersionIds },
      },
    );
  }
}
