import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ILogbookVersion,
  ILogbookTasksData,
  IFieldSetData,
  IFormVersionLite,
} from '../../../../store/my-tasks/logbook-tasks/logbook-tasks.model';
import {
  IBulkResponseRecord,
  IGetManyResponse,
  IGetOneResponse,
} from '../../../model/interface/crud-response-interface.model';
import { ILogbookTasksHistory } from '../../../../view/my-tasks/logbook-tasks/logbook-tasks.component.model';
import { TranslateService } from '@ngx-translate/core';
import { ITableHeader } from '../../../../../constants.model';
import * as _ from 'lodash';
import { IIssuer } from '../../../component/issuer/issuer.model';
import { IReview, IReviewResults } from '../../../../store/my-tasks/my-tasks.model';

@Injectable({
  providedIn: 'root',
})
export class LogbookTasksService {
  private readonly FIELDSET_ITEMS = '/field-version-items/';
  private readonly LOGBOOKS = {
    BASE_URL: '/logbooks/',
    REVIEW: 'review/',
    SEND_TO_REVIEW: 'send-to-review/',
    ACTIVATE: 'activate/',
    USER_ACTIONS: '/user-actions/logbooks/',
  };
  private readonly LOGBOOK_VERSIONS = {
    BASE_URL: '/logbook-versions/',
    ACTIVE: 'active/',
    ACTIVES: '/form-versions/actives',
    REVIEW: 'review/',
    SEND_TO_REVIEW: 'send-to-review/',
    ACTIVATE: 'activate/',
    LOGBOOK_VERSION_USER_ACTIONS: '/user-actions/grouped-logbook-versions/',
    USER_ACTIONS_FOR_ONE_VERSION: '/user-actions/logbook-versions',
  };
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getReviewData(
    params: Record<string, string | number>,
    reviewType: string = 'logbook_versions',
  ): Observable<IGetManyResponse<ILogbookTasksData>> {
    let url = `${this.baseUrl}${this.LOGBOOKS.BASE_URL}${this.LOGBOOKS.REVIEW}`;
    if (reviewType === 'logbook_versions') {
      url = `${this.baseUrl}${this.LOGBOOK_VERSIONS.BASE_URL}${this.LOGBOOK_VERSIONS.REVIEW}`;
    }
    return this.http.post<IGetManyResponse<ILogbookTasksData>>(
      url,
      { ...params },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public reviewEntries(
    reviewPayload: IReview,
    issuer: IIssuer | null,
    reviewType: string = 'logbook_versions',
  ): Observable<IGetOneResponse<IReviewResults[]>> {
    let url = `${this.baseUrl}${this.LOGBOOKS.BASE_URL}${this.LOGBOOKS.REVIEW}`;
    if (reviewType === 'logbook_versions') {
      url = `${this.baseUrl}${this.LOGBOOK_VERSIONS.BASE_URL}${this.LOGBOOK_VERSIONS.REVIEW}`;
    }
    return this.http.post<IGetOneResponse<IReviewResults[]>>(url, {
      issuer,
      payload: reviewPayload,
    });
  }

  public getLogbookTasksHistory(
    id: number,
    isActive: boolean,
    reviewType: string = 'logbook_versions',
  ): Observable<IGetManyResponse<ILogbookTasksHistory>> {
    const urlId: number | string = reviewType === 'logbook_versions' && isActive ? '' : id;
    const url = `${this.baseUrl}${
      reviewType === 'logbook_versions'
        ? isActive
          ? this.LOGBOOK_VERSIONS.USER_ACTIONS_FOR_ONE_VERSION
          : this.LOGBOOK_VERSIONS.LOGBOOK_VERSION_USER_ACTIONS
        : this.LOGBOOKS.USER_ACTIONS
    }${urlId}`;
    const params: HttpParams = new HttpParams().append('versionIds', id);

    if (urlId === '') {
      return this.http.get<IGetManyResponse<ILogbookTasksHistory>>(url, { params });
    }

    return this.http.get<IGetManyResponse<ILogbookTasksHistory>>(url);
  }

  public getLogbookTasksHistoryForOneVersion(
    id: number,
    reviewType: string = 'logbook_versions',
  ): Observable<IGetManyResponse<ILogbookTasksHistory>> {
    const urlId: number | string = reviewType === 'logbook_versions' ? '' : id;
    const url = `${this.baseUrl}${
      reviewType === 'logbook_versions'
        ? this.LOGBOOK_VERSIONS.USER_ACTIONS_FOR_ONE_VERSION
        : this.LOGBOOKS.USER_ACTIONS
    }${urlId}`;
    const params: HttpParams = new HttpParams().append('versionIds', id);

    if (urlId === '') {
      return this.http.get<IGetManyResponse<ILogbookTasksHistory>>(url, { params });
    }

    return this.http.get<IGetManyResponse<ILogbookTasksHistory>>(url);
  }

  public getLogbookTasksRecord(logbookId: number, id: number): Observable<IGetOneResponse<ILogbookVersion>> {
    let url = `${this.baseUrl}${this.LOGBOOK_VERSIONS.BASE_URL}${logbookId}/${id}`;
    return this.http.get<IGetOneResponse<ILogbookVersion>>(url);
  }

  public getLogbookTasksActiveRecord(logbookId: number): Observable<IGetOneResponse<ILogbookVersion>> {
    let url = `${this.baseUrl}${this.LOGBOOK_VERSIONS.BASE_URL}${this.LOGBOOK_VERSIONS.ACTIVE}${logbookId}`;
    return this.http.get<IGetOneResponse<ILogbookVersion>>(url);
  }

  public getLogbookTasksFieldSet(id: number): Observable<IGetOneResponse<IFieldSetData[]>> {
    let url = `${this.baseUrl}${this.FIELDSET_ITEMS}${id}`;
    return this.http.get<IGetOneResponse<IFieldSetData[]>>(url);
  }

  public getSelectedForms(
    entry_ids: number[],
    getOnlyActiveForms: boolean = true,
  ): Observable<IBulkResponseRecord<IFormVersionLite>> {
    let url = `${this.baseUrl}${this.LOGBOOK_VERSIONS.ACTIVES}`;
    return this.http.post<IBulkResponseRecord<IFormVersionLite>>(url, {
      payload: {
        entry_ids,
        getOnlyActiveForms,
      },
    });
  }

  public static getLogbookTasksTableHeaderDefaults(translateService: TranslateService): ITableHeader[] {
    return _.cloneDeep([
      {
        value: 'logbookId',
        name: translateService.instant('myTasks.table.headers.logbookId'),
        sortable: true,
        selected: true,
        draggable: true,
        disabled: true,
      },
      {
        value: 'logbookName',
        name: translateService.instant('myTasks.table.headers.logbookName'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'logbookLegacyId',
        name: translateService.instant('myTasks.table.headers.logbookLegacyId'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'versionNumber',
        name: translateService.instant('myTasks.table.headers.versionNumber'),
        selected: true,
        draggable: true,
        sortable: true,
      },
      {
        value: 'createdBy',
        name: translateService.instant('myTasks.table.headers.createdBy'),
        selected: true,
        draggable: true,
        sortable: false,
      },
      {
        value: 'createdAt',
        name: translateService.instant('myTasks.table.headers.createdAt'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'submittedBy',
        name: translateService.instant('myTasks.table.headers.submittedBy'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'submittedAt',
        name: translateService.instant('myTasks.table.headers.submittedAt'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'approvalStepPosition',
        name: translateService.instant('myTasks.table.headers.status'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'dueDate',
        name: translateService.instant('myTasks.table.headers.dueDate'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'actions',
        name: translateService.instant('myTasks.table.headers.history'),
        sortable: false,
        selected: true,
        draggable: true,
        disabled: true,
      },
    ]);
  }
}
