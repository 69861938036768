import { Action } from '@ngrx/store';
import {
  IForm,
  IFormVersionHistory,
  IFormTableQuery,
  IFormVersion,
  ISpecificFormData,
  IFormActionPayload,
  IFormVersionSettings,
  IFormExcelData,
  IFormExcelWithError,
  IPassedIssueDateForms,
  IGetFormVersionsParams,
  IGetFormVersionHistoryParams,
} from './forms.model';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
  IGetOneResponse,
} from '../../shared/model/interface/crud-response-interface.model';
import { IIssuer } from '../../shared/component/issuer/issuer.model';
import { IWorkflow } from '../settings/workflows/workflows.model';
import { IWorkflowStepsData } from '../../view/settings/workflows/workflows.model';
import { IIssuerAndReason } from '../../shared/component/before-action-preparer/before-action-preparer.model';
import { IFormMasterDataDetail } from '../settings/form-master-data/form-master-data.model';
import { ICloneFormPayload, IFormCreate } from '../../view/settings/form/form.model';
import { ILogbookMasterData } from '../settings/logbook-master-data/logbook-master-data.model';
import { IUserShortInfo } from '../settings/users/users.model';
import { ITableHeader } from '../../../constants.model';
import { IBulkActionSingleResponse } from '../../shared/service/bulk-action/bulk-action.model';

export const WORKFLOW_DATA_LOADING = '[FORMS] WORKFLOW DATA LOAD';
export const WORKFLOW_DATA_LOADED = '[FORMS] WORKFLOW DATA LOADED';
export const WORKFLOW_STEP_DATA_LOADING = '[FORMS] WORKFLOW STEP DATA LOAD';
export const WORKFLOW_STEP_DATA_LOADED = '[FORMS] WORKFLOW STEP DATA LOADED';
export const ACTIVE_MASTER_DATA_LOADING = '[FORMS] ACTIVE MASTER DATA LOAD';
export const ACTIVE_MASTER_DATA_LOADED = '[FORMS] ACTIVE MASTER DATA LOADED';
export const GET_FORM_MASTER_DATA = '[FORMS] LOGBOOK MASTER DATA';
export const GET_FORM_MASTER_DATA_COMPLETED = '[FORMS] LOGBOOK MASTER DATA COMPLETED';
export const GET_FORM_VERSION_LOADING = '[FORMS] GET FORM VERSIONS LOADING';
export const GET_FORM_VERSION_LOADED = '[FORMS] GET FORM VERSIONS LOADED';
export const GET_SPECIFIC_FORM_DATA_LOADING = '[FORMS] GET SPECIFIC FORM DATA LOADING';
export const GET_SPECIFIC_FORM_DATA_LOADED = '[FORMS] GET SPECIFIC FORM DATA LOADED';
export const GET_FORM_HISTORY_LOADING = '[FORMS] FORM HISTORY LOADING';
export const GET_FORM_HISTORY_LOADED = '[FORMS] FORM HISTORY LOADED';
export const DELETE_FORM_LOADING = '[FORMS] DELETE FORM LOADING';
export const DELETE_FORM_LOADED = '[FORMS] DELETE FORM LOADED';
export const SUBMIT_FORM_LOADING = '[FORMS] SUBMIT FORM LOADING';
export const SUBMIT_FORM_LOADED = '[FORMS] SUBMIT FORM LOADED';
export const ACTIVATE_FORM_LOADING = '[FORMS] ACTIVATE FORM LOADING';
export const ACTIVATE_FORM_LOADED = '[FORMS] ACTIVATE FORM LOADED';
export const FLAG_TO_BE_ARCHIVE_FORM_LOADING = '[FORMS] FLAG TO BE ARCHIVE FORM LOADING';
export const FLAG_TO_BE_ARCHIVE_FORM_LOADED = '[FORMS] FLAG TO BE ARCHIVE FORM LOADED';
export const ARCHIVE_FORM_LOADING = '[FORMS] ARCHIVE FORM LOADING';
export const ARCHIVE_FORM_LOADED = '[FORMS] ARCHIVE FORM LOADED';
export const FORMS_DATA_LOADING = '[FORMS] FORMS DATA LOAD';
export const FORMS_DATA_LOADED = '[FORMS] FORMS DATA LOADED';
export const ALL_FORM_VERSIONS_DATA_LOADING = '[FORMS] ALL FORM VERSIONS DATA LOAD';
export const ALL_FORM_VERSIONS_DATA_LOADED = '[FORMS] ALL FORM VERSIONS DATA LOADED';
export const ADD_FORM = '[FORMS] ADD FORMS';
export const ADD_FORM_COMPLETED = '[FORMS] ADD FORMS COMPLETED';
export const EDIT_FORM = '[FORMS] EDIT FORMS';
export const EDIT_FORM_COMPLETED = '[FORMS] EDIT FORMS COMPLETED';
export const ADD_FORM_VERSION = '[FORMS] ADD FORM VERSION FORMS';
export const ADD_FORM_VERSION_COMPLETED = '[FORMS] ADD FORM VERSION FORMS COMPLETED';
export const CLONE_FORM = '[FORMS] CLONE FORM';
export const CLONE_FORM_COMPLETED = '[FORMS] CLONE FORM COMPLETED';
export const CHECK_PASSED_ISSUE_DATE = '[FORMS] CHECK PASSED ISSUE DATE';
export const CHECK_PASSED_ISSUE_DATE_COMPLETED = '[FORMS] CHECK PASSED ISSUE DATE COMPLETED';
export const DOWNLOAD_EXCEL = '[FORMS] FORM EXCEL DOWNLOAD';
export const DOWNLOAD_EXCEL_COMPLETED = '[FORMS] FORM EXCEL DOWNLOAD COMPLETED';
export const UPLOAD_EXCEL = '[FORMS] FORM EXCEL UPLOAD';
export const UPLOAD_EXCEL_COMPLETED = '[FORMS] FORM EXCEL UPLOAD COMPLETED';
export const DOWNLOAD_ERROR_EXCEL = '[FORMS] FORM ERROR EXCEL DOWNLOAD';
export const DOWNLOAD_ERROR_EXCEL_COMPLETED = '[FORMS] FORM ERROR EXCEL DOWNLOAD COMPLETED';
export const SET_TABLE_SETTINGS = '[FORMS] FORM SET TABLE SETTINGS';
export const BULK_SUBMIT_FORMS = '[FORMS] BULK SUBMIT FORMS';
export const BULK_SUBMIT_FORMS_COMPLETED = '[FORMS] BULK SUBMIT FORMS COMPLETED';
export const BULK_ACTIVATE_FORMS = '[FORMS] BULK ACTIVATE FORMS';
export const BULK_ACTIVATE_FORMS_COMPLETED = '[FORMS] BULK ACTIVATE FORMS COMPLETED';
export const FETCH_ERROR = '[FORMS] FETCH ERROR';

export class FormsDataLoading implements Action {
  readonly type = FORMS_DATA_LOADING;

  constructor(public tableQuery?: IFormTableQuery, public statusIds?: number[]) {}
}

export class FormsDataLoaded implements Action {
  readonly type = FORMS_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IForm>) {}
}

export class AllFormVersionsDataLoading implements Action {
  readonly type = ALL_FORM_VERSIONS_DATA_LOADING;

  constructor(
    public statuses: number[],
    public tableQuery?: IFormTableQuery,
    public dispatchHideLoader: boolean = true,
  ) {}
}

export class AllFormVersionsDataLoaded implements Action {
  readonly type = ALL_FORM_VERSIONS_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IFormVersionSettings>) {}
}

export class GetWorkflowData implements Action {
  readonly type = WORKFLOW_DATA_LOADING;
}

export class GetWorkflowDataLoaded implements Action {
  readonly type = WORKFLOW_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IWorkflow>) {}
}

export class GetWorkflowStepData implements Action {
  readonly type = WORKFLOW_STEP_DATA_LOADING;

  constructor(public id: number) {}
}

export class GetWorkflowStepDataLoaded implements Action {
  readonly type = WORKFLOW_STEP_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IWorkflowStepsData>) {}
}

export class GetActiveMasterDataLoading implements Action {
  readonly type = ACTIVE_MASTER_DATA_LOADING;

  constructor(public dispatchHideLoader: boolean = true) {}
}

export class GetActiveMasterDataLoaded implements Action {
  readonly type = ACTIVE_MASTER_DATA_LOADED;

  constructor(public payload: IGetManyResponse<ILogbookMasterData>) {}
}

export class GetMasterDataLoading implements Action {
  readonly type = GET_FORM_MASTER_DATA;

  constructor(public masterDataId: number, public dispatchHideLoader: boolean = true) {}
}

export class GetMasterDataLoaded implements Action {
  readonly type = GET_FORM_MASTER_DATA_COMPLETED;

  constructor(public payload: IBaseOneResponse<IFormMasterDataDetail>) {}
}

export class GetFormVersionsLoading implements Action {
  readonly type = GET_FORM_VERSION_LOADING;

  constructor(public params: IGetFormVersionsParams) {}
}

export class GetFormVersionsLoaded implements Action {
  readonly type = GET_FORM_VERSION_LOADED;

  constructor(public payload: IGetManyResponse<IFormVersion>) {}
}

export class GetSpecificFormDataLoading implements Action {
  readonly type = GET_SPECIFIC_FORM_DATA_LOADING;

  constructor(
    public formId: number | null = null,
    public formVersionId: number | null = null,
    public issuer?: IIssuer,
    public dispatchHideLoader: boolean = true,
    public previousVersionId: number | null = null,
    public isIncludeDisabled: boolean = false,
  ) {}
}

export class GetSpecificFormDataLoaded implements Action {
  readonly type = GET_SPECIFIC_FORM_DATA_LOADED;

  constructor(public payload: ISpecificFormData) {}
}

export class GetFormHistoryLoading implements Action {
  readonly type = GET_FORM_HISTORY_LOADING;

  constructor(public params: IGetFormVersionHistoryParams, public formID?: number, public getTemplateHistory: boolean = false) {}
}

export class GetFormHistoryLoaded implements Action {
  readonly type = GET_FORM_HISTORY_LOADED;

  constructor(public payload: IGetManyResponse<IFormVersionHistory>) {}
}

export class SubmitFormLoading implements Action {
  readonly type = SUBMIT_FORM_LOADING;

  constructor(public formId: number | null, public formVersionId: number | null, public issuer: IIssuer | null) {}
}

export class SubmitFormLoaded implements Action {
  readonly type = SUBMIT_FORM_LOADED;

  constructor(public payload: IBaseOneResponse<IFormVersion>) {}
}

export class ActivateFormLoading implements Action {
  readonly type = ACTIVATE_FORM_LOADING;

  constructor(public formId: number | null, public formVersionId: number | null, public issuer: IIssuer | null) {}
}

export class ActivateFormLoaded implements Action {
  readonly type = ACTIVATE_FORM_LOADED;

  constructor(public payload: IBaseOneResponse<IForm>) {}
}

export class DeleteFormLoading implements Action {
  readonly type = DELETE_FORM_LOADING;

  constructor(
    public formId: number | null,
    public formVersionId: number | null,
    public issuerAndReason: IIssuerAndReason,
  ) {}
}

export class DeleteFormLoaded implements Action {
  readonly type = DELETE_FORM_LOADED;

  constructor(public payload: IBaseOneResponse<IFormVersion>) {}
}

export class FlagToBeArchiveFormLoading implements Action {
  readonly type = FLAG_TO_BE_ARCHIVE_FORM_LOADING;

  constructor(public id: number | null, public issuerAndReason: IIssuerAndReason) {}
}

export class FlagToBeArchiveFormLoaded implements Action {
  readonly type = FLAG_TO_BE_ARCHIVE_FORM_LOADED;

  constructor(public payload: IBaseOneResponse<IForm>) {}
}

export class ArchiveFormLoading implements Action {
  readonly type = ARCHIVE_FORM_LOADING;

  constructor(public id: number | null, public issuerAndReason: IIssuerAndReason) {}
}

export class ArchiveFormLoaded implements Action {
  readonly type = ARCHIVE_FORM_LOADED;

  constructor(public payload: IBaseOneResponse<IForm>) {}
}

export class AddForm implements Action {
  readonly type = ADD_FORM;
  constructor(public payload: IFormActionPayload, public issuer: IIssuer | null) {}
}

export class AddFormCompleted implements Action {
  readonly type = ADD_FORM_COMPLETED;

  constructor(public payload: IBaseOneResponse<IForm>) {}
}

export class AddFormVersion implements Action {
  readonly type = ADD_FORM_VERSION;

  constructor(public formId: number | null, public formVersion: any, public issuer: IIssuer | null) {}
}
export class AddFormVersionCompleted implements Action {
  readonly type = ADD_FORM_VERSION_COMPLETED;

  constructor(public payload: IBaseOneResponse<IFormVersion>) {}
}

export class EditForm implements Action {
  readonly type = EDIT_FORM;

  constructor(
    public form: any,
    public formId: number | null,
    public formVersionId: number | null,
    public issuer: IIssuer | null,
  ) {}
}
export class EditFormCompleted implements Action {
  readonly type = EDIT_FORM_COMPLETED;

  constructor(public payload: IBaseOneResponse<IForm>) {}
}

export class CloneForm implements Action {
  readonly type = CLONE_FORM;

  constructor(public clonedPayload: ICloneFormPayload, public formId: number, public issuer: IIssuer | null) {}
}

export class CloneFormCompleted implements Action {
  readonly type = CLONE_FORM_COMPLETED;

  constructor(public payload: IGetManyResponse<any>) {}
}

export class CheckPassedIssueDate implements Action {
  readonly type = CHECK_PASSED_ISSUE_DATE;
}

export class CheckPassedIssueDateCompleted implements Action {
  readonly type = CHECK_PASSED_ISSUE_DATE_COMPLETED;

  constructor(public payload: IGetManyResponse<IPassedIssueDateForms>) {}
}

export class DownloadExcel implements Action {
  readonly type = DOWNLOAD_EXCEL;

  constructor(public activeMasterDataData: IFormMasterDataDetail, public workflows: IWorkflow[]) {}
}

export class DownloadExcelCompleted implements Action {
  readonly type = DOWNLOAD_EXCEL_COMPLETED;
}

export class UploadExcel implements Action {
  readonly type = UPLOAD_EXCEL;

  constructor(
    public forms: IFormCreate[],
    public workflow: IWorkflow[],
    public issuerAndReason: IIssuerAndReason,
    public activeMasterDataData: IFormMasterDataDetail,
    public scopeId?: number | null,
  ) {}
}

export class UploadExcelCompleted implements Action {
  readonly type = UPLOAD_EXCEL_COMPLETED;

  constructor(
    public payload: (IFormExcelWithError & { errorMessages?: string })[],
    public success: boolean,
    public issuerAndReason: IIssuerAndReason | null,
  ) {}
}

export class DownloadErrorExcel implements Action {
  readonly type = DOWNLOAD_ERROR_EXCEL;

  constructor(
    public payload: IFormExcelData[],
    public activeMasterDataData: IFormMasterDataDetail,
    public workflow: IWorkflow[],
    public withData: boolean,
  ) {}
}

export class DownloadErrorExcelCompleted implements Action {
  readonly type = DOWNLOAD_ERROR_EXCEL_COMPLETED;
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: ITableHeader[], public setAsDefault: boolean) {}
}

export class BulkSubmitForms implements Action {
  readonly type = BULK_SUBMIT_FORMS;

  constructor(public formVersionIds: number[], public issuer: IIssuer | null) {}
}

export class BulkSubmitFormsCompleted implements Action {
  readonly type = BULK_SUBMIT_FORMS_COMPLETED;

  constructor(public response: IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>) {}
}

export class BulkActivateForms implements Action {
  readonly type = BULK_ACTIVATE_FORMS;

  constructor(public formVersionIds: number[], public issuer: IIssuer | null) {}
}

export class BulkActivateFormsCompleted implements Action {
  readonly type = BULK_ACTIVATE_FORMS_COMPLETED;

  constructor(public response: IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object, public errorType?: string) {
  }
}

export type FormsActions =
  | FormsDataLoading
  | FormsDataLoaded
  | AllFormVersionsDataLoading
  | AllFormVersionsDataLoaded
  | GetWorkflowData
  | GetWorkflowDataLoaded
  | GetWorkflowStepData
  | GetWorkflowStepDataLoaded
  | GetActiveMasterDataLoaded
  | GetActiveMasterDataLoading
  | GetMasterDataLoaded
  | GetMasterDataLoading
  | GetFormVersionsLoaded
  | GetFormVersionsLoading
  | GetSpecificFormDataLoading
  | GetSpecificFormDataLoaded
  | GetFormHistoryLoading
  | GetFormHistoryLoaded
  | SubmitFormLoading
  | SubmitFormLoaded
  | ActivateFormLoading
  | ActivateFormLoaded
  | DeleteFormLoading
  | DeleteFormLoaded
  | FlagToBeArchiveFormLoading
  | FlagToBeArchiveFormLoaded
  | ArchiveFormLoading
  | ArchiveFormLoaded
  | AddForm
  | AddFormCompleted
  | AddFormVersion
  | AddFormVersionCompleted
  | CloneForm
  | CloneFormCompleted
  | EditForm
  | EditFormCompleted
  | CheckPassedIssueDate
  | CheckPassedIssueDateCompleted
  | DownloadExcel
  | DownloadExcelCompleted
  | UploadExcel
  | UploadExcelCompleted
  | DownloadErrorExcel
  | DownloadErrorExcelCompleted
  | FetchError
  | BulkSubmitForms
  | BulkSubmitFormsCompleted
  | BulkActivateForms
  | BulkActivateFormsCompleted;
