import { IFormField } from '../../../shared/model/interface/scw-form.model';
import {
  IScwMatDatepickerReturn,
  ScwMatDatepickerRule,
} from '../../../shared/component/scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.model';
import { ISelect, ScwMatSelectRule } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ScwMatInputRule } from '../../../shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { ExtendedComponentSchema } from 'formiojs';
import { IDateRangeReturn } from '../../../shared/component/date-range-picker/date-range-picker.model';
import { EFormActivityType } from '../../../store/forms/forms.model';
import { TFormIoComponentKey } from '../../../shared/helper/component-utilities';
import { TBulkActions } from '../../../shared/service/bulk-action/bulk-action.model';

export type FormActionsType =
  | 'add-modal-open'
  | 'edit-modal-open'
  | 'add-new-version-modal-open'
  | 'add'
  | 'add-new-version'
  | 'clone'
  | 'edit'
  | 'all-version'
  | 'all-version-show'
  | 'qr'
  | 'history'
  | 'flag-to-be-archive'
  | 'archive'
  | 'submit'
  | 'activate'
  | 'delete'
  | 'excel'
  | TBulkActions;

export interface IFormForm {
  formName: IFormField<string | null, ScwMatInputRule>;
  formApprovalWorkFlow: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
  entryReviewWorkFlow: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
  formId: IFormField<string | null, ScwMatInputRule>;
  versionNumber: IFormField<string | null, ScwMatInputRule>;
  legacyId: IFormField<string | null, ScwMatInputRule>;
  activityType: IFormField<ISelect<EFormActivityType, string>[] | null, ScwMatSelectRule>;
  issuedDate: IFormField<IScwMatDatepickerReturn | null, ScwMatDatepickerRule>;
  useCheckIn: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
}

export interface ICloneFormPayload {
  form: number;
  workflowId: number;
  workflowEntriesId: number;
  fieldVersionId: number;
  fieldMeta: any[];
  meta: ExtendedComponentSchema[] | undefined;
  name: string;
  reason: string | null;
  legacyId: string | null;
  activityType: EFormActivityType | null;
  criticalFieldCount: number;
  useCheckIn: boolean;
}

export interface IFieldMeta {
  fieldId: string;
  inputModel: number | string | boolean | IFieldMetaDropdown[] | IDateRangeReturn | null;
}

export interface IFieldMetaDropdown {
  id: number;
  label: string;
  status: true;
}

export interface IFormCreate {
  criticalFieldCount: number;
  isActive: boolean;
  activityType: string;
  isSubmitted: boolean;
  fieldVersionId: number;
  fieldMeta: any[];
  meta: any;
  legacyId: string;
  issuedDate: string | null;
  name: string | null;
  workflowId: number;
  workflowEntriesId: number;
  reason: string | null;
  useCheckIn?: boolean | null;
}

export interface IPreviousFormVersion {
  formName: string | null;
  formId: string | null;
  formApprovalWorkFlow: ISelect<number, string>[];
  entryReviewWorkFlow: ISelect<number, string>[];
  versionNumber: number | null;
  legacyId: string | null;
  activityType: ISelect<EFormActivityType, string>[];
  checkInMechanism: ISelect<number, string>[];
  issuedDate: IScwMatDatepickerReturn;
}

export enum EFormActionsErrorType {
  LAST_ITEM_IN_SCOPE = 'form_is_last_item_in_scope',
}

export interface IFormIoComponentKeyId {
  id: string;
  key: string;
  type?: TFormIoComponentKey;
  label?: string;
}

export interface IFormIoDefaultComponentSchema {
  components: ExtendedComponentSchema[];
  isTableRows: false;
}

export interface IFormIoTableComponentSchema {
  components: ExtendedComponentSchema[][];
  isTableRows: true;
}
