import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './forms.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as LogbookAppReducer from '../logbook.reducer';
import * as AppActions from '../app/actions';
import * as _ from 'lodash';
import { FormsService } from './forms.service';
import {
  IForm,
  IFormExcelWithError,
  IFormVersion,
  IFormVersionHistory,
  IFormVersionSettings,
  ISpecificFormData,
  IPassedIssueDateForms,
  IGetFormVersionsParams,
  IGetFormVersionHistoryParams,
} from './forms.model';
import {
  IBaseCrudResponse,
  IBaseOneResponse,
  IBulkResponseData,
  IBulkResponseRecord,
  IGetManyResponse,
  IGetOneResponse,
} from '../../shared/model/interface/crud-response-interface.model';
import { IWorkflow } from '../settings/workflows/workflows.model';
import { WorkflowsService } from '../settings/workflows/workflows.service';
import { IWorkflowStepsData } from '../../view/settings/workflows/workflows.model';
import { FormMasterDataService } from '../settings/form-master-data/form-master-data.service';
import { IFormMasterData } from '../settings/form-master-data/form-master-data.model';
import { ServiceUtilities } from '../../shared/helper/service-utilities';
import { emptyAction } from '../../../constants';
import { ErrorMessageService } from '../../shared/service/error-message.service';
import { ExcelHelperService } from '../../shared/service/excel/excel-helper.service';
import { EFormVersionsRequestingPages } from '../../shared/model/enum/constants';
import { IBulkActionSingleResponse } from '../../shared/service/bulk-action/bulk-action.model';

@Injectable()
export class FormsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppReducer.LogbookAppState>,
    private readonly service: FormsService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly formMasterDataService: FormMasterDataService,
    private readonly workflowService: WorkflowsService,
  ) {}

  getFormData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FORMS_DATA_LOADING),
      switchMap((payload: ObjectActions.FormsDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let httpParams: HttpParams = new HttpParams()
          .append('page', payload.tableQuery?.page ?? 1)
          .append('limit', payload.tableQuery?.rowsPerPage ?? 10)
          .append('sort', 'form_id')
          .append('direction', 'ASC');

        if (payload.tableQuery?.sort) {
          httpParams = httpParams
            .set('sort', payload.tableQuery.sort.column)
            .set('direction', payload.tableQuery.sort.type);
        }

        if (payload.tableQuery?.search) {
          httpParams = httpParams.append('search', payload.tableQuery.search);
        }

        if (!_.isNil(payload.tableQuery?.isActive)) {
          httpParams = httpParams.append('isActive', Boolean(payload.tableQuery?.isActive));
        }

        if (!_.isNil(payload.tableQuery?.versionType)) {
          httpParams = httpParams.append('include_archived', String(payload.tableQuery?.versionType));
        }

        if (Array.isArray(payload.statusIds)) {
          httpParams = httpParams.append('statuses', JSON.stringify(payload.statusIds));
        }

        return this.service.getForms(httpParams).pipe(
          switchMap((response: IGetManyResponse<IForm>) => {
            return of(new ObjectActions.FormsDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getAllFormVersions = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ALL_FORM_VERSIONS_DATA_LOADING),
      switchMap((payload: ObjectActions.AllFormVersionsDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
          page: payload.tableQuery?.page ?? 1,
          rowsPerPage: payload.tableQuery?.rowsPerPage ?? 10,
          sort: payload.tableQuery?.sort ?? { type: '-', column: 'form' },
          ...(payload.tableQuery?.search && { search: payload.tableQuery.search }),
          ...(!_.isNil(payload.tableQuery?.versionType) && { versionType: payload.tableQuery?.versionType }),
          ...(!_.isNil(payload.statuses) &&
            Array.isArray(payload.statuses) && { statuses: JSON.stringify(payload.statuses) }),
        });

        httpParams = httpParams.append('requesting_page', EFormVersionsRequestingPages.FORM_SETTINGS);

        return this.service.getAllFormVersions(httpParams).pipe(
          switchMap((response: IGetManyResponse<IFormVersionSettings>) => {
            return of(
              new ObjectActions.AllFormVersionsDataLoaded(response),
              payload.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getWorkFlowData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WORKFLOW_DATA_LOADING),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParas: HttpParams = new HttpParams().append('limit', 1000).append('just_workflow_data', true);

        return this.workflowService.getWorkflows(httpParas).pipe(
          switchMap((response: IGetManyResponse<IWorkflow>) => {
            return of(new ObjectActions.GetWorkflowDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getWorkflowStepData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WORKFLOW_STEP_DATA_LOADING),
      switchMap((payload: ObjectActions.GetWorkflowStepData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.workflowService.getWorkflowsSteps(payload.id).pipe(
          switchMap((response: IGetManyResponse<IWorkflowStepsData>) => {
            return of(new ObjectActions.GetWorkflowStepDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getMasterData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_FORM_MASTER_DATA),
      switchMap((payload: ObjectActions.GetMasterDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.formMasterDataService.getFormMasterDetailData(payload.masterDataId).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.GetMasterDataLoaded({
                success: response[0].success && response[1].success,
                data: { ...response[0].data, fields: response[1].data ?? [] },
              }),
              payload.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getActiveMasterData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ACTIVE_MASTER_DATA_LOADING),
      switchMap((payload: ObjectActions.GetActiveMasterDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = new HttpParams().set('is_active', true);
        return this.formMasterDataService.getFormMasterData(httpParams).pipe(
          switchMap((response: IGetManyResponse<IFormMasterData>) => {
            return of(
              new ObjectActions.GetMasterDataLoading(response.data[0].id, payload.dispatchHideLoader),
              new ObjectActions.GetActiveMasterDataLoaded(response),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getSpecificFormData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_SPECIFIC_FORM_DATA_LOADING),
      switchMap((payload: ObjectActions.GetSpecificFormDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service
          .getSpecificFormData(
            payload.formId,
            payload.formVersionId,
            payload.previousVersionId,
            payload.issuer,
            payload.isIncludeDisabled,
          )
          .pipe(
            switchMap((response: ISpecificFormData) => {
              return of(
                new ObjectActions.GetSpecificFormDataLoaded(response),
                payload.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction(),
              );
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormVersions = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_FORM_VERSION_LOADING),
      switchMap((payload: ObjectActions.GetFormVersionsLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const params: IGetFormVersionsParams = payload.params;
        const httpParams: HttpParams = new HttpParams()
        .append('ongoing_forms', 1)
        .append('page', params.page)
        .append('limit', params.rowsPerPage);

        return this.service.getFormVersions(params.formId, httpParams).pipe(
          switchMap((response: IGetManyResponse<IFormVersion>) => {
            return of(new ObjectActions.GetFormVersionsLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_FORM_HISTORY_LOADING),
      switchMap((payload: ObjectActions.GetFormHistoryLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const params: IGetFormVersionHistoryParams = payload.params;
        let httpParams: HttpParams = new HttpParams();

        if (!_.isNil(params.formId)) {
          httpParams = httpParams.append('formId', params.formId);
        } else if (!_.isNil(params.versionIds)) {
          httpParams = httpParams.append('versionIds', params.versionIds.join(','));
        }

        return this.service.getFormVersionHistory(httpParams, payload.formID, payload.getTemplateHistory).pipe(
          switchMap((response: IGetManyResponse<IFormVersionHistory>) => {
            return of(new ObjectActions.GetFormHistoryLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  activateForm = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ACTIVATE_FORM_LOADING),
      switchMap((payload: ObjectActions.ActivateFormLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.activateForm(payload.formId, payload.formVersionId, payload.issuer).pipe(
          switchMap((response: IBaseOneResponse<IForm>) => {
            return of(new ObjectActions.ActivateFormLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteForm = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_FORM_LOADING),
      switchMap((payload: ObjectActions.DeleteFormLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteForm(payload.formId, payload.formVersionId, payload.issuerAndReason).pipe(
          switchMap((response: IBaseOneResponse<IFormVersion>) => {
            return of(new ObjectActions.DeleteFormLoaded(response));
          }),
          catchError((errorRes) => {
            return of(
              new ObjectActions.FetchError(errorRes, errorRes.error?.data?.error),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  submitForm = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SUBMIT_FORM_LOADING),
      switchMap((payload: ObjectActions.SubmitFormLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.submitForm(payload.formId, payload.formVersionId, payload.issuer).pipe(
          switchMap((response: IBaseOneResponse<IFormVersion>) => {
            return of(new ObjectActions.SubmitFormLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  flagToBeArchiveForm = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FLAG_TO_BE_ARCHIVE_FORM_LOADING),
      switchMap((payload: ObjectActions.FlagToBeArchiveFormLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.flagToBeArchiveForm(payload.id, payload.issuerAndReason).pipe(
          switchMap((response: IBaseOneResponse<IForm>) => {
            return of(new ObjectActions.FlagToBeArchiveFormLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  archiveForm = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ARCHIVE_FORM_LOADING),
      switchMap((payload: ObjectActions.ArchiveFormLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.archiveForm(payload.id, payload.issuerAndReason).pipe(
          switchMap((response: IBaseOneResponse<IForm>) => {
            return of(new ObjectActions.ArchiveFormLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addForm = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_FORM),
      switchMap((payload: ObjectActions.AddForm) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addForm(payload.payload, payload.issuer).pipe(
          switchMap((response: IBaseOneResponse<any>) => {
            return of(new ObjectActions.AddFormCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addFormVersion = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_FORM_VERSION),
      switchMap((payload: ObjectActions.AddFormVersion) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addFormVersion(payload.formId, payload.formVersion, payload.issuer).pipe(
          switchMap((response: IBaseOneResponse<IFormVersion>) => {
            return of(new ObjectActions.AddFormVersionCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editLogbook = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_FORM),
      switchMap((payload: ObjectActions.EditForm) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editForm(payload.form, payload.formId, payload.formVersionId, payload.issuer).pipe(
          switchMap((response: IBaseOneResponse<any>) => {
            return of(new ObjectActions.EditFormCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  cloneForm = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CLONE_FORM),
      switchMap((payload: ObjectActions.CloneForm) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.cloneForm(payload.clonedPayload, payload.formId, payload.issuer).pipe(
          switchMap((response: IBaseOneResponse<any>) => {
            return of(new ObjectActions.CloneFormCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  checkPassedIssueDate = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHECK_PASSED_ISSUE_DATE),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams();

        return this.service.checkPassedIssueDate(httpParams).pipe(
          switchMap((response: IGetManyResponse<IPassedIssueDateForms>) => {
            return of(new ObjectActions.CheckPassedIssueDateCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_EXCEL),
      switchMap((objectData: ObjectActions.DownloadExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.service.downloadExcel(false, objectData.activeMasterDataData, objectData.workflows);

        return emptyAction;
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  uploadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_EXCEL),
      switchMap((objectData: ObjectActions.UploadExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.uploadExcel(objectData.forms, objectData.issuerAndReason, objectData.scopeId).pipe(
          switchMap((response: IBulkResponseData) => {
            const errorsWithKeysMapped: IFormExcelWithError[] = [];

            (response.errors as unknown as IFormExcelWithError[])?.forEach((user: IFormExcelWithError) =>
              errorsWithKeysMapped.push(
                _.mapKeys(user, (v, k: string) => _.camelCase(k)) as unknown as IFormExcelWithError,
              ),
            );

            this.errorMessageService.getTranslatedErrorMessage(errorsWithKeysMapped ?? []);

            const mergedArray: IFormExcelWithError[] =
              this.excelHelperService.mergeBulkResponseWithRequestData<IFormExcelWithError>(
                { data: errorsWithKeysMapped as IBaseCrudResponse[], success: response.success },
                errorsWithKeysMapped,
              );

            return of(
              new ObjectActions.UploadExcelCompleted(mergedArray, response.success, objectData.issuerAndReason),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  downloadErrorExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_ERROR_EXCEL),
      switchMap((objectData: ObjectActions.DownloadErrorExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.service.downloadExcel(
          objectData.withData,
          objectData.activeMasterDataData,
          objectData.workflow,
          true,
          objectData.payload,
        );

        return emptyAction;
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  bulkSubmitForms = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_SUBMIT_FORMS),
      switchMap((payload: ObjectActions.BulkSubmitForms) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkSubmitForms(payload.formVersionIds, payload.issuer).pipe(
          switchMap((response: IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>) => {
            return of(new ObjectActions.BulkSubmitFormsCompleted(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  bulkActivateForms = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_ACTIVATE_FORMS),
      switchMap((payload: ObjectActions.BulkActivateForms) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkActivateForms(payload.formVersionIds, payload.issuer).pipe(
          switchMap((response: IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>) => {
            return of(new ObjectActions.BulkActivateFormsCompleted(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
