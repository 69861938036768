import { Inject, Injectable } from '@angular/core';
import { IFormSubmissionUserAction, ITableHeader, IUserAction, PageConfigurationTypes } from '../../../constants.model';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { IFormSubmission, IHomeFormDetail, IHomeSearchResult } from './home.model';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../logbook.reducer';
import { IFormSubmissionHistory } from '../../view/home/form-submission-table/form-submission-table.model';
import { HelperService } from '../../shared/service/helper.service';
import { IPageHeader } from '../../shared/service/page-header/page-header.model';
import { IIssuerAndReason } from '../../shared/component/before-action-preparer/before-action-preparer.model';
import * as HomeActions from './home.actions';
import { EActionType, EApprovalStatuses } from '../../shared/model/enum/constants';
import { TColorInput } from '../../shared/component/scw-mat-ui/scw-mat-border-coloring/scw-mat-border-coloring.model';
import { IHomeLogbook } from '../../view/home/home.model';
import { EFormActivityType, IFormVersion } from '../forms/forms.model';
import { ILogbookMasterDataFields } from '../settings/logbook-master-data/logbook-master-data.model';
import { IFormMasterDataDetail } from '../settings/form-master-data/form-master-data.model';
import {
  IActiveVersionHistory,
  IAllVersionHistory,
  IFormDetailView,
  IMasterDataFormatted,
  IWorkflowStepsView,
} from '../../view/home/logbook-home-detail/logbook-home-detail.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ISelect } from '../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ComponentUtilities } from '../../shared/helper/component-utilities';
import { IWorkflowStepsData } from '../../view/settings/workflows/workflows.model';
import { IGetCurrentUserResponse } from '../app/model';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private readonly statusColorMapping: { id: EApprovalStatuses; color: TColorInput }[] = [
    { id: 1, color: 'awaitingApproval' },
    { id: 0, color: 'ongoing' },
    { id: -1, color: 'sendBack' },
    { id: -2, color: 'obsolete' },
    { id: -3, color: 'approved' },
    { id: -4, color: 'completed' },
    { id: -5, color: 'active' },
    { id: -6, color: 'canceled' },
    { id: -7, color: 'rejected' },
  ];
  public static readonly pageHeader: IPageHeader = {
    title: '',
    titleKey: 'Home Logs History',
    icon: '',
    fullScreenButton: false,
    fullScreenTargetElementId: '',
  };

  private readonly URLS = {
    HOME_SEARCH_URL: `${this.baseUrl}/search`,
    GET_CURRENT_USER: `${this.baseUrl}/users/get-current-user`,
  };

  constructor(
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    public http: HttpClient,
    private readonly store: Store<LogbookAppState>,
    private readonly translate: TranslateService,
    private readonly helperService: HelperService,
  ) {
  }

  public static getFormSubmissionTableHeaderDefaults(translate: TranslateService): ITableHeader[] {
    const masterDataText: string = translate.instant('pageConfiguration.modal.labels.masterData');
    return _.cloneDeep([
      {
        value: 'id',
        name: translate.instant('general.datatable.headers.logId'),
        selected: true,
        sortable: false,
        draggable: true,
        type: masterDataText,
        disabled: true,
      },
      {
        value: 'logbookLogbookId',
        name: translate.instant('general.datatable.headers.logbookId'),
        selected: true,
        sortable: false,
        draggable: true,
        type: masterDataText,
      },
      {
        value: 'logbookName',
        name: translate.instant('general.datatable.headers.logbookName'),
        selected: true,
        sortable: false,
        draggable: true,
        type: masterDataText,
      },
      {
        value: 'logbookLegacyId',
        name: translate.instant('general.datatable.headers.logbookLegacyId'),
        selected: true,
        sortable: false,
        draggable: true,
        type: masterDataText,
      },
      {
        value: 'logbookVersionNumber',
        name: translate.instant('general.datatable.headers.logbookVersion'),
        selected: true,
        sortable: false,
        draggable: true,
        type: masterDataText,
      },
      {
        value: 'createdBy',
        name: translate.instant('general.datatable.headers.createdBy'),
        selected: true,
        sortable: false,
        draggable: true,
        type: masterDataText,
      },
      {
        value: 'createdAt',
        name: translate.instant('home.logbook.createdAt'),
        selected: true,
        sortable: false,
        draggable: true,
        type: masterDataText,
      },
      {
        value: 'submittedBy',
        name: translate.instant('general.datatable.headers.submittedBy'),
        selected: true,
        sortable: false,
        draggable: true,
        type: masterDataText,
      },
      {
        value: 'submittedAt',
        name: translate.instant('general.datatable.headers.submittedAt'),
        selected: true,
        sortable: false,
        draggable: true,
        type: masterDataText,
      },
      {
        value: 'formattedIsMobileLogbook',
        name: translate.instant('general.datatable.headers.mobileLogbook'),
        selected: false,
        sortable: false,
        draggable: true,
        type: masterDataText,
      },
      {
        value: 'currentParentLogbookName',
        name: translate.instant('general.datatable.headers.parentLogbookName'),
        selected: true,
        sortable: false,
        draggable: true,
        type: masterDataText,
      },
      {
        value: 'formFormId',
        name: translate.instant('general.datatable.headers.formId'),
        selected: true,
        sortable: false,
        draggable: true,
        type: masterDataText,
      },
      {
        value: 'formName',
        name: translate.instant('general.datatable.headers.formName'),
        selected: true,
        sortable: false,
        draggable: true,
        type: masterDataText,
      },
      {
        value: 'formLegacyId',
        name: translate.instant('general.datatable.headers.formLegacyId'),
        selected: true,
        sortable: false,
        draggable: true,
        type: masterDataText,
      },
      {
        value: 'formActivityType',
        name: translate.instant('general.datatable.headers.formActivityType'),
        selected: false,
        sortable: false,
        draggable: true,
        type: masterDataText,
      },
      {
        value: 'formVersionNumber',
        name: translate.instant('general.datatable.headers.formVersion'),
        selected: true,
        sortable: false,
        draggable: true,
        type: masterDataText,
      },
      {
        value: 'approvalStepPosition',
        name: translate.instant('general.datatable.headers.status'),
        selected: true,
        sortable: false,
        draggable: true,
        type: masterDataText,
      },
      {
        value: 'actions',
        name: translate.instant('general.datatable.headers.actions'),
        selected: true,
        sortable: false,
        draggable: true,
        disabled: true,
        type: masterDataText,
      },
      {
        value: 'content',
        name: translate.instant('general.datatable.headers.history'),
        selected: true,
        sortable: false,
        draggable: true,
        disabled: true,
        type: masterDataText,
      },
    ]);
  }

  public getHistoryTableHeaderDefaults(): ITableHeader[] {
    return _.cloneDeep([
      {
        value: 'action',
        name: this.translate.instant('general.datatable.headers.action'),
        selected: true,
        sortable: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
        disabled: true,
      },
      {
        value: 'actionBy',
        name: this.translate.instant('general.datatable.headers.actionTakenBy'),
        selected: true,
        sortable: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'actionAt',
        name: this.translate.instant('general.datatable.headers.actionTakenAt'),
        selected: true,
        sortable: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'actionReason',
        name: this.translate.instant('general.datatable.headers.actionReason'),
        selected: true,
        sortable: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
    ]);
  }

  public getFormSubmissionHistoryTableHeaderDefaults(): ITableHeader[] {
    return _.cloneDeep([
      ...this.getHistoryTableHeaderDefaults(),
      {
        value: 'change',
        name: this.translate.instant('general.datatable.headers.change'),
        selected: true,
        sortable: false,
        type: PageConfigurationTypes.TABLE,
      },
    ]);
  }

  public getGroupedHistoryTableHeaders(): ITableHeader[] {
    return _.cloneDeep([
      {
        value: 'version',
        name: this.translate.instant('general.datatable.headers.version'),
        selected: true,
        sortable: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
        disabled: true,
      },
      {
        value: 'createdBy',
        name: this.translate.instant('general.datatable.headers.createdBy'),
        selected: true,
        sortable: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'activatedAt',
        name: this.translate.instant('general.datatable.headers.activatedAt'),
        selected: true,
        sortable: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'actionReason',
        name: this.translate.instant('general.datatable.headers.reasonForNewVersion'),
        selected: true,
        sortable: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
    ]);
  }

  public formatFormSubmissionHistory(formSubmissionHistory: IFormSubmissionUserAction[]): IFormSubmissionHistory[] {
    return formSubmissionHistory.map((formSubmissionHistoryItem: IFormSubmissionUserAction) => {
      const action: string = this.translate.instant(
        `general.userActions.${
          formSubmissionHistoryItem.action === EActionType.TO_BE_REVIEWED
            ? EActionType.SUBMITTED
            : formSubmissionHistoryItem.action
        }`,
      );
      const completedTranslation: string = this.translate.instant(`general.userActions.completed`);

      return {
        actionRaw: formSubmissionHistoryItem.action,
        action: formSubmissionHistoryItem.mobileLogbookSubmission ? completedTranslation : action,
        actionBy: {
          firstName: formSubmissionHistoryItem.createdBy?.firstName ?? '',
          lastName: formSubmissionHistoryItem.createdBy?.lastName ?? '',
          title: formSubmissionHistoryItem.createdBy?.title ?? '',
          email: formSubmissionHistoryItem.createdBy?.email ?? '',
        },
        actionAt: HelperService.userDateTimeFormat
          ? moment(formSubmissionHistoryItem.createdAt).format(HelperService.userDateTimeFormat)
          : formSubmissionHistoryItem.createdAt,
        actionReason: formSubmissionHistoryItem.reason || _.get(formSubmissionHistoryItem, 'meta.reason', ''),
        createdAt: formSubmissionHistoryItem.createdAt,
        change: formSubmissionHistoryItem.changes,
        mobileLogbookSubmission: formSubmissionHistoryItem.mobileLogbookSubmission,
        updateCanceled: _.get(formSubmissionHistoryItem, 'meta.updateCanceled', false),
      };
    });
  }

  public formatFormSubmissions(
    formSubmissions: IFormSubmission[],
    addArchivedString: boolean = false,
  ): IFormSubmission[] {
    const yesTranslation: string = this.translate.instant('general.yes');
    const noTranslation: string = this.translate.instant('general.no');
    const movingTranslation: string = this.translate.instant('general.logbookActivityTypes.moving');
    const formActivityTypes: ISelect<string, string>[] = this.helperService.getFormActivityTypes();
    const yesNo: ISelect<number, string>[] = this.helperService.getYesNoDropdown();

    const formSubmissionsModified: IFormSubmission[] = formSubmissions.map((formSubmission: IFormSubmission) => {
      const status: string | undefined = _.find(this.statusColorMapping, {
        id: formSubmission.approvalStepPosition > 1 ? 1 : formSubmission.approvalStepPosition,
      })?.color;
      const meta: any = JSON.parse(
        _.isString(formSubmission.meta) ? formSubmission.meta : JSON.stringify(formSubmission.meta),
      );
      const isMobileLogbookSubmission: boolean = Boolean(meta?.mobile_logbook_submission);
      const isNotSourceMobileLogbook: boolean = !Boolean(meta?.changed_parent);
      const addArchivedForChild: boolean = Boolean(meta?.add_archived_label);
      const mobileLogbookSubmissionLogbookName: string = meta?.logbook_name ?? '';
      const mobileLogbookSubmissionLogbookLogbookId: string = meta?.logbook_logbook_id ?? '';
      const mobileLogbookSubmissionLogbookLegacyId: string = meta?.logbook_legacy_id ?? '';
      const mobileLogbookSubmissionLogbookVersionNumber: number = meta?.logbook_version_number ?? '';
      const mobileLogbookSubmissionParentLogbookName: string = meta?.current_parent_logbook_name ?? '';
      const formSubmissionCurrentLogbookName: string = formSubmission.currentParentLogbookName ?? '';
      const useMetaData: boolean = isNotSourceMobileLogbook && isMobileLogbookSubmission;
      const currentParentLogbookName: string = useMetaData
        ? mobileLogbookSubmissionParentLogbookName
        : formSubmissionCurrentLogbookName;
      const logbookName: string = useMetaData
        ? this.helperService.getNameWithArchiveLabel(addArchivedForChild, mobileLogbookSubmissionLogbookName)
        : this.helperService.getNameWithArchiveLabel(
          _.get(formSubmission, 'logbookIsArchived', false) && addArchivedString,
          _.get(formSubmission, 'logbookName', ''),
        );
      const logbookLogbookId: string = useMetaData
        ? mobileLogbookSubmissionLogbookLogbookId
        : _.get(formSubmission, 'logbookLogbookId', '');
      const logbookLegacyId: string = useMetaData
        ? mobileLogbookSubmissionLogbookLegacyId
        : _.get(formSubmission, 'logbookLegacyId', '');
      const logbookVersionNumber: number = useMetaData
        ? mobileLogbookSubmissionLogbookVersionNumber
        : _.get(formSubmission, 'logbookVersionNumber', 0);
      const formActivityType: string = isMobileLogbookSubmission
        ? movingTranslation
        : ComponentUtilities.findOneOptionForSelect(
        formActivityTypes,
        _.get(formSubmission, 'formActivityType', ''),
      )?.[0]?.name ?? formSubmission.formActivityType;
      const useCheckIn: string = ComponentUtilities.findOneOptionForSelect(
        yesNo,
        _.get(formSubmission, 'useCheckIn', '') ? 1 : 0,
        'id',
      )?.[0]?.name;

      return {
        ...formSubmission,
        logbookName,
        logbookLogbookId,
        logbookLegacyId,
        logbookVersionNumber,
        isMobileLogbookSubmission,
        formActivityType,
        useCheckIn,
        createdAt: HelperService.userDateTimeFormat
          ? HelperService.formatDateTimeWithLineBreak(formSubmission.createdAt)
          : formSubmission.createdAt,
        submittedAt:
          HelperService.userDateTimeFormat && formSubmission.submittedAt
            ? HelperService.formatDateTimeWithLineBreak(formSubmission.submittedAt)
            : formSubmission.submittedAt,
        createdBy: {
          firstName: formSubmission.createdByFirstName,
          lastName: formSubmission.createdByLastName,
          title: formSubmission.createdByTitle,
          email: formSubmission.createdByEmail,
        },
        submittedBy: formSubmission.submittedByUsername
          ? {
            firstName: formSubmission.submittedByFirstName,
            lastName: formSubmission.submittedByLastName,
            title: formSubmission.submittedByTitle,
            email: formSubmission.submittedByEmail,
          }
          : undefined,
        createdAtOriginal: formSubmission.createdAt,
        submittedAtOriginal: formSubmission.submittedAt,
        formattedIsMobileLogbook:
          formSubmission.isMobileLogbook || isMobileLogbookSubmission ? yesTranslation : noTranslation,
        currentParentLogbookName: currentParentLogbookName ?? '',
        formName: formSubmission.formName
          ? addArchivedString
            ? this.helperService.getNameWithArchiveLabel(
              _.get(formSubmission, 'formIsArchived', false),
              formSubmission.formName,
            )
            : formSubmission.formName
          : 'N/A',
        statusColor: status || '',
        statusText: status ? this.translate.instant(`general.formSubmissionStatuses.${status}`) : '',
        approvalDate: HelperService.userDateTimeFormat
          ? HelperService.formatDateTimeWithLineBreak(formSubmission.approvalDate)
          : formSubmission.approvalDate,
        formattedCombinedSendBackReasons: formSubmission.combinedSendBackReasons?.split('\n')
      };
    });

    return formSubmissionsModified;
  }

  public getApprovalWorkflowStepsTableHeaders(isParallel: boolean) {
    return [
      ...(isParallel
        ? []
        : [
          {
            value: 'order',
            name: this.translate.instant('general.datatable.headers.stepNo'),
            sortable: false,
            width: '20px',
            isOrderColumn: true,
          },
        ]),
      {
        value: 'stepName',
        name: this.translate.instant('general.datatable.headers.stepName'),
        sortable: false,
        width: '300px',
      },
      {
        value: 'role',
        name: this.translate.instant('general.datatable.headers.role'),
        sortable: false,
        width: '400px',
      },
      {
        value: 'expireTime',
        name: this.translate.instant('general.datatable.headers.expireTime'),
        sortable: false,
        width: '400px',
      },
    ];
  }

  public dispatchCancelFormSubmission(
    selectedFormSubmission: IHomeFormDetail | null,
    issuerAndReason: IIssuerAndReason,
  ): void {
    if (!selectedFormSubmission) {
      return;
    }

    if (selectedFormSubmission.approvalStepPosition === 0) {
      this.store.dispatch(
        new HomeActions.CancelFormSubmission(
          selectedFormSubmission.logbookId,
          selectedFormSubmission.id,
          issuerAndReason,
        ),
      );
    }
  }

  public static generateMasterDataContent(
    fieldMetaSource: IHomeLogbook | IFormVersion | undefined,
    masterDataFieldContent: ILogbookMasterDataFields[] | IFormMasterDataDetail[] | undefined,
    helperService: HelperService,
    timezone: string,
  ): IMasterDataFormatted[] {
    const masterDataFormatted: IMasterDataFormatted[] =
      masterDataFieldContent?.map((item: any) => {
        return {
          fieldId: item.meta.fieldId,
          type: item.meta.type,
          options: item.meta.options,
          mandatory: item.mandatory,
          maxLength: item.meta.maxLength,
          name: item.name,
          inputModel: ['Dropdown', 'DropdownMulti', 'CheckboxMulti'].includes(item.meta.type) ? [] : null,
        };
      }) ?? [];

    return HomeService.setMasterDataInputModels(fieldMetaSource, masterDataFormatted, helperService, timezone);
  }

  public homeSearch(searchKey: string): Observable<IHomeSearchResult[]> {
    return this.http.get<IHomeSearchResult[]>(`${this.URLS.HOME_SEARCH_URL}`, { params: { search: searchKey } });
  }

  public static setMasterDataInputModels(
    fieldMetaSource: IHomeLogbook | IFormVersion | undefined,
    masterDataFormatted: IMasterDataFormatted[],
    helperService: HelperService,
    timezone: string,
  ): IMasterDataFormatted[] {
    if (!fieldMetaSource) {
      return [];
    }

    return masterDataFormatted.map((item: IMasterDataFormatted) => {
      return {
        ...item,
        inputModel: helperService.setUserDefinedFieldsInputModels(
          _.find(fieldMetaSource?.fieldMeta, { fieldId: item.fieldId }),
          timezone,
        ),
      };
    });
  }

  public formatActiveVersionHistory(history: IUserAction[]): IActiveVersionHistory[] {
    return history.map((userAction: IUserAction) => {
      return {
        action: this.translate.instant(`general.userActions.${userAction.action}`),
        actionBy: {
          firstName: userAction?.createdBy.firstName,
          lastName: userAction?.createdBy.lastName,
          title: userAction?.createdBy.title,
          email: userAction?.createdBy.email,
        },
        actionAt: HelperService.userDateTimeFormat
          ? HelperService.formatDateTimeWithLineBreak(userAction?.createdAt)
          : userAction?.createdAt,
        actionReason: userAction?.reason,
        createdAt: userAction?.createdAt,
        before: userAction?.before,
        after: userAction?.after,
      };
    });
  }

  public formatAllVersionsHistory(history: IUserAction[], draftsAllowed: boolean = false): IAllVersionHistory[] {
    const groupedHistory: any[] = Object.values(_.groupBy(history, 'meta.versionDbId'));
    return _.orderBy(
      groupedHistory.reduce((result: IAllVersionHistory[], versionGroup: IUserAction[], currentIndex: number) => {
        const createUserAction: IUserAction = _.find(versionGroup, { action: EActionType.CREATED }) as IUserAction;
        const activateUserAction: IUserAction = _.find(versionGroup, {
          action: EActionType.ACTIVATED,
        }) as IUserAction;

        if (!activateUserAction && !draftsAllowed) {
          return result;
        }

        result.push({
          version: _.first(versionGroup)?.meta?.version ?? groupedHistory.length - currentIndex,
          createdBy: {
            firstName: createUserAction?.createdBy.firstName,
            lastName: createUserAction?.createdBy.lastName,
            title: createUserAction?.createdBy.title,
            email: createUserAction?.createdBy.email,
          },
          createdAt: createUserAction?.createdAt,
          activatedAt:
            activateUserAction?.createdAt && HelperService.userDateTimeFormat
              ? HelperService.formatDateTimeWithLineBreak(activateUserAction?.createdAt)
              : '',
          actionReason: createUserAction?.reason,
          before: createUserAction?.before,
          after: createUserAction?.after,
        });

        return result;
      }, []),
      'version',
      'desc',
    );
  }

  public static formatFormDetails(
    form: IFormVersion | undefined,
    formActivityTypeDropdownItems: ISelect<EFormActivityType, string>[],
    yesNoDropdown: ISelect<number, string>[],
    helperService: HelperService,
  ): IFormDetailView {
    return {
      activityType: {
        isEnabled: true,
        value: ComponentUtilities.findOneOptionForSelect(formActivityTypeDropdownItems, form?.activityType),
        rules: [],
      },
      formName: { isEnabled: false, value: form?.name ?? null, rules: [] },
      formId: { isEnabled: false, value: form?.form?.formId ?? null, rules: [] },
      versionNumber: { isEnabled: false, value: form?.versionNumber ?? null, rules: [] },
      approvalWorkFlow: {
        isEnabled: false,
        value: form?.workflowDetail
          ? [
            {
              ...form?.workflowDetail,
              name: helperService.getNoApprovalRequiredWorkflowName(form.workflowDetail),
            },
          ]
          : null,
        rules: [],
      },
      entryReviewWorkflow: {
        isEnabled: false,
        value: form?.workflowEntriesDetail
          ? [
            {
              ...form?.workflowEntriesDetail,
              name: helperService.getNoApprovalRequiredWorkflowName(form.workflowEntriesDetail),
            },
          ]
          : null,
        rules: [],
      },
      legacyId: { isEnabled: false, value: form?.legacyId ?? null, rules: [] },
      issuedDate: {
        isEnabled: false,
        value: form?.issuedDate ? { startDate: moment(form.issuedDate), endDate: moment(form.issuedDate) } : null,
        rules: [],
      },
      checkInMechanism: {
        isEnabled: true,
        value: ComponentUtilities.findOneOptionForSelect(yesNoDropdown, form?.useCheckIn ? 1 : 0),
        rules: [],
      },
    };
  }

  public formatApprovalWorkflowSteps(approvalWorkflowSteps: IWorkflowStepsData[]): IWorkflowStepsView[] {
    return approvalWorkflowSteps.map((approvalWorkflowStep: IWorkflowStepsData) => {
      const dueDateTypeTranslationKey: string =
        approvalWorkflowStep.dueDateType === 0 ? 'general.hours' : 'general.days';

      return {
        order: approvalWorkflowStep.order,
        stepName: approvalWorkflowStep.stepName,
        role: approvalWorkflowStep.role?.name,
        expireTime: approvalWorkflowStep.dueDate
          ? `${approvalWorkflowStep.dueDate} ${this.translate.instant(dueDateTypeTranslationKey)}`
          : '',
      };
    });
  }

  public getCurrentUser(action: string): Observable<IGetCurrentUserResponse> {
    return this.http.get<IGetCurrentUserResponse>(this.URLS.GET_CURRENT_USER, {
      params: {
        action,
      },
    });
  }
}
