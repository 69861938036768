import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IFormVersion, IFormTasksData, IFieldSetData } from '../../../../store/my-tasks/form-tasks/form-tasks.model';
import { IGetManyResponse, IGetOneResponse } from '../../../model/interface/crud-response-interface.model';
import { IFormTasksHistory } from '../../../../view/my-tasks/form-tasks/form-tasks.component.model';
import { TranslateService } from '@ngx-translate/core';
import { ITableHeader } from '../../../../../constants.model';
import * as _ from 'lodash';
import { IIssuer } from '../../../component/issuer/issuer.model';
import { IReview, IReviewResults } from '../../../../store/my-tasks/my-tasks.model';

@Injectable({
  providedIn: 'root',
})
export class FormTasksService {
  private readonly FIELDSET_ITEMS = '/field-version-items/';
  private readonly FORMS = {
    BASE_URL: '/forms/',
    REVIEW: 'review/',
    SEND_TO_REVIEW: 'send-to-review/',
    ACTIVATE: 'activate/',
    USER_ACTIONS: '/user-actions/forms/',
  };
  private readonly FORM_VERSIONS = {
    BASE_URL: '/form-versions/',
    ACTIVE: 'active/',
    REVIEW: 'review/',
    SEND_TO_REVIEW: 'send-to-review/',
    ACTIVATE: 'activate/',
    FORM_VERSION_USER_ACTIONS: '/user-actions/grouped-form-versions/',
    USER_ACTIONS_FOR_ONE_VERSION: '/user-actions/form-versions',
  };
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getReviewData(
    params: Record<string, string | number>,
    reviewType: string = 'form_versions',
  ): Observable<IGetManyResponse<IFormTasksData>> {
    let url = `${this.baseUrl}${this.FORMS.BASE_URL}${this.FORMS.REVIEW}`;
    if (reviewType === 'form_versions') {
      url = `${this.baseUrl}${this.FORM_VERSIONS.BASE_URL}${this.FORM_VERSIONS.REVIEW}`;
    }
    return this.http.post<IGetManyResponse<IFormTasksData>>(
      url,
      { ...params },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public reviewEntries(
    reviewPayload: IReview,
    issuer: IIssuer | null,
    reviewType: string = 'form_versions',
  ): Observable<IGetOneResponse<IReviewResults[]>> {
    let url = `${this.baseUrl}${this.FORMS.BASE_URL}${this.FORMS.REVIEW}`;
    if (reviewType === 'form_versions') {
      url = `${this.baseUrl}${this.FORM_VERSIONS.BASE_URL}${this.FORM_VERSIONS.REVIEW}`;
    }
    return this.http.post<IGetOneResponse<IReviewResults[]>>(url, {
      issuer,
      payload: reviewPayload,
    });
  }

  public getFormTasksHistory(
    id: number,
    isActive: boolean,
    reviewType: string = 'form_versions',
  ): Observable<IGetManyResponse<IFormTasksHistory>> {
    const urlId: string | number = reviewType === 'form_versions' && isActive ? '' : id;
    const url = `${this.baseUrl}${
      reviewType !== 'form_versions'
        ? this.FORMS.USER_ACTIONS
        : isActive
        ? this.FORM_VERSIONS.USER_ACTIONS_FOR_ONE_VERSION
        : this.FORM_VERSIONS.FORM_VERSION_USER_ACTIONS
    }${urlId}`;
    const params: HttpParams = new HttpParams().append('versionIds', id);

    if (urlId === '') {
      return this.http.get<IGetManyResponse<IFormTasksHistory>>(url, { params });
    }

    return this.http.get<IGetManyResponse<IFormTasksHistory>>(url);
  }

  public getFormTasksHistoryForOneVersion(
    id: number,
    reviewType: string = 'form_versions',
  ): Observable<IGetManyResponse<IFormTasksHistory>> {
    const urlId: number | string = reviewType === 'form_versions' ? '' : id;
    const url = `${this.baseUrl}${
      reviewType === 'form_versions' ? this.FORM_VERSIONS.USER_ACTIONS_FOR_ONE_VERSION : this.FORMS.USER_ACTIONS
    }${urlId}`;
    const params: HttpParams = new HttpParams().append('versionIds', id);

    if (urlId === '') {
      return this.http.get<IGetManyResponse<IFormTasksHistory>>(url, { params });
    }

    return this.http.get<IGetManyResponse<IFormTasksHistory>>(url);
  }

  public getFormTasksRecord(formId: number, id: number): Observable<IGetOneResponse<IFormVersion>> {
    let url = `${this.baseUrl}${this.FORM_VERSIONS.BASE_URL}${formId}/${id}`;
    return this.http.get<IGetOneResponse<IFormVersion>>(url);
  }

  public getFormTasksActiveRecord(formId: number): Observable<IGetOneResponse<IFormVersion>> {
    let url = `${this.baseUrl}${this.FORM_VERSIONS.BASE_URL}${this.FORM_VERSIONS.ACTIVE}${formId}`;
    return this.http.get<IGetOneResponse<IFormVersion>>(url);
  }

  public getFormTasksFieldSet(id: number): Observable<IGetOneResponse<IFieldSetData[]>> {
    let url = `${this.baseUrl}${this.FIELDSET_ITEMS}${id}`;
    return this.http.get<IGetOneResponse<IFieldSetData[]>>(url);
  }

  public static getFormTasksTableHeaderDefaults(translateService: TranslateService): ITableHeader[] {
    return _.cloneDeep([
      {
        value: 'formId',
        name: translateService.instant('myTasks.table.headers.formId'),
        sortable: true,
        selected: true,
        draggable: true,
        disabled: true,
      },
      {
        value: 'formName',
        name: translateService.instant('myTasks.table.headers.formName'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'legacyId',
        name: translateService.instant('myTasks.table.headers.formLegacyId'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'formActivityType',
        name: translateService.instant('myTasks.table.headers.formActivityType'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'versionNumber',
        name: translateService.instant('myTasks.table.headers.versionNumber'),
        selected: true,
        draggable: true,
        sortable: true,
      },
      {
        value: 'createdBy',
        name: translateService.instant('myTasks.table.headers.createdBy'),
        selected: true,
        draggable: true,
        sortable: false,
      },
      {
        value: 'createdAt',
        name: translateService.instant('myTasks.table.headers.createdAt'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'submittedBy',
        name: translateService.instant('myTasks.table.headers.submittedBy'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'submittedAt',
        name: translateService.instant('myTasks.table.headers.submittedAt'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'approvalStepPosition',
        name: translateService.instant('myTasks.table.headers.status'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'dueDate',
        name: translateService.instant('myTasks.table.headers.dueDate'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'actions',
        name: translateService.instant('myTasks.table.headers.history'),
        sortable: false,
        selected: true,
        draggable: true,
        disabled: true,
      },
    ]);
  }
}
